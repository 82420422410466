import React,{useState, useEffect} from 'react';
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, Link, useNavigate} from "react-router-dom";
import styled from 'styled-components';
import { FcInfo } from "react-icons/fc";


const Container = styled.div`

`

const Info = ({containerStyle, content, content2}) => {

  const navigate = useNavigate();
   useEffect(()=>{
    async function fetchData(){
		}
		fetchData();
  }, [])



  return (
    <Container style={containerStyle}>
      <div
        style={{
          background: "#ededed",
          borderRadius: 5,
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
          alignItems: "center",
          padding: "10px 15px",
        }}
      >
        <div style={{ display: "flex" }}>
          <FcInfo size={20} />
        </div>
        <div style={{display:"flex", flexDirection:"column"}}>
          <div
            style={{
              fontSize: 14,
              display: "flex",
              paddingLeft: 10,
              lineHeight: 1.5,
            }}
          >
            {content}
          </div>
          <div
            style={{
              fontSize: 14,
              display: "flex",
              paddingLeft: 10,
              lineHeight: 1.5,
            }}
          >
            {content2}
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Info;
