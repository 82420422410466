import React, {
  useState,
  useEffect,
  useContext,
  useLayoutEffect,
  useRef,
} from "react";
import {
  HashRouter,
  Route,
  Redirect,
  BrowserRouter,
  Routes,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Swipe from "../common/Swipe";

import styled from "styled-components";

import Guide from "../components/Guide";
import Swipe2 from "../common/Swipe2";
import Button from "../common/Button";
import { colors, theme } from "../theme/theme";
import InputItem from "../components/InputItem";
import InputReturn from "../components/InputReturn";
import Next from "../components/Next";
import { Mobile, PC } from "../components/Responsive";
import SelectItem from "../components/SelectItem";
import { UserContext } from "../context/User";
import { get_tax, update_user } from "../service/UserService";
import { BiCalendarEdit } from "react-icons/bi";
import ErrorModalEx from "../components/ErrorModalEx";
import { JuminCheck } from "../utility/common";

const Container = styled.div`
  padding: 80px 15px 0px;
  background: white;
`;
const PCContainer = styled.div`
width: 27%;
margin: 0px 37%;
  background: white;
  padding-top: 80px;
  height: 100%;

`;

const Note = styled.div`
  color: #788391;
  font-size: 1rem;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
  text-align: left;
  padding: 0px 10%;
  line-height:2;
`;


const OPTIONS = [
  { value: "관계선택", name: "관계선택" },
  { value: "소득자본인", name: "소득자본인" },
  { value: "소득자의 직계존속", name: "소득자의 직계존속" },
  { value: "배우자의 직계존속", name: "배우자의 직계존속" },
  { value: "배우자", name: "배우자" },
  { value: "직계비속", name: "직계비속" },
  { value: "형제자매", name: "형제자매" },
  { value: "위탁아동", name: "위탁아동" },
];
const OPTIONS2 = [
  { value: "장애여부선택", name: "장애여부선택" },
  { value: "장애없음", name: "장애없음" },
  { value: "장애있음", name: "장애있음" },
];

const LabelItem = styled.div`
  font-weight: 400;
  line-height: 1.5;
  font-size: 18px;
  text-decoration-line: none;
  color: #000;
  padding-left: 10px;
`;
const LabelItem2 = styled.div`
  font-weight: 400;
  line-height: 1.5;
  font-size: 16px;
  text-decoration-line: none;
  color: #000;
  padding-left: 10px;
`;

const Humanregistercontainer = () => {
  const navigate = useNavigate();

  const { user, dispatch2 } = useContext(UserContext);

  const [name, setName] = useState("");
  const [relation, setRelation] = useState("");
  const [disable, setDisable] = useState("");
  const [jumin, setJumin] = useState("");
  const [enable, setEnable] = useState(false);
  const [refresh, setRefresh] = useState(1);
  const _handlenext = async() => {
    
    if (!JuminCheck(jumin)) {
      setError(true);
      setErrormsg(
        "잘못된 주민등록번호입니다. 올바른 주민등록번호를 입력해주세요"
      );
      setJuminfocus(true);
      setRefresh((refresh) => refresh + 1);
      return;
    }


    if(relation == ''){
      setError(true);
      setErrormsg(
        "주소득자와 관계를 선택해주세요"
      );

      setRefresh((refresh) => refresh + 1);
      return;
    }
    if(disable == ''){
      setError(true);
      setErrormsg(
        "장애여부를 선택해주세요"
      );

      setRefresh((refresh) => refresh + 1);
      return;
    }
    
    const username = user.name;
    const phonedata = user.phone;
    const taxitem = await get_tax({ username, phonedata });

    console.log("taxitem", taxitem);
    const tax_id = taxitem.tax_id;

    let etchumandata = [];
    if (taxitem.etchumandata != undefined) {
      etchumandata = taxitem.etchumandata;
    }
    const u = await update_user({
      tax_id,
      name,
      jumin,
      relation,
      disable,
      etchumandata,
    });

    
    console.log("register", name, jumin, relation);
    navigate("/newhome");
  };

  const namecallback = (callbackdata) => {
    setName(callbackdata);
    console.log("username", callbackdata);
  setRefresh((refresh) => refresh + 1);

  };
  const relationcallback = (callbackdata) => {
    setRelation(callbackdata);
    console.log("relation", callbackdata);

   setRefresh((refresh) => refresh + 1);
  };

  const jumincallback = (callbackdata) => {
    setJumin(callbackdata);
    console.log("jumin number", callbackdata);

   setRefresh((refresh) => refresh + 1);
  };

  useEffect(() => {
    setJumin(jumin);
    setRelation(relation);
    setDisable(disable)
    setName(name);
    setNamefocus(namefocus);
    setJuminfocus(juminfocus);
  }, [refresh]);
    
  const [namefocus, setNamefocus] = useState(true);
  const [juminfocus, setJuminfocus] = useState(false);
  const [error, setError] = useState(false);
  const [errormsg, setErrormsg] = useState("");
  const errormodalcallback = () => {
      setError(false);
    setRefresh((refresh) => refresh + 1);
  };
  
  return (
    <>
      {error == true && (
        <ErrorModalEx callback={errormodalcallback} msg={errormsg} />
      )}

      <Mobile>
        <Container>
          <Note>
            {
              "공제대상 부양가족 : 연간소득 100만 원 이하의 1963.12.31.이전 출생 양가 부모(동거무관) 및 2003.01.01.이후 출생 직계비속과 형제"
            }
          </Note>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              marginLeft: "5%",
            }}
          >
            <div
              style={{
                marginTop: 50,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BiCalendarEdit size={20} />
              <LabelItem>{"이름"}</LabelItem>
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 10 }}
            >
              <div>
                <input
                  type="text"
                  value={name}
                  style={{ width: "260px", marginLeft: 10 }}
                  onChange={(e) => {
                    setName(e.target.value);
                    setRefresh((refresh) => refresh + 1);
                  }}
                  onFocus={() => setNamefocus(true)}
                  onBlur={() => setNamefocus(false)}
                  className="inputcommon"
                  placeholder={"부양가족 이름 입력해주세요"}
                  returnKeyType={"다음"}
                  autoCapitalize="none"
                  autoCorrect={false}
                ></input>

                {namefocus == true ? (
                  <div className="inputFocus"></div>
                ) : (
                  <div className="inputNoFocus"></div>
                )}
              </div>
            </div>
            <div style={{ fontSize: 14, display: "flex", marginTop: 5 }}>
              <div style={{ color: "#ff0000" }}>TIP</div>
              <div style={{ paddingLeft: 5, color: "#959595" }}>
                실명이 입력되어 있는지 확인해주세요
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              marginLeft: "5%",
            }}
          >
            <div
              style={{
                marginTop: 50,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BiCalendarEdit size={20} />
              <LabelItem>{"주민등록번호(숫자만입력)"}</LabelItem>
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 10 }}
            >
              <div>
                <input
                  type="number"
                  value={jumin}
                  style={{ width: "260px", marginLeft: 10 }}
                  onChange={(e) => {
                    setJumin(e.target.value);
                    setRefresh((refresh) => refresh + 1);
                  }}
                  onFocus={() => setJuminfocus(true)}
                  onBlur={() => setJuminfocus(false)}
                  className="inputcommon"
                  placeholder={"부양가족 주민번호를 입력해주세요"}
                  returnKeyType={"다음"}
                  autoCapitalize="none"
                  autoCorrect={false}
                ></input>

                {juminfocus == true ? (
                  <div className="inputFocus"></div>
                ) : (
                  <div className="inputNoFocus"></div>
                )}
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingLeft: "5%",
              marginTop: 40,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <LabelItem2>{"주소득자와의 관계"}</LabelItem2>

            <select
              style={{
                padding: 10,
                width: "150px",
                border: "none",
                fontSize: 14,
                outline: 0,
                background: "white",
              }}
              onChange={(e) => {
                setRelation(e.target.value);
                setRefresh((refresh) => refresh + 1);
              }}
            >
              {OPTIONS.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingLeft: "5%",
              marginTop: 20,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <LabelItem2>{"장애여부선택"}</LabelItem2>

            <select
              style={{
                padding: 10,
                width: "150px",
                border: "none",
                fontSize: 14,
                outline: 0,
                background: "white",
              }}
              onChange={(e) => {
                setDisable(e.target.value);
                setRefresh((refresh) => refresh + 1);
              }}
            >
              {OPTIONS2.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>

          <Next
            callback={_handlenext}
            label={"추가"}
            enable={true}
            containerStyle={{ marginTop: 20 }}
          />
        </Container>
      </Mobile>
      <PC>
        <PCContainer>
          <Note>
            {
              "공제대상 부양가족 : 연간소득 100만 원 이하의 1963.12.31.이전 출생 양가 부모(동거무관) 및 2003.01.01.이후 출생 직계비속과 형제"
            }
          </Note>

          <InputItem
            containerStyle={{ marginTop: 50 }}
            label={"부양가족 성명"}
            placeholder={"부양가족 이름을 입력해 주세요"}
            callback={namecallback}
          />

          <InputItem
            containerStyle={{ marginTop: 50 }}
            label={"주민등록번호"}
            placeholder={"주민등록번호를 입력해 주세요"}
            callback={jumincallback}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "5%",
              marginTop: 40,
              alignItems: "flex-start",
            }}
          >
            <LabelItem>{"주소득자와의 관계"}</LabelItem>

            <select
              style={{
                padding: 10,
                width: "200px",
                border: "none",
                fontSize: 18,
                outline: 0,
                background: "white",
              }}
              onChange={(e) => {
                setRelation(e.target.value);
                setRefresh((refresh) => refresh + 1);
              }}
            >
              {OPTIONS.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "5%",
              marginTop: 40,
              alignItems: "flex-start",
            }}
          >
            <LabelItem>{"장애여부선택"}</LabelItem>

            <select
              style={{
                padding: 10,
                width: "200px",
                border: "none",
                fontSize: 18,
                outline: 0,
                background: "white",
              }}
              onChange={(e) => {
                setDisable(e.target.value);
                setRefresh((refresh) => refresh + 1);
              }}
            >
              {OPTIONS2.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>

          <Next
            callback={_handlenext}
            enable={true}
            containerStyle={{ marginTop: 120 }}
          />
        </PCContainer>
      </PC>
    </>
  );
};

export default Humanregistercontainer;
