import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";
import { useSleep } from "../utility/common";

import { imageDB } from "../utility/imageData";
import { Mobile, PC } from "./Responsive";

const Container = styled.div`
position:absolute;
top:25%;
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
left: 12%;
`;

const Loading = ({ containerStyle }) => {

  const [height, setHeight] = useState(window.innerHeight);

  const PCContainer = styled.div`
    width: 27%;
    margin: 0px 37%;
    background: white;
    padding-top: 80px;
    height: 100%;
    height: ${({ height }) => height - 10}px;

    border: 1px solid;
  `;

  const navigate = useNavigate();

  useEffect(()=>{

    async function FetchData(){

      const update = await useSleep(3000);

      navigate("/newhome");

    }
    FetchData();

  },[])

  return (
    <>
        <Mobile>
          <Container style={containerStyle}>
            <img src={imageDB.loading} style={{ width: "100px" }} />
            <div style={{padding:"20px 20px 5px", fontFamily:"Pretendard-Bold"}}>청람 세무회계사무소에 이미 2024년에 </div>

            <div style={{padding:"5px 20px", fontFamily:"Pretendard-Bold"}}>
              세무 신고를 하신 고객님 입니다.</div>
              <div style={{padding:"5px 20px",fontFamily:"Pretendard-Bold"}}>
              세무자료 신고내용을 확인중에 있습니다</div>
          </Container>
        </Mobile>
        <PC>
          <PCContainer height={height}>
          <img src={imageDB.loading} style={{ width: "100px" }} />

            <div style={{padding:"20px 20px 5px", fontFamily:"Pretendard-Bold"}}>청람 세무회계사무소에 이미 2024년에 </div>

            <div style={{padding:"5px 20px", fontFamily:"Pretendard-Bold"}}>
              세무 신고를 하신 고객님 입니다.</div>
              <div style={{padding:"5px 20px",fontFamily:"Pretendard-Bold"}}>
              세무자료 신고내용을 확인중에 있습니다</div>
          </PCContainer>
        </PC>
    </>

  );
};

export default Loading;
