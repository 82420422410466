import React, {
  useState,
  useEffect,
  useContext,
  useLayoutEffect,
  useRef,
} from "react";
import {
  HashRouter,
  Route,
  Redirect,
  BrowserRouter,
  Routes,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Swipe from "../common/Swipe";

import styled from "styled-components";
import Guide from "../components/Guide";
import Swipe2 from "../common/Swipe2";
import Button from "../common/Button";
import { colors, theme } from "../theme/theme";
import InputItem from "../components/InputItem";
import { imageDB } from "../utility/imageData";
import AcceptModalEx from "../components/AcceptModalEx";
import ContentItem from "../components/ContentItem";
import { Mobile, PC } from "../components/Responsive";
import NoticeItem from "../components/NoticeItem";

const Container = styled.div`
  padding-top: 80px;
  background : #fff;
`;

const PCContainer = styled.div`
width: 27%;
margin: 0px 37%;
  background: white;
  padding-top: 50px;
`;

const MainItem = styled.div`
  font-size: 20px;
  font-weight: 700;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  text-align :left;
  height: 50px;
  color: {({theme})=>theme.maintaxcolor}
`;

const DateItem = styled.div`
  font-size: 15px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: flex-end;
  text-align :left;
  height: 50px;
  color: {({theme})=>theme.maintaxcolor}
  padding-right:20px;
`;


const Noticedetailcontainer = ({label, content}) => {
  const navigate = useNavigate();


  return (
    <>
      <Mobile>
        <Container>
          <MainItem>{"청람세무회계사무소에서 알려드립니다"}</MainItem>
          <DateItem>{label}</DateItem>
          <textarea
            value={content}
            style={{
              width: "100%",
              height: 800,
              fontSize: 16,
              padding:10,
              outline: 0,
              resize: "none",
              border: "none",
            }}
          ></textarea>
        </Container>
      </Mobile>
      <PC>
        <PCContainer>
          <MainItem>{"청람세무회계사무소에서 알려드립니다"}</MainItem>
          <DateItem>{label}</DateItem>
          <textarea
            value={content}
            style={{
              width: "100%",
              fontSize: 16,
              padding:10,
              height: 800,
              outline: 0,
              resize: "none",
              border: "none",
            }}
          ></textarea>
        </PCContainer>
      </PC>
    </>
  );
};

export default Noticedetailcontainer;
