import React, { useContext, useEffect, useState } from "react";

import Layout from "../screen/Layout/HomeLayout";
import HomeLayout from "../screen/Layout/HomeLayout";
import { UserContext } from "../context/User";
import { TYPE } from "../utility/maroneDefine";
import { useLocation } from "react-router-dom";
import {
  get_userInfoForDevice,
  login,
  update_userdevice,
} from "../service/UserService";

import PrevLayout from "../screen/Layout/PrevLayout";
import HometaxInfocontainer from "../container/HometaxInfocontainer";
import WetaxInfocontainer from "../container/WetaxInfocontainer";
import BizInfocontainer from "../container/BizInfocontainer";
import BizTypeInfocontainer from "../container/BizTypeInfocontainer";

const BizTypeInfopage = () => {
  const { user, dispatch2 } = useContext(UserContext);

  return (
    <PrevLayout menu={true} bottom={false} header={true} headerdetail={false}>
      <BizTypeInfocontainer />
    </PrevLayout>
  );
};

export default BizTypeInfopage;
