import React,{useState, useEffect} from 'react';
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, Link, useNavigate} from "react-router-dom";
import styled from 'styled-components';
import { imageDB } from '../utility/imageData';
import Relationbtn from './Relationbtn';
import Button from '../common/Button';
import { CommaFormatted } from '../utility/common';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 2;
  background: ${({bgcolor}) => bgcolor};
  margin: 5px 0px;
  box-shadow: 1px 1px 1px 2px #f5f5f5;
  border-radius: 5px;
`;

const LabelItem = styled.div`
  font-weight: 700;
  line-height: 1.4;
  font-size: 1rem;
  text-decoration-line: none;
  color: rgb(58, 64, 71);
  margin-top:20px;
  display:flex;
  justify-content:flex-start;
  padding-left:30px;
  align-items:center;
  flex-direction:row;
`;

const Content = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  margin-top:5px;
`
const ItemLabel = styled.div`
  width: 50px;
  height: 50px;
  background-color: #ededed;
  border-radius: 100%;
  position: absolute;
  left: 30px;
`;
const ItemData = styled.div`
  font-weight: 600;
  line-height: 1.5;
  font-size: 14px;
  text-decoration-line: none;
  color: #788391;;
  text-align:left;
  padding : 0px 10px;
`;

const LineControl = styled.div`
  margin-top:20px;
  width: 100%;
  height: 1px;
  background-color: #f0f1f2;
`;

const Note = styled.div`
  font-size: 12px;
  text-align: left;
  padding-left: 20px;
`;

const Info = styled.div`
  font-size: 14px;
  text-align: left;
  padding-left: 20px;
`;

const Items = [
  { name: "사업자등록번호", money: "" },
  { name: "귀속연도", money: "" },
  { name: "세목", money: "" },
  { name: "납부일", money: "" },
  { name: "합계", money: "" },
];

const LocalTaxItem = ({
  containerStyle,
  label,
  items,
  content,
  color,
  callback,
  bgcolor,
}) => {
  const navigate = useNavigate();
  useEffect(() => {
    async function fetchData() {}
    fetchData();
  }, []);

  const _handlecallback = () => {
  //  callback();
  };
  const _handleWetax = () => {};

  return (
    <Container
      style={containerStyle}
      onClick={_handlecallback}
      bgcolor={bgcolor}
    >
      <LabelItem>
        <div style={{ paddingLeft: 5 }}>{label}</div>
      </LabelItem>
      <Content>
        <table style={{ marginTop: 5, fontSize: 14, width: "90%" }}>
          <thead>
            <tr>
              <th scope="col">세목</th>
              <th scope="col">부과소재지 / 금액</th>
            </tr>
          </thead>
          <tbody>
            {items.list.map((data, index) => (
              <tr>
                <td style={{ width: "50%" }}>{data.taxType}</td>
                <td style={{ width: "50%" }}>
                  <div>{data.chargeOrg}</div>
                  <div> {CommaFormatted(data.sumAmt)}원</div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Content>

      <LineControl />
    </Container>
  );
};

LocalTaxItem.defaultProps = {
  bgcolor: "#FFF",
};


export default LocalTaxItem;
