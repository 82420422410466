import React,{useState, useEffect} from 'react';
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, Link, useNavigate} from "react-router-dom";
import styled from 'styled-components';
import { imageDB } from '../utility/imageData';
import Relationbtn from './Relationbtn';
import Button from '../common/Button';
import { CommaFormatted } from '../utility/common';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 2;
  background: ${({bgcolor}) => bgcolor};
  margin: 5px 0px;
  border-radius: 5px;
`;

const LabelItem = styled.div`
  font-weight: 700;
  line-height: 1.4;
  font-size: 1rem;
  text-decoration-line: none;
  color: rgb(58, 64, 71);
  margin-top:20px;
  display:flex;
  justify-content:flex-start;
  padding-left:30px;
  align-items:center;
  flex-direction:row;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
`;
const ItemLabel = styled.div`
  width: 50px;
  height: 50px;
  background-color: #ededed;
  border-radius: 100%;
  position: absolute;
  left: 30px;
`;
const ItemData = styled.div`
  font-weight: 600;
  line-height: 1.5;
  font-size: 14px;
  text-decoration-line: none;
  color: #788391;;
  text-align:left;
  padding : 0px 10px;
`;

const LineControl = styled.div`
  margin-top:20px;
  width: 100%;
  height: 1px;
  background-color: #f0f1f2;
`;

const Note = styled.div`
  font-size: 12px;
  text-align: left;
  padding-left: 20px;
`;

const Info = styled.div`
  font-size: 14px;
  text-align: left;
  padding-left: 20px;
`;

const Items = [
  { name: "일용근로소득", money: "" },
  { name: "근로소득", money: "" },
  { name: "사업소득", money: "" },
];

const IncomeItem = ({
  containerStyle,
  label,
  items,
  content,
  color,
  callback,
  bgcolor,
}) => {
  const navigate = useNavigate();
  useEffect(() => {
    async function fetchData() {}
    fetchData();
  }, []);

  const _handlecallback = () => {
    //callback();
  };
  const handleCheck = () => {
    
  }

  return (
    <Container
      style={containerStyle}
      onClick={_handlecallback}
      bgcolor={bgcolor}
    >
      <LabelItem>
        <div style={{ paddingLeft: 5 }}>{label}</div>
      </LabelItem>
      <Content>
        {items.list1 == undefined ? (
          <div>홈택스에서 정보를 가져오지 못하였습니다</div>
        ) : (
          <table style={{ marginTop: 5, fontSize: 14, width: "90%" }}>
            <thead>
              <tr>
                <th scope="col">내용</th>
                <th scope="col">금액</th>
              </tr>
            </thead>

            <tbody>
              {items.list1.length == 0 && (
                <tr>
                  <td style={{ width: "40%" }}>{"일용근로소득"}</td>
                  <td style={{ width: "60%" }}>{"없음"}</td>
                </tr>
              )}
              {items.list1.map((data, index) => (
                <tr>
                  <td style={{ width: "40%" }}>
                    {"일용근로소득" + data.pymnTmop + "월"}
                  </td>
                  <td style={{ width: "60%" }}>
                    {CommaFormatted(data.totaPymnAmt)}원
                  </td>
                </tr>
              ))}

              {items.list2.length == 0 && (
                <tr>
                  <td style={{ width: "40%" }}>{"근로소득"}</td>
                  <td style={{ width: "60%" }}>{"없음"}</td>
                </tr>
              )}
              {items.list2.map((data, index) => (
                <tr>
                  <td style={{ width: "40%" }}>
                    <div>{"근로소득"}</div>
                    <div> {data.pymnTmop + "월"}</div>
                  </td>
                  <td style={{ width: "60%" }}>
                    {CommaFormatted(data.totaPymnAmt)}원
                  </td>
                </tr>
              ))}

              {items.list3.length == 0 && (
                <tr>
                  <td style={{ width: "40%" }}>{"사업	소득"}</td>
                  <td style={{ width: "60%" }}>{"없음"}</td>
                </tr>
              )}

              {items.list3.map((data, index) => (
                <tr>
                  <td style={{ width: "60%" }}>
                    <div>{"사업소득"}</div>
                    <div> {data.pperNm}</div>
                  </td>
                  <td style={{ width: "40%" }}>
                    {CommaFormatted(data.totaPymnAmt)}원
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </Content>

      <LineControl />
    </Container>
  );
};

IncomeItem.defaultProps = {
  bgcolor: "#FFF",
};


export default IncomeItem;
