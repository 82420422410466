import firebase from "firebase/compat/app";

// Add the Firebase products that you want to use
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";


export const firebaseConfig = {
  apiKey: "AIzaSyBFmGfCfwxjSaO7vP_r6VxJIyNkUz4N30M",
  authDomain: "taxapp-cfca2.firebaseapp.com",
  projectId: "taxapp-cfca2",
  storageBucket: "taxapp-cfca2.appspot.com",
  messagingSenderId: "442827738768",
  appId: "1:442827738768:web:6d9bedd90cb3a77651d215",
  measurementId: "G-4QH41QM0XV",
};
const firebaseApp = firebase.initializeApp(firebaseConfig);
firebaseApp.firestore().settings({ experimentalForceLongPolling: true });
const db = firebaseApp.firestore();
const auth = firebase.auth();
const storage = firebase.storage();

export { db, auth, storage, firebaseApp };