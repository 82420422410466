import React, { useState, useEffect, useRef } from "react";
import {
  HashRouter,
  Route,
  Switch,
  Redirect,
  BrowserRouter,
  Routes,
  Link,
  useNavigate,
} from "react-router-dom";
import styled from "styled-components";
import { imageDB } from "../utility/imageData";
import Button from "../common/Button";
import { update_userexcelfile, update_userfile, uploadExcel, uploadImage } from "../service/UserService";
import { IoIosCloseCircle } from "react-icons/io";
import { AttachType } from "../utility/contentDefine";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  background: #f7f7f7;
  margin: 5px 0px;
  border: 3px dotted #c0c0c0;
  border-radius: 5px;
`;

const LabelItem = styled.div`
  font-weight: 700;
  line-height: 1.4;
  font-size: 1rem;
  text-decoration-line: none;
  color: rgb(58, 64, 71);
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

`;

const ItemData = styled.div`
  font-weight: 600;
  line-height: 1.5;
  font-size: 14px;
  text-decoration-line: none;
  color: #788391;
  text-align: left;
  padding: 0px 10px;
`;

const LineControl = styled.div`
  margin-top: 20px;
  width: 100%;
  height: 1px;
  background-color: #f0f1f2;
`;

const ImageItem = styled.div`
  padding: 5px;
  background: #575353;
  color: #ededed;
  width: 80%;
  margin:20px 0px;
`;
const CloseIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ServiceItem3 = ({
  containerStyle,
  label,
  content,
  color,
  attachtype,
  taxid,
  itemsdata,
}) => {

  const [items, setItems] = useState(itemsdata);

  console.log("items", items);
  const [refresh, setRefresh] = useState(1);
  const [reload, setReload] = useState(1);

  const navigate = useNavigate();
  useEffect(() => {
    async function fetchData() {}
    fetchData();
  }, []);
  const fileInput = useRef();

  const handleUploadClick = (e) => {
    fileInput.current.click();
  };

  const _handleDelete = async(data) => {

    let itemsTmp = items;
    const FindIndex = items.findIndex((x) => x.path == data);

    console.log("FindIndex", FindIndex);
    itemsTmp.splice(FindIndex, 1);
    console.log("itemsTmp", itemsTmp);
    setItems(itemsTmp);
    setReload((reload) => reload + 1);
  };

  useEffect(() => {
    setItems(items);
    console.log("items", items);
  }, [refresh]);

  useEffect(() => {
    setItems(items);
    
    const tax_id = taxid;
    const type = attachtype;
    const itemsdata = items;
    console.log("upload", tax_id, type, itemsdata);
    update_userexcelfile({ tax_id, type, itemsdata });


  }, [reload]);

  const handlefileuploadChange = async (e) => {
    let filename = "";
    const file = e.target.files[0];
    filename = file.name;

    if (!ImagefileExtensionValid(filename)) {
      window.alert(
        "업로드 대상 파일의 확장자는 xlsx 만 가능 합니다"
      );
      return;
    }
    

    var p1 = new Promise(function (resolve, reject) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let img = reader.result;
        resolve(img);
      };
    });
    const getRandom = () => Math.random();
    const email = getRandom();

    p1.then(async (result) => {
      const uri = result;
      console.log("uri", uri);

      const URL = await ExcelUpload(uri, email);
      console.log("image", URL);
      let exceldata = {
        path: "",
        name :"",
      }
      exceldata.path = URL;
      exceldata.name = filename;
      items.push(exceldata);

      setItems(items);

      setReload((reload) => reload + 1);
    });
  };

  const ExcelUpload = async (data, data2) => {
    const uri = data;
    const email = data2;
    const URL = await uploadExcel({ uri, email });
    return URL;
  };
  const ALLOW_IMAGE_FILE_EXTENSION = "xlsx";

  const ImagefileExtensionValid = (name) => {
    const extention = removeFileName(name);

    if (
      ALLOW_IMAGE_FILE_EXTENSION.indexOf(extention) <= -1 ||
      extention == ""
    ) {
      return false;
    }
    return true;
  };
  const removeFileName = (originalFileName) => {
    const lastIndex = originalFileName.lastIndexOf(".");

    if (lastIndex < 0) {
      return "";
    }
    return originalFileName.substring(lastIndex + 1).toLowerCase();
  };
  const [border, setBorder] = useState(false);
  const handleFocusBorder = () => {
    setBorder(true);
  };
  const handleNoFocusBorder = () => {
    setBorder(false);
  };
  const handleAdd = () => {};

  return (
    <Container style={containerStyle}>
      <LabelItem>
        <div style={{ paddingLeft: 5 }}>{label}</div>
      </LabelItem>
      <Content>
        <ItemData color={color}>{content}</ItemData>

        {items.map((data, index) => (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <ImageItem>
              <a target={"_blank"} href={data.path}>
                <div style={{ color: "#fff" }}>{data.name}</div>
              </a>
            </ImageItem>

            <CloseIcon
              onClick={() => {
                _handleDelete(data.path);
              }}
            >
              <IoIosCloseCircle size={30} />
            </CloseIcon>
          </div>
        ))}

        <input
          type="file"
          ref={fileInput}
          onChange={handlefileuploadChange}
          style={{ display: "none" }}
        />

        {attachtype == AttachType.TYPE6 || attachtype == AttachType.TYPE7 ? (
          <Button
            icon={"excel"}
            buttonText={""}
            callback={handleUploadClick}
            containerStyle={{
              marginTop: "20px",
              width: "80px",
              height: 25,
              fontSize: 14,
              fontWeight: 300,
              color: "#000",
              display: "flex",
              justifyContent: "center",
            }}
          />
        ) : (
          <Button
            icon={"add"}
            buttonText={""}
            callback={handleUploadClick}
            containerStyle={{
              marginTop: "20px",
              width: "80px",
              height: 25,
              fontSize: 14,
              fontWeight: 300,
              color: "#000",
              display: "flex",
              justifyContent: "center",
            }}
          />
        )}
      </Content>
      <LineControl />
    </Container>
  );
};

export default ServiceItem3;
