import React, {
  useState,
  useEffect,
  useContext,
  useLayoutEffect,
  useRef,
} from "react";
import {
  HashRouter,
  Route,
  Redirect,
  BrowserRouter,
  Routes,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Swipe from "../common/Swipe";

import styled from "styled-components";
import Guide from "../components/Guide";
import Swipe2 from "../common/Swipe2";
import Button from "../common/Button";
import { colors, theme } from "../theme/theme";
import InputItem from "../components/InputItem";
import { imageDB } from "../utility/imageData";
import AcceptModalEx from "../components/AcceptModalEx";
import ContentItem from "../components/ContentItem";
import { Mobile, PC } from "../components/Responsive";
import NoticeItem from "../components/NoticeItem";
import { getDateFullTime, JuminCheck, useSleep } from "../utility/common";
import { UserContext } from "../context/User";
import { get_tax, Update_useraddr, Update_userbank, Update_userbusiness, Update_useremail, Update_userfinancial, Update_userhometaxid, Update_userhometaxpw, Update_userhouse, Update_userjumin } from "../service/UserService";
import InfoItem from "../components/InfoItem";
import { InfoType } from "../utility/contentDefine";
import ErrorModalEx from "../components/ErrorModalEx";
import DaumPostcode from 'react-daum-postcode';


const PCContainer = styled.div`
width: 27%;
margin: 0px 37%;
  background: white;
  padding-top: 80px;
  border :1px solid;
`;
const Container = styled.div`
  width: 100%;
  background: white;
  padding-top: 80px;
`;
const ServiceLayer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 10px 0px;
`;

const Infocontainer = ({INFOTYPE, TAX}) => {
  const navigate = useNavigate();
  const { user, dispatch2 } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [jumin, setJumin] = useState(TAX.jumin);
  const [bank, setBank] = useState(TAX.bank);
  const [email, setEmail] = useState(TAX.email);
  const [hometaxid, setHometaxid] = useState(TAX.hometaxid);
  const [hometaxpw, setHometaxpw] = useState(TAX.hometaxpw);

  const [addr, setAddr] = useState('');
  const [subaddr, setSubaddr] = useState('');
  const [house, setHouse] = useState(TAX.houseetc);
  const [business, setBusiness] = useState(TAX.businessetc);
  const [financial, setFinancial] = useState(TAX.financial);
  const [refresh, setRefresh] = useState(1);
  const [error, setError] = useState(false);
  const [errormsg, setErrormsg] = useState("");

  useEffect(() => {
      window.scrollTo(0, 0);
      return () => {
      };
    }, []);
  
  useEffect(() => {
    async function FetchData() {
      setLoading(true);
    }
    FetchData();
  }, [])

  useEffect(()=>{
    setJumin(jumin);
    setBank(bank);
    setEmail(email);
    setHometaxid(hometaxid);
    setHometaxpw(hometaxpw);
    setAddr(addr);
    setSubaddr(subaddr);
  }, [refresh])

  const _handlejumin = async()=>{
    const tax_id = TAX.tax_id;

    if (!JuminCheck(jumin)) {
      setError(true);
      setErrormsg("잘못된 주민등록번호입니다. 올바른 주민등록번호를 입력해주세요");
      setRefresh((refresh) => refresh + 1);
      return;
    }
    
 
    const update2= await Update_userjumin({
      tax_id,
      jumin});

 

    navigate("/newhome");

  }
  const _handlebank = async()=>{
    const tax_id = TAX.tax_id;
    const update2= await Update_userbank({
      tax_id,
      bank});
    navigate("/newhome");
  }
  const _handleemail = async()=>{
    const tax_id = TAX.tax_id;
    const update2= await Update_useremail({
      tax_id,
      email});
    navigate("/newhome");
  }

  const _handlehometaxid = async()=>{
    const tax_id = TAX.tax_id;
    const update2= await Update_userhometaxid({
      tax_id,
      hometaxid});
    navigate("/newhome");
  }


  const _handlehometaxpw = async()=>{
    const tax_id = TAX.tax_id;
    const update2= await Update_userhometaxpw({
      tax_id,
      hometaxpw});
    navigate("/newhome");
  }

  const _handleaddr = async()=>{
    const tax_id = TAX.tax_id;

    const update2= await Update_useraddr({
      tax_id,
      addr,
      subaddr});
    navigate("/newhome");
  }

  const _handlehouse = async()=>{
    const tax_id = TAX.tax_id;

    const update2= await Update_userhouse({
      tax_id,
      house});
    navigate("/newhome");
  }

  const _handlebusiness = async()=>{
    const tax_id = TAX.tax_id;

    const update2= await Update_userbusiness({
      tax_id,
      business});
    navigate("/newhome");
  }
  const _handlefinancial = async()=>{
    const tax_id = TAX.tax_id;

    const update2= await Update_userfinancial({
      tax_id,
      financial});
    navigate("/newhome");
  }

  const errormodalcallback = () => {
    setError(false);
    setRefresh((refresh) => refresh + 1);
  };

  const handleComplete = (data) => {
    console.log("address", data.address);
    setAddr(data.address);

  };
  

  
  return (
    <>
      {loading == true ? (

        
        <div>
          {error == true && (
              <ErrorModalEx callback={errormodalcallback} msg={errormsg} />
          )}

          <Mobile>
            <Container>
            {
                INFOTYPE == InfoType.ADDR && 
                <>
                <Guide
                height={0}
                main1={"주민등록지상 주소지를 입력해주세요"}
                 ></Guide>
                 <DaumPostcode onComplete={handleComplete} />

                 {
                  addr != '' && <>
                  
                  <div style={{padding:"10px 20px", display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start"}}>
                  <input
                    type="text"
                    value={addr}
                    onChange={(e) => {
                      setAddr(e.target.value);
                      setRefresh((refresh) => refresh +1);
                    }}
                    className="inputInfoCommon"
      
                   ></input>

                   <div style={{marginTop:10}}></div>

                  <input
                    type="text"
                    value={subaddr}
                    onChange={(e) => {
                      setSubaddr(e.target.value);
                      setRefresh((refresh) => refresh +1);
                    }}
                    className="inputInfoCommon"
                    placeholder="상세주소를 입력해주세요"
                   ></input>

                </div>

                  </>
                 }

                <Button
                  buttonText={"입력하기"}
                  callback={_handleaddr}
                  containerStyle={{
                    backgroundColor: colors.mainbuttoncolor,
                    color: "#fff",
                    margin: "400px 5% 0px",
                    width: '90%',
                    height: 40,
                    fontSize: 17,
                    fontWeight: 700,
                    borderRadius: "10px",
                  }}
                />
                </>        
              }
              {
                INFOTYPE == InfoType.JUMIN && 
                <>
                <Guide
                height={0}
                main1={"주민등록번호를 입력해주세요"}
                 ></Guide>
                <div style={{padding:"10px 20px", display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start"}}>
                  <input
                    type="text"
                    value={jumin}
                    onChange={(e) => {
                      setJumin(e.target.value);
                      setRefresh((refresh) => refresh +1);
                    }}
                    className="inputInfoCommon"
                    placeholder={"주민등록번호를 입력해주세요"}
                  ></input>

                </div>

                <Button
                  buttonText={"입력하기"}
                  callback={_handlejumin}
                  containerStyle={{
                    backgroundColor: colors.mainbuttoncolor,
                    color: "#fff",
                    margin: "450px 5% 0px",
                    width: '90%',
                    height: 40,
                    fontSize: 17,
                    fontWeight: 700,
                    borderRadius: "10px",
                  }}
                />
                </>        
              }
              {
                INFOTYPE == InfoType.BANK && 
                <>
                <Guide
                height={0}
                main1={"환급받을 입금계좌번호를 입력해주세요"}
                 ></Guide>
                <div style={{padding:"10px 20px", display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start"}}>
                  <input
                    type="text"
                    value={bank}
                    onChange={(e) => {
                      setBank(e.target.value);
                      setRefresh((refresh) => refresh +1);
                    }}
                    className="inputInfoCommon"
                    placeholder={"입금 받을 은행명과 계좌번호를 입력해주세요"}
                  ></input>

                </div>

                <Button
                  buttonText={"입력하기"}
                  callback={_handlebank}
                  containerStyle={{
                    backgroundColor: colors.mainbuttoncolor,
                    color: "#fff",
                    margin: "450px 5% 0px",
                    width: '90%',
                    height: 40,
                    fontSize: 17,
                    fontWeight: 700,
                    borderRadius: "10px",
                  }}
                />
                </>   
              }
              {
                INFOTYPE == InfoType.EMAIL && 
                <>
                <Guide
                height={0}
                main1={"이메일주소를 입력해주세요"}
                 ></Guide>
                <div style={{padding:"10px 20px", display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start"}}>
                  <input
                    type="text"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setRefresh((refresh) => refresh +1);
                    }}
                    className="inputInfoCommon"
                    placeholder={"이메일주소를 입력해주세요"}
                  ></input>

                </div>

                <Button
                  buttonText={"입력하기"}
                  callback={_handleemail}
                  containerStyle={{
                    backgroundColor: colors.mainbuttoncolor,
                    color: "#fff",
                    margin: "450px 5% 0px",
                    width: '90%',
                    height: 40,
                    fontSize: 17,
                    fontWeight: 700,
                    borderRadius: "10px",
                  }}
                />
                </>   
              }
              {
                INFOTYPE == InfoType.HOMETAXID && 
                <>
                <Guide
                height={0}
                main1={"홈택스 아이디를 입력해주세요"}
                 ></Guide>
                <div style={{padding:"10px 20px", display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start"}}>
                  <input
                    type="text"
                    value={hometaxid}
                    onChange={(e) => {
                      setHometaxid(e.target.value);
                      setRefresh((refresh) => refresh +1);
                    }}
                    className="inputInfoCommon"
                    placeholder={"홈택스 아이디를 입력해주세요"}
                  ></input>

                </div>

                <Button
                  buttonText={"입력하기"}
                  callback={_handlehometaxid}
                  containerStyle={{
                    backgroundColor: colors.mainbuttoncolor,
                    color: "#fff",
                    margin: "450px 5% 0px",
                    width: '90%',
                    height: 40,
                    fontSize: 17,
                    fontWeight: 700,
                    borderRadius: "10px",
                  }}
                />
                </>   
              }
              {
                INFOTYPE == InfoType.HOMETAXPW && 
                <>
                <Guide
                height={0}
                main1={"홈택스 패스워드를 입력해주세요"}
                 ></Guide>
                <div style={{padding:"10px 20px", display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start"}}>
                  <input
                    type="text"
                    value={hometaxpw}
                    onChange={(e) => {
                      setHometaxpw(e.target.value);
                      setRefresh((refresh) => refresh +1);
                    }}
                    className="inputInfoCommon"
                    placeholder={"홈택스 패스워드를 입력해주세요"}
                  ></input>

                </div>

                <Button
                  buttonText={"입력하기"}
                  callback={_handlehometaxpw}
                  containerStyle={{
                    backgroundColor: colors.mainbuttoncolor,
                    color: "#fff",
                    margin: "450px 5% 0px",
                    width: '90%',
                    height: 40,
                    fontSize: 17,
                    fontWeight: 700,
                    borderRadius: "10px",
                  }}
                />
                </>   
              }
              {
                INFOTYPE == InfoType.HOUSE && 
                <>
                <Guide
                height={0}
                main1={"주택임대소득을 입력해주세요"}
                 ></Guide>
                <div style={{padding:"10px 20px", display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start"}}>
                  <input
                    type="text"
                    value={house}
                    onChange={(e) => {
                      setHouse(e.target.value);
                      setRefresh((refresh) => refresh +1);
                    }}
                    className="inputInfoCommon"
                    placeholder={"주택임대소득을 입력해주세요"}
                  ></input>

                </div>

                <Button
                  buttonText={"입력하기"}
                  callback={_handlehouse}
                  containerStyle={{
                    backgroundColor: colors.mainbuttoncolor,
                    color: "#fff",
                    margin: "450px 5% 0px",
                    width: '90%',
                    height: 40,
                    fontSize: 17,
                    fontWeight: 700,
                    borderRadius: "10px",
                  }}
                />
                </>   
              }
      
              {
                INFOTYPE == InfoType.BUSINESS && 
                <>
                <Guide
                height={0}
                main1={"사업자 등록 기타소득을 입력해주세요"}
                 ></Guide>
                <div style={{padding:"10px 20px", display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start"}}>
                  <input
                    type="text"
                    value={business}
                    onChange={(e) => {
                      setBusiness(e.target.value);
                      setRefresh((refresh) => refresh +1);
                    }}
                    className="inputInfoCommon"
                    placeholder={"주택임대소득을 입력해주세요"}
                  ></input>

                </div>

                <Button
                  buttonText={"입력하기"}
                  callback={_handlebusiness}
                  containerStyle={{
                    backgroundColor: colors.mainbuttoncolor,
                    color: "#fff",
                    margin: "450px 5% 0px",
                    width: '90%',
                    height: 40,
                    fontSize: 17,
                    fontWeight: 700,
                    borderRadius: "10px",
                  }}
                />
                </>   
              }
                    {
                INFOTYPE == InfoType.FINANCIAL && 
                <>
                <Guide
                height={0}
                main1={"2천만원 이상 금융소득을 입력해주세요"}
                 ></Guide>
                <div style={{padding:"10px 20px", display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start"}}>
                  <input
                    type="text"
                    value={financial}
                    onChange={(e) => {
                      setFinancial(e.target.value);
                      setRefresh((refresh) => refresh +1);
                    }}
                    className="inputInfoCommon"
                    placeholder={"주택임대소득을 입력해주세요"}
                  ></input>

                </div>

                <Button
                  buttonText={"입력하기"}
                  callback={_handlefinancial}
                  containerStyle={{
                    backgroundColor: colors.mainbuttoncolor,
                    color: "#fff",
                    margin: "450px 5% 0px",
                    width: '90%',
                    height: 40,
                    fontSize: 17,
                    fontWeight: 700,
                    borderRadius: "10px",
                  }}
                />
                </>   
              }
          
            </Container>
          </Mobile>
  

          <PC>
            <PCContainer>
            {
                INFOTYPE == InfoType.ADDR && 
                <>
                <Guide
                height={0}
                main1={"주민등록지상 주소지를 입력해주세요"}
                 ></Guide>
                 <DaumPostcode onComplete={handleComplete} />

                 {
                  addr != '' && <>
                  
                  <div style={{padding:"10px 20px", display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start"}}>
                  <input
                    type="text"
                    value={addr}
                    onChange={(e) => {
                      setAddr(e.target.value);
                      setRefresh((refresh) => refresh +1);
                    }}
                    className="inputInfoCommon"
      
                   ></input>

                   <div style={{marginTop:10}}></div>

                  <input
                    type="text"
                    value={subaddr}
                    onChange={(e) => {
                      setSubaddr(e.target.value);
                      setRefresh((refresh) => refresh +1);
                    }}
                    className="inputInfoCommon"
                    placeholder="상세주소를 입력해주세요"
                   ></input>

                </div>

                  </>
                 }

                <Button
                  buttonText={"입력하기"}
                  callback={_handleaddr}
                  containerStyle={{
                    backgroundColor: colors.mainbuttoncolor,
                    color: "#fff",
                    margin: "400px 5% 0px",
                    width: '90%',
                    height: 40,
                    fontSize: 17,
                    fontWeight: 700,
                    borderRadius: "10px",
                  }}
                />
                </>        
              }
              {
                INFOTYPE == InfoType.JUMIN && 
                <>
                <Guide
                height={0}
                main1={"주민등록번호를 입력해주세요"}
                 ></Guide>
                <div style={{padding:"10px 20px", display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start"}}>
                  <input
                    type="text"
                    value={jumin}
                    onChange={(e) => {
                      setJumin(e.target.value);
                      setRefresh((refresh) => refresh +1);
                    }}
                    className="inputInfoCommon"
                    placeholder={"주민등록번호를 입력해주세요"}
                  ></input>

                </div>

                <Button
                  buttonText={"입력하기"}
                  callback={_handlejumin}
                  containerStyle={{
                    backgroundColor: colors.mainbuttoncolor,
                    color: "#fff",
                    margin: "450px 5% 0px",
                    width: '90%',
                    height: 40,
                    fontSize: 17,
                    fontWeight: 700,
                    borderRadius: "10px",
                  }}
                />
                </>        
              }
              {
                INFOTYPE == InfoType.BANK && 
                <>
                <Guide
                height={0}
                main1={"환급받을 입금계좌번호를 입력해주세요"}
                 ></Guide>
                <div style={{padding:"10px 20px", display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start"}}>
                  <input
                    type="text"
                    value={bank}
                    onChange={(e) => {
                      setBank(e.target.value);
                      setRefresh((refresh) => refresh +1);
                    }}
                    className="inputInfoCommon"
                    placeholder={"입금 받을 은행명과 계좌번호를 입력해주세요"}
                  ></input>

                </div>

                <Button
                  buttonText={"입력하기"}
                  callback={_handlebank}
                  containerStyle={{
                    backgroundColor: colors.mainbuttoncolor,
                    color: "#fff",
                    margin: "450px 5% 0px",
                    width: '90%',
                    height: 40,
                    fontSize: 17,
                    fontWeight: 700,
                    borderRadius: "10px",
                  }}
                />
                </>   
              }
              {
                INFOTYPE == InfoType.EMAIL && 
                <>
                <Guide
                height={0}
                main1={"이메일주소를 입력해주세요"}
                 ></Guide>
                <div style={{padding:"10px 20px", display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start"}}>
                  <input
                    type="text"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setRefresh((refresh) => refresh +1);
                    }}
                    className="inputInfoCommon"
                    placeholder={"이메일주소를 입력해주세요"}
                  ></input>

                </div>

                <Button
                  buttonText={"입력하기"}
                  callback={_handleemail}
                  containerStyle={{
                    backgroundColor: colors.mainbuttoncolor,
                    color: "#fff",
                    margin: "450px 5% 0px",
                    width: '90%',
                    height: 40,
                    fontSize: 17,
                    fontWeight: 700,
                    borderRadius: "10px",
                  }}
                />
                </>   
              }
              {
                INFOTYPE == InfoType.HOMETAXID && 
                <>
                <Guide
                height={0}
                main1={"홈택스 아이디를 입력해주세요"}
                 ></Guide>
                <div style={{padding:"10px 20px", display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start"}}>
                  <input
                    type="text"
                    value={hometaxid}
                    onChange={(e) => {
                      setHometaxid(e.target.value);
                      setRefresh((refresh) => refresh +1);
                    }}
                    className="inputInfoCommon"
                    placeholder={"홈택스 아이디를 입력해주세요"}
                  ></input>

                </div>

                <Button
                  buttonText={"입력하기"}
                  callback={_handlehometaxid}
                  containerStyle={{
                    backgroundColor: colors.mainbuttoncolor,
                    color: "#fff",
                    margin: "450px 5% 0px",
                    width: '90%',
                    height: 40,
                    fontSize: 17,
                    fontWeight: 700,
                    borderRadius: "10px",
                  }}
                />
                </>   
              }
              {
                INFOTYPE == InfoType.HOMETAXPW && 
                <>
                <Guide
                height={0}
                main1={"홈택스 패스워드를 입력해주세요"}
                 ></Guide>
                <div style={{padding:"10px 20px", display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start"}}>
                  <input
                    type="text"
                    value={hometaxpw}
                    onChange={(e) => {
                      setHometaxpw(e.target.value);
                      setRefresh((refresh) => refresh +1);
                    }}
                    className="inputInfoCommon"
                    placeholder={"홈택스 패스워드를 입력해주세요"}
                  ></input>

                </div>

                <Button
                  buttonText={"입력하기"}
                  callback={_handlehometaxpw}
                  containerStyle={{
                    backgroundColor: colors.mainbuttoncolor,
                    color: "#fff",
                    margin: "450px 5% 0px",
                    width: '90%',
                    height: 40,
                    fontSize: 17,
                    fontWeight: 700,
                    borderRadius: "10px",
                  }}
                />
                </>   
              }
              {
                INFOTYPE == InfoType.HOUSE && 
                <>
                <Guide
                height={0}
                main1={"주택임대소득을 입력해주세요"}
                 ></Guide>
                <div style={{padding:"10px 20px", display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start"}}>
                  <input
                    type="text"
                    value={house}
                    onChange={(e) => {
                      setHouse(e.target.value);
                      setRefresh((refresh) => refresh +1);
                    }}
                    className="inputInfoCommon"
                    placeholder={"주택임대소득을 입력해주세요"}
                  ></input>

                </div>

                <Button
                  buttonText={"입력하기"}
                  callback={_handlehouse}
                  containerStyle={{
                    backgroundColor: colors.mainbuttoncolor,
                    color: "#fff",
                    margin: "450px 5% 0px",
                    width: '90%',
                    height: 40,
                    fontSize: 17,
                    fontWeight: 700,
                    borderRadius: "10px",
                  }}
                />
                </>   
              }
      
              {
                INFOTYPE == InfoType.BUSINESS && 
                <>
                <Guide
                height={0}
                main1={"사업자 등록 기타소득을 입력해주세요"}
                 ></Guide>
                <div style={{padding:"10px 20px", display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start"}}>
                  <input
                    type="text"
                    value={business}
                    onChange={(e) => {
                      setBusiness(e.target.value);
                      setRefresh((refresh) => refresh +1);
                    }}
                    className="inputInfoCommon"
                    placeholder={"주택임대소득을 입력해주세요"}
                  ></input>

                </div>

                <Button
                  buttonText={"입력하기"}
                  callback={_handlebusiness}
                  containerStyle={{
                    backgroundColor: colors.mainbuttoncolor,
                    color: "#fff",
                    margin: "450px 5% 0px",
                    width: '90%',
                    height: 40,
                    fontSize: 17,
                    fontWeight: 700,
                    borderRadius: "10px",
                  }}
                />
                </>   
              }
                    {
                INFOTYPE == InfoType.FINANCIAL && 
                <>
                <Guide
                height={0}
                main1={"2천만원 이상 금융소득을 입력해주세요"}
                 ></Guide>
                <div style={{padding:"10px 20px", display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start"}}>
                  <input
                    type="text"
                    value={financial}
                    onChange={(e) => {
                      setFinancial(e.target.value);
                      setRefresh((refresh) => refresh +1);
                    }}
                    className="inputInfoCommon"
                    placeholder={"주택임대소득을 입력해주세요"}
                  ></input>

                </div>

                <Button
                  buttonText={"입력하기"}
                  callback={_handlefinancial}
                  containerStyle={{
                    backgroundColor: colors.mainbuttoncolor,
                    color: "#fff",
                    margin: "450px 5% 0px",
                    width: '90%',
                    height: 40,
                    fontSize: 17,
                    fontWeight: 700,
                    borderRadius: "10px",
                  }}
                />
                </>   
              }
          
            </PCContainer>
          </PC>

        </div>
      ) : null}
    </>
  );
};

export default Infocontainer;
