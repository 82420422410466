import React, {
  useState,
  useEffect,
  useContext,
  useLayoutEffect,
  useRef,
} from "react";
import {
  HashRouter,
  Route,
  Redirect,
  BrowserRouter,
  Routes,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Swipe from "../common/Swipe";

import styled from "styled-components";

import Guide from "../components/Guide";
import Swipe2 from "../common/Swipe2";
import Button from "../common/Button";
import { colors, theme } from "../theme/theme";
import { Mobile, PC } from "../components/Responsive";
import { imageDB } from "../utility/imageData";
import { getnotice } from "../service/UserService";

import Fade from "react-reveal/Fade";

import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import { ThemeColor } from "../utility/theme";
import AcceptModalEx from "../components/AcceptModalEx";
import { VelocityComponent } from "velocity-react";
import Zoom from "react-reveal/Zoom";

const Container = styled.div`
  background: ${({ bgcolor }) => bgcolor};
  height: ${({ height }) => height - 30}px;
`;
const PCContainer = styled.div`
  width: 27%;
  margin: 0px 37%;
  background: white;
  height: ${({ height }) => height - 10}px;
  border : 1px solid;
`;
const MainItem = styled.div`
  font-size: 23px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: left;
  height: 40px;
  color: #fff;
  font-family:Pretendard-Bold;
`;

const SubItem = styled.div`
  font-size: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: left;
  height: 30px;
  color: #fff;
  font-family:Pretendard-Bold;
`;

const BoxItem = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
  padding-top: 100px;
`;

const swiperStyle = {
  position: "relative",
  width: "100%",
};

const VelocityLetter = ({ letter }) => (
  <VelocityComponent
    runOnMount
    animation={{ opacity: 1, marginTop: 0 }}
    duration={500}
  >
    <p style={styles.letter}>{letter}</p>
  </VelocityComponent>
);

const Gatecontainer = () => {
  const navigate = useNavigate();

  const [height, setHeight] = useState(window.innerHeight);

  const [noticeitems, setNoticeitems] = useState([]);
  const [notice, setNotice] = useState("");
  const [swiperIndex, setSwiperIndex] = useState(0);

  const [acceptstatus, setAcceptstatus] = useState(false);
  const [refresh, setRefresh] = useState(1);

  SwiperCore.use([Autoplay]);

  const _handlenext = () => {
    setAcceptstatus(!acceptstatus);
    setRefresh((refresh) => refresh + 1);
    // navigate("/privacyinfo");
  };

  useEffect(() => {}, [refresh]);
  const _handlelogin = () => {
    navigate("/login");
  };

  const acceptcallback = (type) => {
    if (type == false) {
      return;
    }
    navigate("/basicinfo");
  };

  useEffect(() => {
    async function FetchData() {
      const items = await getnotice();

      console.log("noticeitems", items);

      setNoticeitems(items);
    }
    FetchData();
  }, []);

  const letters1 = ["2", "0", "2", "4"];
  const letters2= [
      "청",
      "람",
      "세",
      "무",
      "회",
      "계",
      "사",
      "무",
      "소",
    ];
  return (
    <>

      {acceptstatus == true && <AcceptModalEx callback={acceptcallback} />}

      <Mobile>
    

        <Container height={height} bgcolor={"white"}>
          <div style={{ background: "#424141", paddingTop: 20 }}>
            <MainItem color={ThemeColor.mainTxtcolor}>
              {"2024 종합소득세 세무대리 접수"}
            </MainItem>
            <SubItem>{"(청람세무회계)"}</SubItem>\
          </div>


          <BoxItem>
            <Fade bottom delay={100}>
              <img src={imageDB.tax} width={200} height={200} />
            </Fade>

            <div style={{ display: "flex", flexDirection: "row" }}>
              {letters1.map((data, index) => (
                <Fade left delay={500 * index}>
                  <div
                    style={{
                      fontWeight: 900,
                      fontSize: 22,
                      letterSpacing: 1.2,
                      fontFamily:'Pretendard-Bold'
                    }}
                  >
                    {data}
                  </div>
                </Fade>
              ))}
            </div>

            <div style={{ display: "flex", flexDirection: "row" }}>
              {letters2.map((data, index) => (
                <Fade bottom delay={500 * index}>
                  <div
                    style={{
                      fontWeight: 900,
                      fontSize: 22,
                      letterSpacing: 1.2,
                      fontFamily:'Pretendard-Bold'
                    }}
                  >
                    {data}
                  </div>
                </Fade>
              ))}
            </div>
          </BoxItem>

          <div>
            <div style={{ marginTop: 180 }}></div>
          </div>
          {/* <div
            style={{
              display: "flex",
              justifyContent: "center",
              position: "absolute",
              bottom: 70,
              width: "100%",
            }}
          >
            <Button
              buttonText={"청람에 2024년도 세무대리 하셨던 분"}
              callback={_handlelogin}
              containerStyle={{
                background: "#cdcdcc",
                color: "#090909",
                border: "1px solid #eaeaea",
                margin: "10px",
                width: "85%",
                height: 50,
                fontSize: 15,
                borderRadius: "10px",
                fontFamily:'Pretendard-Bold'
              }}
            />
          </div> */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              position: "absolute",
              bottom: 10,
              width: "100%",
            }}
          >
            <Button
              buttonText={"시작하기"}
              callback={_handlenext}
              containerStyle={{
                backgroundColor: colors.mainbuttoncolor,
                color: "#fff",
                margin: "10px",
                width: "85%",
                height: 50,
                fontSize: 18,
                borderRadius: "10px",
                fontFamily:'Pretendard-Bold'
              }}
            />
          </div>
        </Container>
      </Mobile>
      <PC>
        <PCContainer height={height} bgcolor={"white"}>
          <div style={{ background: "#424141", paddingTop: 20 }}>
            <MainItem color={ThemeColor.mainTxtcolor}>
              {"2024 종합소득세 세무대리 접수"}
            </MainItem>
            <SubItem>{"(청람세무회계)"}</SubItem>\
          </div>


          <BoxItem>
            <Fade bottom delay={100}>
              <img src={imageDB.tax} width={200} height={200} />
            </Fade>

            <div style={{ display: "flex", flexDirection: "row" }}>
              {letters1.map((data, index) => (
                <Fade left delay={500 * index}>
                  <div
                    style={{
                      fontWeight: 900,
                      fontSize: 22,
                      letterSpacing: 1.2,
                      fontFamily:'Pretendard-Bold'
                    }}
                  >
                    {data}
                  </div>
                </Fade>
              ))}
            </div>

            <div style={{ display: "flex", flexDirection: "row" }}>
              {letters2.map((data, index) => (
                <Fade bottom delay={500 * index}>
                  <div
                    style={{
                      fontWeight: 900,
                      fontSize: 22,
                      letterSpacing: 1.2,
                      fontFamily:'Pretendard-Bold'
                    }}
                  >
                    {data}
                  </div>
                </Fade>
              ))}
            </div>
          </BoxItem>

          <div>
            <div style={{ marginTop: 180 }}></div>
          </div>
          {/* <div
            style={{
              display: "flex",
              justifyContent: "center",
              position: "absolute",
              bottom: 70,
              width: "30%",
            }}
          >
            <Button
              buttonText={"청람에 2024년도 세무대리 하셨던 분"}
              callback={_handlelogin}
              containerStyle={{
                background: "#cdcdcc",
                color: "#090909",
                border: "1px solid #eaeaea",
                margin: "10px",
                width: "100%",
                height: 50,
                fontSize: 15,
                borderRadius: "10px",
                fontFamily:'Pretendard-Bold'
              }}
            />
          </div> */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              position: "absolute",
              bottom: 10,
              width: "27%",
            }}
          >
            <Button
              buttonText={"시작하기"}
              callback={_handlenext}
              containerStyle={{
                backgroundColor: colors.mainbuttoncolor,
                color: "#fff",
                margin: "10px",
                width: "100%",
                height: 50,
                fontSize: 15,
                borderRadius: "10px",
                fontFamily:'Pretendard-Bold'
              }}
            />
          </div>
        </PCContainer>
      </PC>
    </>
  );
};

const styles = {
  input: {
    height: 40,
    backgroundColor: "#ddd",
    width: 200,
    border: "none",
    outline: "none",
    marginBottom: 20,
    fontSize: 22,
    padding: 8,
  },
  letters: {
    display: "flex",
    height: 140,
  },
  letter: {
    opacity: 0,
    marginTop: 100,
    fontSize: 22,
    whiteSpace: "pre",
  },
};

export default Gatecontainer;
