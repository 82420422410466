import React,{useState, useEffect} from 'react';
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, Link, useNavigate} from "react-router-dom";
import styled from 'styled-components';
import { imageDB } from '../utility/imageData';
import { Mobile, PC } from './Responsive';
import { updatetaxalarm } from '../service/UserService';

const PCContainer = styled.div`
  margin: 10px 10%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  line-height: 2;
  padding: 10px 20px 10px 20px;
  background: ${({ read }) => (read == true ? "#fff" : "#ffec1f")};
  box-shadow: ${({ read }) =>
    read == true ? "null" : "#1px 1px 1px 2px #e2e2e2"};
  border: ${({ read }) => (read == true ? "1px solid #ededed" : "null")};
`;

const Container = styled.div`
  margin: 10px 3%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  line-height: 2;
  padding: 10px 20px 10px 20px;
  background: ${({ read }) => (read == true ? "#fff" : "#ffec1f")};
  box-shadow: ${({ read }) =>
    read == true ? "null" : "#1px 1px 1px 2px #e2e2e2"};
  border: ${({ read }) => (read == true ? "1px solid #ededed" : "null")};
`;
const LabelItem = styled.div`
  font-weight: 500;
  line-height: 1.4;
  font-size: 0.875rem;
  text-decoration-line: none;
  color: rgb(58, 64, 71);
  display : flex;
  flex-direction : row;

`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  width:300px;

`;
const ItemLabel = styled.div`
  width: 20%;
  height: 60px;
  background-color: #ededed;
  border-radius: 50%;
  position: relative;
`;
const ItemData = styled.div`
  width: 80%;
  padding-left: 25px;
  font-weight: 600;
  line-height: 1.5;
  font-size: 1.125rem;
  text-decoration-line: none;
  color: ${({ color }) => color};
  text-align: left;
`;

const LineControl = styled.div`
  margin-top:20px;
  width: 100%;
  height: 1px;
  background-color: #f0f1f2;
`;

const Unread = styled.div`
  background: #f50a0a;
  color: #fff;
  padding: 0px 10px;
  border-radius: 10px;
  font-size:12px;
`;

const NoticeItem = ({containerStyle, label, content, color, read, index, items, tax_id}) => {

  const navigate = useNavigate();
   useEffect(()=>{
    async function fetchData(){
		}
		fetchData();
  }, [])

  const [border, setBorder] = useState(false);
  const handleFocusBorder = () => {
    setBorder(true);
  }
  const handleNoFocusBorder = () => {
    setBorder(false);
  };
  const _handlenoticedetail = async() => {

  items[index].msgread = 1;
  
  const alarm = items;

  const updatemsg = await updatetaxalarm({ tax_id, alarm });
            
    navigate("/noticedetail", { state: { content: content , label : label} });  
  }

  return (
    <>
      <Mobile>
        <Container
          style={containerStyle}
          read={read}
          onClick={_handlenoticedetail}
        >
          <LabelItem>
            <div>{label}</div>
            <div style={{ paddingLeft: 20 }}>
              {read == 1 && <div>읽음</div>}
              {read == 0 && <Unread>읽지않음</Unread>}
            </div>
          </LabelItem>
          <Content>
            <ItemLabel>
              <img
                src={imageDB.iconsample}
                style={{ width: "40px", position: "relative", top: "15px" }}
              />
            </ItemLabel>
            <ItemData color={color}>
              {content.slice(0, 40)}
              {content.length > 40 ? "..." : null}
            </ItemData>
          </Content>
        </Container>
      </Mobile>
      <PC>

          <Container
            read={read}
            onClick={_handlenoticedetail}
          >
            <LabelItem>
              <div>{label}</div>
              <div style={{ paddingLeft: 20 }}>
                {read == 1 && <div>읽음</div>}
                {read == 0 && <Unread>읽지않음</Unread>}
              </div>
            </LabelItem>
            <Content>
              <ItemLabel>
                <img
                  src={imageDB.iconsample}
                  style={{ width: "40px", position: "relative", top: "15px" }}
                />
              </ItemLabel>
              <ItemData color={color}>
                {content.slice(0, 40)}
                {content.length > 40 ? "..." : null}
              </ItemData>
            </Content>
          </Container>
  
      </PC>
    </>
  );
}

export default NoticeItem;
