import React, { Fragment, useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { imageDB } from "../../../utility/imageData";
import Image from "../../../common/Image";
import Text from "../../../common/Text";

import { UserContext } from "../../../context/User";
import { IsEmpty, SearchAddress } from "../../../utility/common";


import { AiOutlineCaretDown, AiOutlineDown, AiOutlineLogout } from "react-icons/ai";
import { Mobile, PC } from "../../../components/Responsive";
import Badge from "../../../common/Badge";
import { setEmitFlags } from "typescript";
import { get_tax } from "../../../service/UserService";


const Container = styled.div`
  color : white;
  height:100%;
`;
const PCContainer = styled.div`
  color: white;
  position: fixed;
  top: 0px;
  left: 37%;
  width:27%;
`;

const Header = () => {
  const navigation = useNavigate();
  const { user, dispatch2 } = useContext(UserContext);
  const [width, setWidth] = useState(window.innerWidth);
  const [msgcount, setMsgcount] = useState(0);


  const _handleInfo = () => {
    navigation("/info");

  


  }
  const _handleNotice = () => {
    navigation("/notice");
  };
  const _handleLogout = () => {
      navigation("/");
  };

  useEffect(() => {

    async function FetchData() {
      const username = user.name;

      const phonedata = user.phone.replaceAll("-", ""); 

      const taxitem = await get_tax({ username, phonedata });
     
      let ncount = 0;
      if (!IsEmpty(taxitem)) {
        taxitem.alarm.map((data, index) => {
          if (data.msgread == 0) {
            ncount++;
          }
        });
      }
  
      setMsgcount(ncount);
    }
    FetchData();



  },[])
 
  return (
    <>
      <Mobile>
        <Container
          id="header"
          style={{
            zIndex: 999,
            position: "fixed",
            background: "#fff",
            borderBottom: "1px solid #ededed",
            height: "60px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width:'100%'
          }}
        >
          <div>
            <Image
              source={imageDB.logo}
              containerStyle={{ width: 55, paddingLeft: 10, height: 35 }}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              paddingLeft: 20,
              alignItems: "center",
              marginTop: 5,
              width: width/2,
              color: "#000",
              fontWeight: 600,
            }}
          >
            청람세무회계 사무소
          </div>


          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginRight: 0,
              alignItems: "center",
            }}
          >
            <div
              onClick={_handleNotice}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <Image
                source={imageDB.bell}
                containerStyle={{ width: 15, marginRight: 10 }}
              />
              <Badge
                count={msgcount}
                width={30}
                height={35}
                backgroundColor={"#FF4E19"}
                color={"#FFF"}
                containerStyle={{
                  position: "relative",
                  backgroundColor: "#FF4E19",
                  width: "20px",
                  right: "15px",
                  top: "-10px",
                  bottom: "1px",
                  borderRadius: "5px",
                }}
              />
            </div>

            {/* <div onClick={_handleInfo}>
              <Image
                source={imageDB.setting}
                containerStyle={{ width: 20, marginRight: 10, marginLeft:-10 }}
              />
            </div> */}

            <div
              onClick={_handleLogout}
              style={{
                display: "flex",
                marginRight: "10px",
              }}
            >
              <AiOutlineLogout size={18} color={"#000"} />
            </div>
          </div>
        </Container>
      </Mobile>
      <PC>
        <PCContainer
          id="header"
          style={{
            zIndex: 999,
            position: "fixed",
            background: "#fff",
            border: "1px solid #000",
            height: "60px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width:"27%"
          }}
        >
          <div>
            <Image
              source={imageDB.logo}
              containerStyle={{ width: 55, paddingLeft: 10, height: 35 }}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              paddingLeft: 20,
              alignItems: "center",
              marginTop: 5,
              width: "310px",
              color: "#000",
              fontWeight: 600,
            }}
          >
            청람세무회계 사무소
          </div>


          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginRight: 0,
              alignItems: "center",
            }}
          >
            <div
              onClick={_handleNotice}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <Image
                source={imageDB.bell}
                containerStyle={{ width: 15, marginRight: 10 }}
              />
              <Badge
                count={msgcount}
                width={30}
                height={35}
                backgroundColor={"#FF4E19"}
                color={"#FFF"}
                containerStyle={{
                  position: "relative",
                  backgroundColor: "#FF4E19",
                  width: "20px",
                  right: "15px",
                  top: "-10px",
                  bottom: "1px",
                  borderRadius: "5px",
                }}
              />
            </div>

            {/* <div onClick={_handleInfo}>
              <Image
                source={imageDB.setting}
                containerStyle={{ width: 20, marginRight: 10, marginLeft:-10 }}
              />
            </div> */}

            <div
              onClick={_handleLogout}
              style={{
                display: "flex",
                marginRight: "10px",
              }}
            >
              <AiOutlineLogout size={18} color={"#000"} />
            </div>
          </div>
        </PCContainer>
      </PC>
    </>
  );
};

export default Header;
