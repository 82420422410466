import React, {
  useState,
  useEffect,
  useContext,
  useLayoutEffect,
  useRef,
} from "react";
import {
  HashRouter,
  Route,
  Redirect,
  BrowserRouter,
  Routes,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Swipe from "../common/Swipe";

import styled from "styled-components";
import Guide from "../components/Guide";
import Swipe2 from "../common/Swipe2";
import Button from "../common/Button";
import { colors, theme } from "../theme/theme";
import InputItem from "../components/InputItem";
import { imageDB } from "../utility/imageData";
import AcceptModalEx from "../components/AcceptModalEx";
import { Mobile, PC } from "../components/Responsive";

import "../screen/css/common.css";
import { LoginType } from "../utility/contentDefine";
import { auth } from "../api/config";
import { add_tax, login, update_tax } from "../service/UserService";
import AuthAlert from "../components/AuthAlert";
import {
  getGovernment24Token,
  getHomeTaxToken,
  getIncome,
  getPrivacy,
  getPrivacyToken,
  getWeTax,
  getWeTaxToken,
  getYear,
  getYearToken,
} from "../utility/api";
import { AuthErroMsgFind, getDateFullTime, useSleep } from "../utility/common";
import { UserContext } from "../context/User";
import { FaCircleInfo } from "react-icons/fa6";

const Container = styled.div`
  padding-top: 80px;
  background: white;
  height: ${({ height }) => height - 80}px;
`;
const PCContainer = styled.div`
width: 27%;
margin: 0px 37%;
  background: white;
  padding-top: 80px;
  height: ${({ height }) => height - 80}px;
  border :1px solid;
`;

const AuthLayer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 30px;
  flex-wrap: wrap;
  padding: 0px 10%;
`;

const AuthButton = styled.div`
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  border: 1px solid #ededed;
  margin: 2px;
  background: #fafafa;
  border-radius: 10px;
`;

// 간편인증 업체에 인증 키  요청을 진행 하고 
// 인증키를 바탕으로 5초마다 인증키를 가지고 위택스 정보인 
// 지방세 대한 정보를 요청 한다

const Wetaxgetcontainer = ({ logintype, tax_id }) => {
  const navigate = useNavigate();
  const [height, setHeight] = useState(window.innerHeight);

  const [notice, setNotice] = useState("");

  const [notice2, setNotice2] = useState("");

  const [refresh, setRefresh] = useState(1);
  const [wetax, setWetax] = useState(true);
  const [error, setError] = useState(false);
  const {user, dispatch2 } = useContext(UserContext);
  const [wetaxprocess, setWetaxprocess] = useState(0);
  const [wetaxprocessfail, setWetaxprocessfail] = useState(false);
  const [wetaxprocesscomplete, setWetaxprocesscomplete] = useState(false);

  const [isclicked, setIsclicked] = useState(false);


  function sleep(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  useEffect(() => {
    let noticetmp = "마지막 인증 절차입니다";
    setNotice(noticetmp);

    noticetmp = "위택스로 부터 정보 수집을 위해" + logintype+ "  에 간편인증 요청을 하였습니다";
    setNotice2(noticetmp);

    async function FetchData() {
      console.log("요청합니다");

      const wetaxToken = await getWeTaxToken({user});
      console.log("wetaxToken",  wetaxToken.data.data.step_data,wetaxToken);

      if ((wetaxToken.data.common.errMsg != "")) {
        setError(true);
        return;
      } else {
        user["wetax"] = wetaxToken.data.data.step_data;
        dispatch2(user);
        setWetax(true);
      }


    }
    FetchData();
  }, []);

  const _handlenext = async () => {


    useSleep(1000);
    setWetaxprocess(1);

    initProcess().then(()=>{
      setWetaxprocess(2);
      setWetaxprocesscomplete(true);
  
      setRefresh((refresh) => refresh +1);
    });
    

  };

  useEffect(() => {
    setWetaxprocess(wetaxprocess);
    setWetaxprocessfail(wetaxprocessfail);
    setWetaxprocesscomplete(wetaxprocesscomplete);


  }, [refresh]);


  const initProcess = async()=>{
    // 지방세 정보 가져왔네
    for(let i = 0; i < 6; i++){

      console.log("지방세 수집",getDateFullTime(Date.now()), user.wetax)
      const wetaxkey = user.wetax;
    
      const localtaxdata = await getWeTax({ user, wetaxkey });


      console.log("localtaxdata", localtaxdata);


      if(!AuthErroMsgFind(localtaxdata.data.common.errMsg)){

        console.log("locattaxdata success",localtaxdata.data);
      
        user["localtaxdata"] = localtaxdata.data.data;
        dispatch2(user);
        i = 6;
      }

 
       await sleep(10000)
 
     }

     return new Promise(async (resolve, reject)=>{
      resolve(0);
     });


  }



  const _handlewetaxfail = async () => {
    navigate("/");
  };

  const _handlewetaxnext = async () => {

      if(isclicked == true){
        return;
      }

      setIsclicked(true);
      const username = user.name;
      const phone = user.phone;
      const bizrealstate = user.bizrealstate;
      const bizhumancost = user.bizhumancost;
      const biznum = user.biznum;
      const bank = user.bank;
      const hometaxid = user.hometaxid;
      const hometaxpw = user.hometaxpw;
      const wetaxid = user.wetaxid;
      const wetaxpw = user.wetaxpw;

      const yeardata = user.yeardata;
      const incomedata = user.incomedata;
      const vatdata =user.vatdata;
      const vatdata2 = user.vatdata2;
      const vatdata3 = user.vatdata3;
      const vatdata4 = user.vatdata4;
      const vatdata5 = user.vatdata5;
      const vatdata6 = user.vatdata6;
      const withholdingdata = user.withholdingdata;
      const withholdingdata2 = user.withholdingdata2;
      const withholdingdata3 = user.withholdingdata3;
      const localtaxdata = user.localtaxdata;


    const addtax = await update_tax({
      tax_id,
      localtaxdata,
    });




    navigate("/newhome");
  };

  return (
    <>
      <Mobile>
        <Container height={800}>
          <Guide main1={notice2}></Guide>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              fontSize: 16,
              padding: 20,
              justifyContent: "flex-start",
              alignItems: "flex-start",
              textAlign: "left",
              lineHeight: 2,
              marginTop:20,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FaCircleInfo />
              <div style={{ marginLeft: 10 }}>인증전 꼭 확인해주세요</div>
            </div>

            <div>고객님 명의 스마트폰에 앱이 설치되어 있어야합니다</div>

            <div>
               모바일에서 {logintype}로 인증을 완료하신경우 아래 인증완료 버튼을 클릭해주세요
            </div>
          </div>
          {/* {wetax == true && (
            <div>
              <AuthAlert
                logintype={logintype}
                agency={"위택스"}
                containerStyle={{
                  border: "1px solid #ededed",
                  background: "#fdf9f9",
                  height: "80px",
                  position: "absolute",
                  top: "65%",
                  left: "5%",
                  width: "300px",
                  zIndex: 5,
                  lineHeight:1.5,
                }}
              />
            </div>
          )} */}
      

          {error == true ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                position: "absolute",
                bottom: 0,
              }}
            >
              <Button
                buttonText={"정보가 불일치 합니다 초기단계로 이동"}
                callback={_handlewetaxfail}
                containerStyle={{
                  backgroundColor: "#cccbc9",
                  color: "#000",
                  margin: "10px",
                  width: "100%",
                  height: 50,
                  fontSize: 17,
                  fontWeight: 700,
                  borderRadius: "10px",
                }}
              />
            </div>
          ) : (
            <>
              {wetax == true && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    position: "absolute",
                    bottom: 0,
                  }}
                >
                  {(logintype == LoginType.kakao && wetaxprocess == 0) && (
                    <Button
                      buttonText={logintype + " 인증 완료후 클릭해주세요"}
                      callback={_handlenext}
                      containerStyle={{
                        backgroundColor: "#ffc83e",
                        color: "#000",
                        margin: "30px 10x",
                        width: "95%",
                        height: 50,
                        fontSize: 16,
                        fontWeight: 700,
                        borderRadius: "10px",
         
                      }}
                    />
                  )}
                  {(logintype == LoginType.samsung && wetaxprocess == 0) && (
                    <Button
                      buttonText={logintype + " 인증 완료후 클릭해주세요"}
                      callback={_handlenext}
                      containerStyle={{
                        backgroundColor: colors.mainbuttoncolor,
                        color: "#fff",
                        margin: "30px 10x",
                        width: "95%",
                        height: 50,
                        fontSize: 16,
                        fontWeight: 700,
                        borderRadius: "10px",
                        fontFamily :"Pretendard-Bold"
                      }}
                    />
                  )}
                  {(logintype == LoginType.pass && wetaxprocess == 0 )&& (
                    <Button
                      buttonText={logintype + " 인증 완료후 클릭해주세요"}
                      callback={_handlenext}
                      containerStyle={{
                        backgroundColor: "#ff2121",
                        color: "#fff",
                        margin: "30px 10x",
                        width: "95%",
                        height: 50,
                        fontSize: 16,
                        fontWeight: 700,
                        borderRadius: "10px",
                        fontFamily :"Pretendard-Bold"
                      }}
                    />
                  )}
                  {(logintype == LoginType.payco && wetaxprocess == 0) && (
                    <Button
                      buttonText={logintype + " 인증 완료후 클릭해주세요"}
                      callback={_handlenext}
                      containerStyle={{
                        backgroundColor: "#ff2121",
                        color: "#fff",
                        margin: "30px 10x",
                        width: "95%",
                        height: 50,
                        fontSize: 16,
                        fontWeight: 700,
                        borderRadius: "10px",
                        fontFamily :"Pretendard-Bold"
                      }}
                    />
                  )}

                  {(logintype == LoginType.kb && wetaxprocess == 0) && (
                    <Button
                      buttonText={logintype + " 인증 완료후 클릭해주세요"}
                      callback={_handlenext}
                      containerStyle={{
                        backgroundColor: "#ffc83e",
                        color: "#000",
                        margin: "30px 10x",
                        width: "95%",
                        height: 50,
                        fontSize: 16,
                        fontWeight: 700,
                        borderRadius: "10px",
                        fontFamily :"Pretendard-Bold"
                      }}
                    />
                  )}
                  {wetaxprocess == 1 && (
                      <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItem:"center"}}>
                    
                      <div style={{display:"flex", flexDirection:"row", justifyContent:"center", alignItem:"center"}}>
                      <img src={imageDB.loading} style={{ width: "100px" }} />
                      </div>
                   
                      <Button
                        buttonText={"인증 결과 조회 대기중입니다"}
                        callback={()=>{}}
                        containerStyle={{
                          backgroundColor: '#f9f9f9',
                          color: '#000',
               
                          height: '60px',
                          fontSize: '14px',
                          borderRadius: '10px'
                        }}
                      />
                      </div>

                  )}
                  {wetaxprocessfail == true && (
                  <>
                    <Button
                      buttonText={"위택스에서 정보를 가져올수가 없습니다"}
                      callback={_handlewetaxfail}
                      containerStyle={{
                        backgroundColor: "#ffc247",
                        color: "#000",
                        margin: "10px",
                        width: "100%",
                        height: 50,
                        fontSize: 16,
                        fontWeight: 700,
                        borderRadius: "10px",
                      }}
                    />
                  </>
                  )}
                  {wetaxprocesscomplete == true && (
                  <>

               

                  <Button
                    buttonText={"정상적으로 수집하였습니다 클릭해주세요"}
                    callback={_handlewetaxnext}
                    containerStyle={{
                      backgroundColor: "#ffc247",
                      margin: "10px",
                      width: "100%",
                      height: 60,
                      fontSize: 16,
                      fontWeight: 700,
                      borderRadius: "10px",
                    }}
                  />
                  </>
                  )}
                </div>
              )}
            </>
          )}
        </Container>
      </Mobile>
      <PC>

      <PCContainer height={height}>
          <Guide main1={notice}></Guide>
          <Guide main1={notice2}></Guide>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              fontSize: 16,
              padding: 20,
              justifyContent: "flex-start",
              alignItems: "flex-start",
              textAlign: "left",
              lineHeight: 2,
              marginTop:20,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FaCircleInfo />
              <div style={{ marginLeft: 10 }}>인증전 꼭 확인해주세요</div>
            </div>

            <div>고객님 명의 스마트폰에 앱이 설치되어 있어야합니다</div>

            <div>
               모바일에서 {logintype}로 인증을 완료하신경우 아래 인증완료 버튼을 클릭해주세요
            </div>
          </div>
          {/* {wetax == true && (
            <div>
              <AuthAlert
                logintype={logintype}
                agency={"위택스"}
                containerStyle={{
                  border: "1px solid #ededed",
                  background: "#fdf9f9",
                  height: "80px",
                  position: "absolute",
                  top: "65%",
                  left: "38%",
                  width: "300px",
                  zIndex: 5,
                }}
              />
            </div>
          )} */}
      

          {error == true ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "27%",
                position: "absolute",
                bottom: 0,
              }}
            >
              <Button
                buttonText={"정보가 불일치 합니다 초기단계로 이동"}
                callback={_handlewetaxfail}
                containerStyle={{
                  backgroundColor: "#cccbc9",
                  color: "#000",
                  margin: "10px",
                  width: "95%",
                  height: 50,
                  fontSize: 16,
                  fontWeight: 700,
                  borderRadius: "10px",
                }}
              />
            </div>
          ) : (
            <>
              {wetax == true && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "27%",
                    position: "absolute",
                    bottom: 0,
                  }}
                >
                  {(logintype == LoginType.kakao && wetaxprocess == 0) && (
                    <Button
                      buttonText={logintype + " 인증 완료후 클릭해주세요"}
                      callback={_handlenext}
                      containerStyle={{
                        backgroundColor: "#ffc83e",
                        color: "#000",
                        margin: "30px 10x",
                        width: "95%",
                        height: 50,
                        fontSize: 16,
                        fontWeight: 700,
                        borderRadius: "10px",
         
                      }}
                    />
                  )}
                  {(logintype == LoginType.samsung && wetaxprocess == 0) && (
                    <Button
                      buttonText={logintype + " 인증 완료후 클릭해주세요"}
                      callback={_handlenext}
                      containerStyle={{
                        backgroundColor: colors.mainbuttoncolor,
                        color: "#fff",
                        margin: "30px 10x",
                        width: "95%",
                        height: 50,
                        fontSize: 16,
                        fontWeight: 700,
                        borderRadius: "10px",
                        fontFamily :"Pretendard-Bold"
                      }}
                    />
                  )}
                  {(logintype == LoginType.pass && wetaxprocess == 0 )&& (
                    <Button
                      buttonText={logintype + " 인증 완료후 클릭해주세요"}
                      callback={_handlenext}
                      containerStyle={{
                        backgroundColor: "#ff2121",
                        color: "#fff",
                        margin: "30px 10x",
                        width: "95%",
                        height: 50,
                        fontSize: 16,
                        fontWeight: 700,
                        borderRadius: "10px",
                        fontFamily :"Pretendard-Bold"
                      }}
                    />
                  )}
                  {(logintype == LoginType.payco && wetaxprocess == 0) && (
                    <Button
                      buttonText={logintype + " 인증 완료후 클릭해주세요"}
                      callback={_handlenext}
                      containerStyle={{
                        backgroundColor: "#ff2121",
                        color: "#fff",
                        margin: "30px 10x",
                        width: "95%",
                        height: 50,
                        fontSize: 16,
                        fontWeight: 700,
                        borderRadius: "10px",
                        fontFamily :"Pretendard-Bold"
                      }}
                    />
                  )}

                  {(logintype == LoginType.kb && wetaxprocess == 0) && (
                    <Button
                      buttonText={logintype + " 인증 완료후 클릭해주세요"}
                      callback={_handlenext}
                      containerStyle={{
                        backgroundColor: "#ffc83e",
                        color: "#000",
                        margin: "30px 10x",
                        width: "95%",
                        height: 50,
                        fontSize: 16,
                        fontWeight: 700,
                        borderRadius: "10px",
                        fontFamily :"Pretendard-Bold"
                      }}
                    />
                  )}
                  {wetaxprocess == 1 && (
                       <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItem:"center"}}>
                    
                       <div style={{display:"flex", flexDirection:"row", justifyContent:"center", alignItem:"center"}}>
                       <img src={imageDB.loading} style={{ width: "100px" }} />
                       </div>
                    
                       <Button
                         buttonText={"인증 결과 조회 대기중입니다"}
                         callback={()=>{}}
                         containerStyle={{
                           backgroundColor: '#f9f9f9',
                           color: '#000',
              
                           height: '60px',
                           fontSize: '14px',
                           borderRadius: '10px'
                         }}
                       />
                       </div>

                  )}
                  {wetaxprocessfail == true && (
                  <>
                    <Button
                      buttonText={"위택스에서 정보를 가져올수가 없습니다"}
                      callback={_handlewetaxfail}
                      containerStyle={{
                        backgroundColor: "#ffc247",
                        color: "#000",
                        margin: "10px",
                        width: "100%",
                        height: 50,
                        fontSize: 16,
                        fontWeight: 700,
                        borderRadius: "10px",
                      }}
                    />
                  </>
                  )}
                  {wetaxprocesscomplete == true && (
                  <>

                  <Button
                    buttonText={"정상적으로 수집하였습니다 클릭해주세요"}
                    callback={_handlewetaxnext}
                    containerStyle={{
                      backgroundColor: "#ffc247",
                      margin: "10px",
                      width: "100%",
                      height: 60,
                      fontSize: 16,
                      fontWeight: 700,
                      borderRadius: "10px",
                    }}
                  />
                  </>
                  )}
                </div>
              )}
            </>
          )}
      </PCContainer>
 
      </PC>
    </>
  );
};

export default Wetaxgetcontainer;
