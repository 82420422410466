import React, {
  useState,
  useEffect,
  useContext,
  useLayoutEffect,
  useRef,
} from "react";
import {
  HashRouter,
  Route,
  Redirect,
  BrowserRouter,
  Routes,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Swipe from "../common/Swipe";

import styled from "styled-components";
import Guide from "../components/Guide";
import Swipe2 from "../common/Swipe2";
import Button from "../common/Button";
import { colors, theme } from "../theme/theme";
import InputItem from "../components/InputItem";
import { imageDB } from "../utility/imageData";
import AcceptModalEx from "../components/AcceptModalEx";
import { Mobile, PC } from "../components/Responsive";
import AcceptDocModalEx from "../components/AcceptDocModalEx";
import { LoginType } from "../utility/contentDefine";
import { getIncomeToken, getPrivacyToken, getYearToken } from "../utility/api";
import { UserContext } from "../context/User";

const Container = styled.div`
  padding-top: 70px;
  background: white;
  height: ${({ height }) => height - 50}px;
`;

const PCContainer = styled.div`
  width: 27%;
  margin: 0px 37%;
  background: white;
  padding-top: 80px;
  height: ${({ height }) => height - 80}px;
  border :1px solid;
`;

const AuthLayer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 30px;
  flex-wrap: wrap;
  padding: 0px 5%;
`;

const AuthButton = styled.div`
  width: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 120px;
  margin: 2px 2px 20px;
  border-radius: 10px;
`;
const AuthButtonM = styled.div`
  width: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 120px;
  margin: 2px 2px 20px;
  border-radius: 10px;
`;

const AuthButtonText = styled.span`
  font-size: 0.7rem;
  line-height: 1.25rem;
  padding-top: 5px;
  font-weight: 400;
`;
const Authselectcontainer2 = ({tax_id}) => {
  const navigate = useNavigate();
  const [height, setHeight] = useState(window.innerHeight);
  const { user, dispatch2 } = useContext(UserContext);

  const [logintype, setLogintype] = useState("");
  const [acceptstatus, setAcceptstatus] = useState(false);
  const [refresh, setRefresh] = useState(1);

  console.log("user", user);

  const acceptcallback = async (type) => {
    setAcceptstatus(false);
    if (type == true) {
      console.log("logintype", logintype);

      user["authtype"] = logintype;
      dispatch2(user);

      console.log("wetax tax_id", tax_id);

      navigate("/wetaxget", { state: { LOGINTYPE: logintype, tax_id: tax_id } });
    }
  };

  const _handlenext = (logintype) => {
    //setAcceptstatus(true);

    setLogintype(logintype);
    user["authtype"] = logintype;
    dispatch2(user);

  

    navigate("/wetaxget", { state: { LOGINTYPE: logintype, tax_id: tax_id } });
    
    setRefresh((refresh) => refresh + 1);
  };

  useEffect(() => {
    setLogintype(logintype);
  }, [refresh]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    console.log("user information", user);
  }, []);

  return (
    <>
      <Mobile height={height}>
        <Container>
          {acceptstatus == true && <AcceptModalEx callback={acceptcallback} />}

          <Guide
            main1={"지방세 신고자료 준비를 위한 인증 절차입니다"}
            main2={""}
            sub={
              "지방세 자료 준비를 위해 위택스 인증이 필요합니다 다음중 하나의 방식으로 간편인증을 진행해주세요"
            }
          ></Guide>

          <AuthLayer style={{ paddingBottom: 250 }}>
            {/* <a href="kakaotalk://launch" target="_blank">
              <AuthButtonM
                onClick={() => {
                  _handlenext(LoginType.kakao);
                }}
              >
                <img
                  src={imageDB.kakaoauth}
                  style={{ width: 70, height: 70, borderRadius: 5 }}
                />
                <AuthButtonText>카카오톡</AuthButtonText>
              </AuthButtonM>
            </a> */}
            <AuthButtonM
              onClick={() => {
                _handlenext(LoginType.kakao);
              }}
            >
              <img
                src={imageDB.kakaoauth}
                style={{ width: 70, height: 70, borderRadius: 5 }}
              />
              <AuthButtonText>카카오톡</AuthButtonText>
            </AuthButtonM>

            <AuthButtonM
              onClick={() => {
                _handlenext(LoginType.samsung);
              }}
            >
              <img
                src={imageDB.samsungauth}
                style={{ width: 70, height: 70, borderRadius: 5 }}
              />
              <AuthButtonText>삼성패스</AuthButtonText>
            </AuthButtonM>
            <AuthButtonM
              onClick={() => {
                _handlenext(LoginType.pass);
              }}
            >
              <img
                src={imageDB.passauth}
                style={{ width: 70, height: 70, borderRadius: 5 }}
              />
              <AuthButtonText>통신사PASS</AuthButtonText>
            </AuthButtonM>
            <AuthButtonM
              onClick={() => {
                _handlenext(LoginType.kb);
              }}
            >
              <img
                src={imageDB.kbauth}
                style={{ width: 70, height: 70, borderRadius: 5 }}
              />
              <AuthButtonText>국민인증서</AuthButtonText>
            </AuthButtonM>
            <AuthButtonM
              onClick={() => {
                _handlenext(LoginType.payco);
              }}
            >
              <img
                src={imageDB.paycoauth}
                style={{ width: 70, height: 70, borderRadius: 5 }}
              />
              <AuthButtonText>페이코</AuthButtonText>
            </AuthButtonM>
          </AuthLayer>
        </Container>
      </Mobile>
      <PC>
        <PCContainer height={height}>
          {acceptstatus == true && <AcceptModalEx callback={acceptcallback} />}

          <Guide
            main1={"소득세 신고 자료를 위해서는 정부24"}
            main2={"홈택스 위택스 인증 이 필요합니다"}
            sub={"인증에 필요한 간편인증을 진행하세요"}
          ></Guide>

          <AuthLayer style={{ marginBottom: 150 }}>
            <AuthButton
              onClick={() => {
                _handlenext(LoginType.kakao);
              }}
            >
              <img
                src={imageDB.kakaoauth}
                style={{ width: 70, height: 70, borderRadius: 5 }}
              />
              <AuthButtonText>카카오톡</AuthButtonText>
            </AuthButton>
            <AuthButton
              onClick={() => {
                _handlenext(LoginType.samsung);
              }}
            >
              <img
                src={imageDB.samsungauth}
                style={{ width: 70, height: 70, borderRadius: 5 }}
              />
              <AuthButtonText>삼성패스</AuthButtonText>
            </AuthButton>
            <AuthButton
              onClick={() => {
                _handlenext(LoginType.pass);
              }}
            >
              <img
                src={imageDB.passauth}
                style={{ width: 70, height: 70, borderRadius: 5 }}
              />
              <AuthButtonText>통신사PASS</AuthButtonText>
            </AuthButton>
            <AuthButton
              onClick={() => {
                _handlenext(LoginType.kb);
              }}
            >
              <img
                src={imageDB.kbauth}
                style={{ width: 70, height: 70, borderRadius: 5 }}
              />
              <AuthButtonText>국민인증서</AuthButtonText>
            </AuthButton>
            <AuthButton
              onClick={() => {
                _handlenext(LoginType.payco);
              }}
            >
              <img
                src={imageDB.paycoauth}
                style={{ width: 70, height: 70, borderRadius: 5 }}
              />
              <AuthButtonText>페이코</AuthButtonText>
            </AuthButton>
          </AuthLayer>
        </PCContainer>
      </PC>
    </>
  );
};

export default Authselectcontainer2;
