
import axios from 'axios'
import { LoginType } from './contentDefine';

export const getGovernment24Token = async ({user}) => {
    
  let logintype = "";
  if (user.authtype == LoginType.kakao) {
       logintype = "kakao";
  } else if (user.authtype == LoginType.samsung) {
       logintype = "samsung";
  } else if (user.authtype == LoginType.pass) {
       logintype = "pass";
  } else if (user.authtype == LoginType.kb) {
       logintype = "kb";
  } else if (user.authtype == LoginType.payco) {
       logintype = "payco";
  }

  let juminnum = user.jumin.replaceAll("-", "");
  let phonenum = user.phone.replaceAll("-", "");

  let phoneorg = "";
    if (user.phoneorg == "SKT") {
      phoneorg = "S";
    } else if (user.authtype == "LGU+") {
      phoneorg = "L";
    } else if (user.authtype == "KT") {
      phoneorg = "K";
    } else {
      phoneorg = "";
    }

  const userData = {
    signCert: "",
    signPri: "",
    signPw: "",
    signPwEnc: "",
    sido: user.region1,
    sigg: user.region2,
    cusGb: "01",
    userName: user.name,
    userNameEnc: "",
    bizNo: juminnum,
    bizNoEnc: "",
    req1Opt1: "01",
    req1Opt2: "01",
    req1Opt3: "01",
    req1Opt4: "01",
    req1Opt5: "01",
    req1Opt6: "01",
    req1Opt7: "01",
    req1Opt8: "01",
    req1Opt9: "01",
    authMethod: "EASY",
    loginOrgCd: logintype,
    mobileNo: phonenum,
    mobileNoEnc: "",
    mobileCo:phoneorg,
    step: "init",
    stepData: "",
    stepMode: "step",
  };

  const res = await axios.post(`https://getprivacy-2x7jy4bauq-uc.a.run.app`, {
    body: userData,
  });
  return res;
}
export const getHomeTaxToken = async ({ user }) => {
  let logintype = "";
  if (user.authtype == LoginType.kakao) {
    logintype = "kakao";
  } else if (user.authtype == LoginType.samsung) {
    logintype = "samsung";
  } else if (user.authtype == LoginType.pass) {
    logintype = "pass";
  } else if (user.authtype == LoginType.kb) {
    logintype = "kb";
  } else if (user.authtype == LoginType.payco) {
    logintype = "payco";
  }

  let juminnum = user.jumin.replaceAll("-", "");
  let phonenum = user.phone.replaceAll("-", "");

  let phoneorg = "";
  if (user.phoneorg == "SKT") {
    phoneorg = "S";
  } else if (user.authtype == "LGU+") {
    phoneorg = "L";
  } else if (user.authtype == "KT") {
    phoneorg = "K";
  } else {
    phoneorg = "";
  }
  let year = "";
  year = "19" + juminnum.substr(0, 6);

  console.log('year', year);
  
  const userData = {
    loginMethod: "EASY",
    signCert: "",
    signPri: "",
    signPw: "",
    signPwEnc: "",
    loginOrgCd: logintype,
    resNm: user.name,
    resNmEnc: "",
    resNo: year,
    mobileCo: phoneorg,
    mobileNo: phonenum,
    mobileNoEnc: "",
    step: "init",
    stepData: "",
    stepMode: "step",
    showCookie: "",
    cookieData: "",
    nMemberLoginYn: "",
    bizNo: "",
    bizNoEnc: "",
    bizNm: "",
    bizNmEnc: "",
    mateKndCd: "ALL",
    attrYr: "2023",
    chkMm: "01,02,03,04,05,06,07,08,09,10,11,12",
    uOption: "0",
  };

  const res = await axios.post(
    `https://getyearcalculate-2x7jy4bauq-uc.a.run.app`,
    {
      body: userData,
    }
  );
  return res;
};
export const getWeTaxToken = async ({user}) => {
   let logintype = "";
   if (user.authtype == LoginType.kakao) {
     logintype = "kakao";
   } else if (user.authtype == LoginType.samsung) {
     logintype = "samsung";
   } else if (user.authtype == LoginType.pass) {
     logintype = "pass";
   } else if (user.authtype == LoginType.kb) {
     logintype = "kb";
   } else if (user.authtype == LoginType.payco) {
     logintype = "payco";
   }

  let juminnum = user.jumin.replaceAll("-", "");
   let phonenum = user.phone.replaceAll("-", "");

   let phoneorg = "";
   if (user.phoneorg == "SKT") {
     phoneorg = "S";
   } else if (user.authtype == "LGU+") {
     phoneorg = "L";
   } else if (user.authtype == "KT") {
     phoneorg = "K";
   } else {
     phoneorg = "";
   }
 const userData = {
   nonMemberYn: "",
   loginMethod: "EASY",
   signCert: "",
   signPri: "",
   signPw: "",
   loginOrgCd: logintype,
   resNm: user.name,
   resNo: juminnum,
   mobileNo: phonenum,
   mobileCo: phoneorg,
   stepMode: "step",
   step: "init",
   step_data: "",
   showCookie: "",
   cookieData: "",
   fromDate: "20230101",
   toDate: "20231231",
   detail: "",
   juso_code: "",
   taxItem: "",
 };

  const res = await axios.post(`https://getlocaltax-2x7jy4bauq-uc.a.run.app`, {
    body: userData,
  });
  return res;
};

export const getPrivacy = async ({ user, privacykey }) => {

    let logintype = "";
    if (user.authtype == LoginType.kakao) {
      logintype = "kakao";
    } else if (user.authtype == LoginType.samsung) {
      logintype = "samsung";
    } else if (user.authtype == LoginType.pass) {
      logintype = "pass";
    } else if (user.authtype == LoginType.kb) {
      logintype = "kb";
    } else if (user.authtype == LoginType.payco) {
      logintype = "payco";
    }

    let juminnum = user.jumin.replaceAll("-", "");
    let phonenum = user.phone.replaceAll("-", "");

    let phoneorg = "";
    if (user.phoneorg == "SKT") {
      phoneorg = "S";
    } else if (user.authtype == "LGU+") {
      phoneorg = "L";
    } else if (user.authtype == "KT") {
      phoneorg = "K";
    } else {
      phoneorg = "";
    }

  const userData = {
    signCert: "",
    signPri: "",
    signPw: "",
    signPwEnc: "",
    sido: user.region1,
    sigg: user.region2,
    cusGb: "01",
    userName: user.name,
    userNameEnc: "",
    bizNo: juminnum,
    bizNoEnc: "",
    req1Opt1: "01",
    req1Opt2: "01",
    req1Opt3: "01",
    req1Opt4: "01",
    req1Opt5: "01",
    req1Opt6: "01",
    req1Opt7: "01",
    req1Opt8: "01",
    req1Opt9: "01",
    authMethod: "EASY",
    loginOrgCd: logintype,
    mobileNo: phonenum,
    mobileNoEnc: "",
    mobileCo: phoneorg,
    step: "sign",
    stepData: privacykey,
    stepMode: "step",
  };

  console.log("getPrivay authdata", userData);

  const res = await axios.post(`https://getprivacy-2x7jy4bauq-uc.a.run.app`, {
    body: userData,
  });

  return res;
};
export const getYear = async ({ user, yearkey }) => {
  
    let logintype = "";
    if (user.authtype == LoginType.kakao) {
      logintype = "kakao";
    } else if (user.authtype == LoginType.samsung) {
      logintype = "samsung";
    } else if (user.authtype == LoginType.pass) {
      logintype = "pass";
    } else if (user.authtype == LoginType.kb) {
      logintype = "kb";
    } else if (user.authtype == LoginType.payco) {
      logintype = "payco";
    }

    let juminnum = user.jumin.replaceAll("-", "");
    let phonenum = user.phone.replaceAll("-", "");

    let phoneorg = "";
    if (user.phoneorg == "SKT") {
      phoneorg = "S";
    } else if (user.authtype == "LGU+") {
      phoneorg = "L";
    } else if (user.authtype == "KT") {
      phoneorg = "K";
    } else {
      phoneorg = "";
    }
  let year = "";
  year = "19" + juminnum.substr(0, 6);
  const userData = {

    loginMethod: "EASY",
    signCert: "",
    signPri: "",
    signPw: "",
    signPwEnc: "",
    loginOrgCd: logintype,
    resNm: user.name,
    resNmEnc: "",
    resNo: year,
    mobileCo: logintype,
    mobileNo: phonenum,
    mobileNoEnc: "",
    step: "sign",
    stepData: yearkey,
    stepMode: "step",
    showCookie: "",
    cookieData: "",
    nMemberLoginYn: "",
    bizNo: "",
    bizNoEnc: "",
    bizNm: "",
    bizNmEnc: "",
    mateKndCd: "ALL",
    attrYr: "2023",
    chkMm: "01,02,03,04,05,06,07,08,09,10,11,12",
    uOption: "0",
  };

  const res = await axios.post(
    `https://getyearcalculate-2x7jy4bauq-uc.a.run.app`,
    {
      body: userData,
    }
  );
  return res;
};
export const getIncome = async ({ user, incomekey }) => {
    let logintype = "";
    if (user.authtype == LoginType.kakao) {
      logintype = "kakao";
    } else if (user.authtype == LoginType.samsung) {
      logintype = "samsung";
    } else if (user.authtype == LoginType.pass) {
      logintype = "pass";
    } else if (user.authtype == LoginType.kb) {
      logintype = "kb";
    } else if (user.authtype == LoginType.payco) {
      logintype = "payco";
    }

    let juminnum = user.jumin.replaceAll("-", "");
    let phonenum = user.phone.replaceAll("-", "");

    let phoneorg = "";
    if (user.phoneorg == "SKT") {
      phoneorg = "S";
    } else if (user.authtype == "LGU+") {
      phoneorg = "L";
    } else if (user.authtype == "KT") {
      phoneorg = "K";
    } else {
      phoneorg = "";
    }

    let year = "";
  year = "19" + juminnum.substr(0, 6);
  
  const userData = {
    loginMethod: "EASY",
    userId: "",
    userPw: "",
    userPwEnc: "",
    signCert: "",
    signPri: "",
    signPw: "",
    signPwEnc: "",
    loginOrgCd:logintype,
    resNm: user.name,
    resNmEnc: "",
    resNo: year,
    mobileCo: phoneorg,
    mobileNo: phonenum,
    mobileNoEnc: "",
    step: "sign",
    stepData: incomekey,
    stepMode: "step",
    showCookie: "",
    cookieData: "",
    bizNo: "",
    bizNoEnc: "",
    attrYr: "2023",
  };

  const res = await axios.post(`https://getincome-2x7jy4bauq-uc.a.run.app`, {
    body: userData,
  });
  return res;
};

export const getVatTax = async ({ user, taxkey, biznum }) => {
  let logintype = "";
  if (user.authtype == LoginType.kakao) {
    logintype = "kakao";
  } else if (user.authtype == LoginType.samsung) {
    logintype = "samsung";
  } else if (user.authtype == LoginType.pass) {
    logintype = "pass";
  } else if (user.authtype == LoginType.kb) {
    logintype = "kb";
  } else if (user.authtype == LoginType.payco) {
    logintype = "payco";
  }

  let juminnum = user.jumin.replaceAll("-", "");
  let phonenum = user.phone.replaceAll("-", "");

  let phoneorg = "";
  if (user.phoneorg == "SKT") {
    phoneorg = "S";
  } else if (user.authtype == "LGU+") {
    phoneorg = "L";
  } else if (user.authtype == "KT") {
    phoneorg = "K";
  } else {
    phoneorg = "";
  }

  let year = "";
  year = "19" + juminnum.substr(0, 6);

  const userData = {
    loginMethod: "EASY",
    userId: "",
    userPw: "",
    userPwEnc: "",
    signCert: "",
    signPri: "",
    signPw: "",
    signPwEnc: "",
    loginOrgCd: logintype,
    resNm: user.name,
    resNmEnc: "",
    resNo: year,
    mobileCo: phoneorg,
    mobileNo: phonenum,
    mobileNoEnc: "",
    step: "sign",
    stepData: taxkey,
    stepMode: "step",
    showCookie: "",
    cookieData: "",
    bizNo: biznum,
    itrfCd: "41",
    yyyy: "2023",
    rtnDtSrt: "20230701",
    ntplInfpYn: "N",
    pdfYn: "N",
    detailYn: "N",
  };

  const res = await axios.post(`https://getmyntstax-2x7jy4bauq-uc.a.run.app`, {
    body: userData,
  });
  return res;
};
export const getVatTax2 = async ({ user, taxkey, biznum }) => {
  let logintype = "";
  if (user.authtype == LoginType.kakao) {
    logintype = "kakao";
  } else if (user.authtype == LoginType.samsung) {
    logintype = "samsung";
  } else if (user.authtype == LoginType.pass) {
    logintype = "pass";
  } else if (user.authtype == LoginType.kb) {
    logintype = "kb";
  } else if (user.authtype == LoginType.payco) {
    logintype = "payco";
  }

  let juminnum = user.jumin.replaceAll("-", "");
  let phonenum = user.phone.replaceAll("-", "");

  let phoneorg = "";
  if (user.phoneorg == "SKT") {
    phoneorg = "S";
  } else if (user.authtype == "LGU+") {
    phoneorg = "L";
  } else if (user.authtype == "KT") {
    phoneorg = "K";
  } else {
    phoneorg = "";
  }

  let year = "";
  year = "19" + juminnum.substr(0, 6);

  const userData = {
    loginMethod: "EASY",
    userId: "",
    userPw: "",
    userPwEnc: "",
    signCert: "",
    signPri: "",
    signPw: "",
    signPwEnc: "",
    loginOrgCd: logintype,
    resNm: user.name,
    resNmEnc: "",
    resNo: year,
    mobileCo: phoneorg,
    mobileNo: phonenum,
    mobileNoEnc: "",
    step: "sign",
    stepData: taxkey,
    stepMode: "step",
    showCookie: "",
    cookieData: "",
    bizNo: biznum,
    itrfCd: "41",
    yyyy: "2024",
    rtnDtSrt: "20240101",
    ntplInfpYn: "N",
    pdfYn: "N",
    detailYn: "N",
  };

  const res = await axios.post(`https://getmyntstax-2x7jy4bauq-uc.a.run.app`, {
    body: userData,
  });
  return res;
};

export const getWithholdingTax = async ({ user, taxkey, biznum }) => {
  let logintype = "";
  if (user.authtype == LoginType.kakao) {
    logintype = "kakao";
  } else if (user.authtype == LoginType.samsung) {
    logintype = "samsung";
  } else if (user.authtype == LoginType.pass) {
    logintype = "pass";
  } else if (user.authtype == LoginType.kb) {
    logintype = "kb";
  } else if (user.authtype == LoginType.payco) {
    logintype = "payco";
  }

  let juminnum = user.jumin.replaceAll("-", "");
  let phonenum = user.phone.replaceAll("-", "");

  let phoneorg = "";
  if (user.phoneorg == "SKT") {
    phoneorg = "S";
  } else if (user.authtype == "LGU+") {
    phoneorg = "L";
  } else if (user.authtype == "KT") {
    phoneorg = "K";
  } else {
    phoneorg = "";
  }

  let year = "";
  year = "19" + juminnum.substr(0, 6);

  const userData = {
    loginMethod: "EASY",
    userId: "",
    userPw: "",
    userPwEnc: "",
    signCert: "",
    signPri: "",
    signPw: "",
    signPwEnc: "",
    loginOrgCd: logintype,
    resNm: user.name,
    resNmEnc: "",
    resNo: year,
    mobileCo: phoneorg,
    mobileNo: phonenum,
    mobileNoEnc: "",
    step: "sign",
    stepData: taxkey,
    stepMode: "step",
    showCookie: "",
    cookieData: "",
    bizNo: biznum,
    itrfCd: "14",
    yyyy: "2023",
    rtnDtSrt: "20230201",
    ntplInfpYn: "N",
    pdfYn: "N",
    detailYn: "N",
  };

  const res = await axios.post(`https://getmyntstax-2x7jy4bauq-uc.a.run.app`, {
    body: userData,
  });
  return res;
};

export const getWeTax = async ({ user, wetaxkey }) => {
    let logintype = "";
    if (user.authtype == LoginType.kakao) {
      logintype = "kakao";
    } else if (user.authtype == LoginType.samsung) {
      logintype = "samsung";
    } else if (user.authtype == LoginType.pass) {
      logintype = "pass";
    } else if (user.authtype == LoginType.kb) {
      logintype = "kb";
    } else if (user.authtype == LoginType.payco) {
      logintype = "payco";
    }

    let juminnum = user.jumin.replaceAll("-", "");
    let phonenum = user.phone.replaceAll("-", "");

    let phoneorg = "";
    if (user.phoneorg == "SKT") {
      phoneorg = "S";
    } else if (user.authtype == "LGU+") {
      phoneorg = "L";
    } else if (user.authtype == "KT") {
      phoneorg = "K";
    } else {
      phoneorg = "";
    }
  let year = "";
  year = "19" + juminnum.substr(0, 6);
  const userData = {
    nonMemberYn: "",
    loginMethod: "EASY",
    signCert: "",
    signPri: "",
    signPw: "",
    loginOrgCd: logintype,
    resNm: user.name,
    resNo: juminnum,
    mobileNo: phonenum,
    mobileCo: phoneorg,
    stepMode: "step",
    step: "sign",
    step_data: wetaxkey,
    showCookie: "",
    cookieData: "",
    fromDate: "20230101",
    toDate: "20231231",
    detail: "",
    juso_code: "",
    taxItem: "",
  };

  const res = await axios.post(`https://getlocaltax-2x7jy4bauq-uc.a.run.app`, {
    body: userData,
  });
  return res;
};
// export const getVat = async ({ user, vattaxkey, biznum }) => {
//     let logintype = "";
//     if (user.authtype == LoginType.kakao) {
//       logintype = "kakao";
//     } else if (user.authtype == LoginType.samsung) {
//       logintype = "samsung";
//     } else if (user.authtype == LoginType.pass) {
//       logintype = "pass";
//     } else if (user.authtype == LoginType.kb) {
//       logintype = "kb";
//     } else if (user.authtype == LoginType.payco) {
//       logintype = "payco";
//     }

//   let juminnum = String(user.jumin);
//   let phonenum = user.phone;

//     let phoneorg = "";
//     if (user.phoneorg == "SKT") {
//       phoneorg = "S";
//     } else if (user.authtype == "LGU+") {
//       phoneorg = "L";
//     } else if (user.authtype == "KT") {
//       phoneorg = "K";
//     } else {
//       phoneorg = "";
//     }
//   let year = "";
//   year = "19" + juminnum.substr(0, 6);
//   const userData = {
//     loginMethod: "EASY",
//     signPwEnc: "",
//     resNmEnc: "",
//     mobileNoEnc: "",
//     bizNoEnc: "",
//     bizNmEnc: "",
//     telNoEnc: "",
//     hpNoEnc: "",
//     loginOrgCd: logintype,
//     resNm: user.name,
//     resNo: year,
//     mobileCo: phoneorg,
//     mobileNo: phonenum,
//     stepMode: "step",
//     step: "sign",
//     stepData: vattaxkey,
//     showCookie: "",
//     cookieData: "",
//     userId: "",
//     userPw: "",
//     signCert: "",
//     signPri: "",
//     signPw: "",
//     agentId: "",
//     agentPw: "",
//     nMemberLoginYn: "",
//     bizNo: biznum,
//     bizNm: "",
//     telNo: phonenum,
//     hpNo: "",
//     email: "",
//     englCvaAplnYn: "",
//     cvaDcumUseUsgCd: "",
//     cvaDcumSbmsOrgnClCd: "",
//     resnoOpYn: "",
//     cvaDcumGranMthdCd: "",
//     cerplsnRqsQty: "",
//     txnrmStrtYm: "20230101",
//     txnrmEndYm: "20231231",
//     pdfYn: "",
//   };

//     const res = await axios.post(`https://getvattax-2x7jy4bauq-uc.a.run.app`, {
//       body: userData,
//     });
//     return res;
// }

