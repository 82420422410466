import React, {
  useState,
  useEffect,
  useContext,
  useLayoutEffect,
  useRef,
} from "react";
import {
  HashRouter,
  Route,
  Redirect,
  BrowserRouter,
  Routes,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Swipe from "../common/Swipe";

import styled from "styled-components";

import Guide from "../components/Guide";
import Swipe2 from "../common/Swipe2";
import Button from "../common/Button";
import { colors, theme } from "../theme/theme";
import InputItem from "../components/InputItem";
import InputReturn from "../components/InputReturn";
import Next from "../components/Next";
import { Mobile, PC } from "../components/Responsive";
import useDimension from "../common/UseDimension";
import SelectItem from "../components/SelectItem";
import { UserContext } from "../context/User";
import { get_userInfoForUID } from "../service/UserService";
import { BiCalendarEdit } from "react-icons/bi";
import { FcInfo } from "react-icons/fc";
import Info from "../common/Info";
import { JuminCheck } from "../utility/common";
import ErrorModalEx from "../components/ErrorModalEx";

const Container = styled.div`
  padding: 80px 10px;
  background: white;

`;
const PCContainer = styled.div`
  width: 27%;
  margin: 0px 37%;
  background: white;
  padding-top: 80px;
  height: 100%;
  height: ${({ height }) => height - 80}px;
  border: 1px solid;
`;

const LabelItem = styled.div`
  line-height: 1.5;
  font-size: 1.2rem;
  text-decoration-line: none;
  color: #6b6b6b;
  padding-left: 10px;
  font-family:Pretendard-Bold;
`;


 

const phoneorg = ["SKT", "KT", "LGU+", "기타"];

const BasicInfocontainer = () => {

   const PhoneSelect = (data) => {
    setNumberorg(data);
    setRefresh((refresh) => refresh + 1);
   }

  const navigate = useNavigate();
  
  const {user, dispatch2 } = useContext(UserContext);
  const [height, setHeight] = useState(window.innerHeight);


  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [numberorg, setNumberorg] = useState("SKT");
  const [jumin, setJumin] = useState("");
  
  const [namefocus, setNamefocus] = useState(true);
  const [numberfocus, setNumberfocus] = useState(false);
  const [juminfocus, setJuminfocus] = useState(false);
  
  
  const nameRef = useRef();
  const numberRef = useRef();
  const juminRef = useRef();
  
  
  const [enable, setEnable] = useState(false);
  const [refresh, setRefresh] = useState(1);
  const [enablerefresh, setEnablerefresh] = useState(1);
  
  const [error, setError] = useState(false);
  const [errormsg, setErrormsg] = useState("");
  
  const _handlenext = async () => {
    
    // if (!JuminCheck(jumin)) {
    //   setError(true);
    //   setErrormsg("잘못된 주민등록번호입니다. 올바른 주민등록번호를 입력해주세요");
    //   setRefresh((refresh) => refresh + 1);
    //   return;
    // }

    if (number.length != 10 && number.length != 11) {

      console.log("number", number, number.length);
      setError(true);
      setErrormsg(
        "잘못된 핸드폰번호입니다. 올바른 핸드폰번호를 입력해주세요"
      );
      setRefresh((refresh) => refresh + 1);
      return;
    }


    user["name"] = name;
    user["phone"] = number;
    user["phoneorg"] = numberorg;
    // user["jumin"] = jumin;
    
  



    const username = name;
    const phone = number;
    const userinfo = await get_userInfoForUID({ username, phone });

    console.log("user", user);

    if (userinfo != null) {

    user["jumin"] = userinfo.jumin;

    dispatch2(user);
    
      navigate("/loading");
    } else {

      navigate("/jumininfo");
    }


  }


  

  useEffect(() => {
    setName(name);
    setNumberorg(numberorg);
    // setJumin(jumin);
    setNumber(number);

    if (name != '' &&
      number != '' ) {
      setEnable(true);
      console.log("enable...... true");
      setEnablerefresh(enablerefresh => enablerefresh + 1);
    } else {
      setEnable(false);
      setEnablerefresh((enablerefresh) => enablerefresh + 1);
    }

  }, [refresh])
  
  useEffect(() => {
    setEnable(enable);
  }, [enablerefresh]);


 

 
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  const errormodalcallback = () => {
    setError(false);
    setRefresh((refresh) => refresh + 1);
  };
  
  return (
    <>
      {error == true && (
        <ErrorModalEx callback={errormodalcallback} msg={errormsg} />
      )}
      <Mobile>
        <Container height={height}>
          <Guide
            main1={"세금 신고에 필요한 개인정보 입력 받습니다"}
            main2={""}
            sub={""}
          ></Guide>

          <Info
            content={
              "입력하신 성함과 핸드폰번호는 세금 신고시 필요한 자료 수집에만 사용되요"
            }
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              marginLeft: "5%",
            }}
          >
            <div
              style={{
                marginTop: 50,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BiCalendarEdit size={20} />
              <LabelItem>{"이름(필수입력)"}</LabelItem>
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 10 }}
            >
              <div>
                <input
                  ref={nameRef}
                  type="text"
                  value={name}
                  style={{ width: "260px", marginLeft: 10 }}
                  onChange={(e) => {
                    setName(e.target.value);
                    setRefresh((refresh) => refresh + 1);
                  }}
                  onFocus={() => setNamefocus(true)}
                  onBlur={() => setNamefocus(false)}
                  className="inputcommon"
                  placeholder={"이름을 입력해주세요"}
                  returnKeyType={"다음"}
                  autoCapitalize="none"
                  autoCorrect={false}
                ></input>

                {namefocus == true ? (
                  <div className="inputFocus"></div>
                ) : (
                  <div className="inputNoFocus"></div>
                )}
              </div>
            </div>
            <div style={{ fontSize: 14, display: "flex", marginTop: 5 }}>
              <div style={{ color: "#ff0000" }}>TIP</div>
              <div style={{ paddingLeft: 5, color: "#959595" }}>
                실명이 입력되어 있는지 확인해주세요
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              marginLeft: "5%",
            }}
          >
            <div
              style={{
                marginTop: 50,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BiCalendarEdit size={20} />
              <LabelItem>{"휴대폰번호(숫자만 필수입력)"}</LabelItem>
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 10 }}
            >
              <select
                style={{
                  padding: 10,
                  marginLeft: 10,
                  width: "80px",
                  border: "none",
                  fontSize: 16,
                  outline: 0,
                  color: "#7b7979",
                  background: "white",
                }}
                onChange={(e) => {
                  PhoneSelect(e.target.value);
                }}
              >
                {phoneorg.map((option) => (
                  <option
                    key={option}
                    value={option}
                    style={{ color: "#7b7979" }}
                  >
                    {option}
                  </option>
                ))}
              </select>

              <div>
                <input
                  ref={numberRef}
                  type="number"
                  value={number}
                  style={{ width: "170px", marginLeft: 10 }}
                  onChange={(e) => {
                    setNumber(e.target.value);
                    setRefresh((refresh) => refresh + 1);
                  }}
                  className="inputcommon"
                  placeholder={"휴대폰을 입력해주세요"}
                  onFocus={() => {
                    setNamefocus(false);
                    setNumberfocus(true);
                  }}
                  onBlur={() => setNumberfocus(false)}
                ></input>
                {numberfocus == true ? (
                  <div className="inputFocus"></div>
                ) : (
                  <div className="inputNoFocus"></div>
                )}
              </div>
            </div>
          </div>
          {/* <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              marginLeft: "5%",
            }}
          >
            <div
              style={{
                marginTop: 50,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BiCalendarEdit size={20} />
              <LabelItem>{"주민등록번호(숫자만 필수입력)"}</LabelItem>
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 10 }}
            >
              <div>
                <input
                  ref={juminRef}
                  type="number"
                  value={jumin}
                  style={{ width: "260px", marginLeft: 10 }}
                  onChange={(e) => {
                    setJumin(e.target.value);
                    setRefresh((refresh) => refresh + 1);
                  }}
                  className="inputcommon"
                  placeholder={"주민등록번호를 입력해주세요"}
                  onFocus={() => {
                    setNamefocus(false);
                    setNumberfocus(false);
                    setJuminfocus(true);
                  }}
                  onBlur={() => setJuminfocus(false)}
                ></input>
                {juminfocus == true ? (
                  <div className="inputFocus"></div>
                ) : (
                  <div className="inputNoFocus"></div>
                )}
              </div>
            </div>
          </div> */}
          <Next
            callback={_handlenext}
            enable={enable}
            containerStyle={{ width: "100%", marginTop: 30 }}
          />
        </Container>
      </Mobile>
      <PC>
        <PCContainer height={height}>
          <Guide
            main1={"세금 신고에 필요한 개인정보 입력 받습니다"}
            main2={""}
            sub={""}
          ></Guide>

          <Info
            content={
              "입력하신 이름과 핸드폰 번호는 세금 신고시 필요한 자료 수집에만 사용되요"
            }
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              marginLeft: "5%",
            }}
          >
            <div
              style={{
                marginTop: 50,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BiCalendarEdit size={20} />
              <LabelItem>{"이름(필수입력)"}</LabelItem>
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 10 }}
            >
              <div>
                <input
                  ref={nameRef}
                  type="text"
                  value={name}
                  style={{ width: "350px", marginLeft: 10 }}
                  onChange={(e) => {
                    setName(e.target.value);
                    setRefresh((refresh) => refresh + 1);
                  }}
                  onFocus={() => setNamefocus(true)}
                  onBlur={() => setNamefocus(false)}
                  className="inputcommon"
                  placeholder={"이름을 입력해주세요"}
                  returnKeyType={"다음"}
                  autoCapitalize="none"
                  autoCorrect={false}
                ></input>

                {namefocus == true ? (
                  <div className="inputFocus"></div>
                ) : (
                  <div className="inputNoFocus"></div>
                )}
              </div>
            </div>
            <div style={{ fontSize: 14, display: "flex", marginTop: 5 }}>
              <div style={{ color: "#ff0000" }}>TIP</div>
              <div style={{ paddingLeft: 5, color: "#959595" }}>
                실명이 입력되어 있는지 확인해주세요
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              marginLeft: "5%",
            }}
          >
            <div
              style={{
                marginTop: 50,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BiCalendarEdit size={20} />
              <LabelItem>{"휴대폰번호(필수입력)"}</LabelItem>
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 10 }}
            >
              <select
                style={{
                  padding: 10,
                  marginLeft: 10,
                  width: "80px",
                  border: "none",
                  fontSize: 16,
                  outline: 0,
                  color: "#7b7979",
                  background: "white",
                }}
                onChange={(e) => {
                  PhoneSelect(e.target.value);
                }}
              >
                {phoneorg.map((option) => (
                  <option
                    key={option}
                    value={option}
                    style={{ color: "#7b7979" }}
                  >
                    {option}
                  </option>
                ))}
              </select>

              <div>
                <input
                  ref={numberRef}
                  type="number"
                  value={number}
                  style={{ width: "250px", marginLeft: 10 }}
                  onChange={(e) => {
                    setNumber(e.target.value);
                    setRefresh((refresh) => refresh + 1);
                  }}
                  className="inputcommon"
                  placeholder={"휴대폰을 입력해주세요"}
                  onFocus={() => {
                    setNamefocus(false);
                    setNumberfocus(true);
                  }}
                  onBlur={() => setNumberfocus(false)}
                ></input>
                {numberfocus == true ? (
                  <div className="inputFocus"></div>
                ) : (
                  <div className="inputNoFocus"></div>
                )}
              </div>
            </div>
          </div>
          {/* <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              marginLeft: "5%",
            }}
          >
            <div
              style={{
                marginTop: 50,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BiCalendarEdit size={20} />
              <LabelItem>{"주민등록번호(필수입력)"}</LabelItem>
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 10 }}
            >
              <div>
                <input
                  ref={juminRef}
                  type="text"
                  value={jumin}
                  style={{ width: "350px", marginLeft: 10 }}
                  onChange={(e) => {
                    setJumin(e.target.value);
                    setRefresh((refresh) => refresh + 1);
                  }}
                  className="inputcommon"
                  placeholder={"주민등록번호를 입력해주세요"}
                  onFocus={() => {
                    setNamefocus(false);
                    setNumberfocus(false);
                    setJuminfocus(true);
                  }}
                  onBlur={() => setJuminfocus(false)}
                ></input>
                {juminfocus == true ? (
                  <div className="inputFocus"></div>
                ) : (
                  <div className="inputNoFocus"></div>
                )}
              </div>
            </div>
          </div> */}
          <Next
            callback={_handlenext}
            enable={enable}
            containerStyle={{ width: "100%", marginTop: 30 }}
          />
        </PCContainer>
      </PC>
    </>
  );
};

export default BasicInfocontainer;
