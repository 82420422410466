import React, { useContext, useEffect, useState } from "react";

import Layout from "../screen/Layout/HomeLayout";
import HomeLayout from "../screen/Layout/HomeLayout";
import { UserContext } from "../context/User";
import { TYPE } from "../utility/maroneDefine";
import { useLocation } from "react-router-dom";
import {
  get_userInfoForDevice,
  login,
  update_userdevice,
} from "../service/UserService";

import Easyauthcontainer from "../container/Hometaxgetcontainer";
import PrevLayout from "../screen/Layout/PrevLayout";
import Authrequestcontainer from "../container/Hometaxgetcontainer";
import Hometaxgetcontainer from "../container/Hometaxgetcontainer";
import DisablePrevLayout from "../screen/Layout/DisablePrevLayout";

const Hometaxgetpage = () => {
  const { user, dispatch2 } = useContext(UserContext);

  const { state } = useLocation(); // 2번 라인
  const { LOGINTYPE } = state; // 3번 라인

  return (
    <DisablePrevLayout menu={true} bottom={false} header={true} headerdetail={false}>
      <Hometaxgetcontainer logintype={LOGINTYPE} />
    </DisablePrevLayout>
  );
};

export default Hometaxgetpage;
