import React, {
  useState,
  useEffect,
  useContext,
  useLayoutEffect,
  useRef,
} from "react";
import {
  HashRouter,
  Route,
  Redirect,
  BrowserRouter,
  Routes,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Swipe from "../common/Swipe";

import styled from "styled-components";

import Guide from "../components/Guide";
import Swipe2 from "../common/Swipe2";
import Button from "../common/Button";
import { colors, theme } from "../theme/theme";
import InputItem from "../components/InputItem";
import InputReturn from "../components/InputReturn";
import Next from "../components/Next";
import { Mobile, PC } from "../components/Responsive";
import useDimension from "../common/UseDimension";
import SelectItem from "../components/SelectItem";
import { UserContext } from "../context/User";
import { get_userInfoForUID } from "../service/UserService";
import { BiCalendarEdit } from "react-icons/bi";
import { FcInfo } from "react-icons/fc";
import Info from "../common/Info";

const Container = styled.div`
  padding: 80px 10px;
  background: white;

`;
 const PCContainer = styled.div`
 width: 27%;
 margin: 0px 37%;
   background: white;
   padding-top: 80px;
   height: 100%;
   height: ${({ height }) => height - 80}px;
   border: 1px solid;
 `;
const LabelItem = styled.div`
  line-height: 1.5;
  font-size: 1.2rem;
  text-decoration-line: none;
  color: #6b6b6b;
  padding-left: 10px;
  font-family:Pretendard-Bold;
`;


 

const phoneorg = ["SKT", "KT", "LGU+", "기타"];

const WetaxInfocontainer = () => {


  const navigate = useNavigate();
  
  const {user, dispatch2 } = useContext(UserContext);
  const [height, setHeight] = useState(window.innerHeight);


  const [wetaxid, setWetaxid] = useState("");
  const [wetaxpw, setWetaxpw] = useState("");
  
  const [wetaxidfocus, setWetaxidfocus] = useState(false);
  const [wetaxpwfocus, setWetaxpwfocus] = useState(false);
  

  
  
  const [enable, setEnable] = useState(false);
  const [refresh, setRefresh] = useState(1);
  const [enablerefresh, setEnablerefresh] = useState(1);
  

  
  const _handlenext = async() => {

    user["wetaxid"] = wetaxid;
    user["wetaxpw"] = wetaxpw;
    dispatch2(user);
    navigate("/bizinfo");

  }

  const _handleskip = async () => {
    navigate("/bizinfo");
  };


  

  useEffect(() => {
    setWetaxid(wetaxid);
    setWetaxpw(wetaxpw);

    if (wetaxid != "" && wetaxpw != "") {
      setEnable(true);
      console.log("enable...... true");
      setEnablerefresh((enablerefresh) => enablerefresh + 1);
    } else {
      setEnable(false);
      setEnablerefresh((enablerefresh) => enablerefresh + 1);
    }

  }, [refresh])
  
  useEffect(() => {
    setEnable(enable);
  }, [enablerefresh]);


 

 
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);


  
  return (
    <>
      <Mobile>
        <Container height={height}>
          <Guide
            main1={"위택스에 가입하신 위택스정보를 입력해주세요"}
            main2={""}
            sub={""}
          ></Guide>

          <Info
            content={"위택스 정보는 필수사항이 아닙니다 없으면 건너띄기 하세요"}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              marginLeft: "5%",
            }}
          >
            <div
              style={{
                marginTop: 50,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BiCalendarEdit size={20} />
              <LabelItem>{"위택스아이디(선택입력)"}</LabelItem>
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 10 }}
            >
              <div>
                <input
                  type="text"
                  value={wetaxid}
                  style={{ width: "260px", marginLeft: 10 }}
                  onChange={(e) => {
                    setWetaxid(e.target.value);
                    setRefresh((refresh) => refresh + 1);
                  }}
                  className="inputcommon"
                  placeholder={"위텍스아이디를 입력해주세요"}
                  onFocus={() => {
                    setWetaxidfocus(true);
                    setWetaxpwfocus(false);
                  }}
                  onBlur={() => setWetaxidfocus(false)}
                ></input>
                {wetaxidfocus == true ? (
                  <div className="inputFocus"></div>
                ) : (
                  <div className="inputNoFocus"></div>
                )}
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              marginLeft: "5%",
            }}
          >
            <div
              style={{
                marginTop: 50,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BiCalendarEdit size={20} />
              <LabelItem>{"위택스비밀번호(선택입력)"}</LabelItem>
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 10 }}
            >
              <div>
                <input
                  type="text"
                  value={wetaxpw}
                  style={{ width: "260px", marginLeft: 10 }}
                  onChange={(e) => {
                    setWetaxpw(e.target.value);
                    setRefresh((refresh) => refresh + 1);
                  }}
                  className="inputcommon"
                  placeholder={"위택스 비밀번호를 입력해주세요"}
                  onFocus={() => {
                    setWetaxpwfocus(true);
                    setWetaxidfocus(false);
                  }}
                  onBlur={() => setWetaxpwfocus(false)}
                ></input>
                {wetaxpwfocus == true ? (
                  <div className="inputFocus"></div>
                ) : (
                  <div className="inputNoFocus"></div>
                )}
              </div>
            </div>
          </div>

          <Next
            callback={_handleskip}
            enable={!enable}
            label={"건너뛰기"}
            containerStyle={{ width: "100%", marginTop: 30 }}
          />

          <Next
            callback={_handlenext}
            enable={enable}
            containerStyle={{ width: "100%" }}
          />
        </Container>
      </Mobile>
      <PC>
        <PCContainer height={height}>
          <Guide
            main1={"위택스에 가입하신 위택스정보를 입력해주세요"}
            main2={""}
            sub={""}
          ></Guide>

          <Info
            content={"위택스 정보는 필수사항이 아닙니다 없으면 건너띄기 하세요"}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              marginLeft: "5%",
            }}
          >
            <div
              style={{
                marginTop: 50,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BiCalendarEdit size={20} />
              <LabelItem>{"위택스아이디(선택입력)"}</LabelItem>
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 10 }}
            >
              <div>
                <input
                  type="text"
                  value={wetaxid}
                  style={{ width: "260px", marginLeft: 10 }}
                  onChange={(e) => {
                    setWetaxid(e.target.value);
                    setRefresh((refresh) => refresh + 1);
                  }}
                  className="inputcommon"
                  placeholder={"위텍스아이디를 입력해주세요"}
                  onFocus={() => {
                    setWetaxidfocus(true);
                    setWetaxpwfocus(false);
                  }}
                  onBlur={() => setWetaxidfocus(false)}
                ></input>
                {wetaxidfocus == true ? (
                  <div className="inputFocus"></div>
                ) : (
                  <div className="inputNoFocus"></div>
                )}
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              marginLeft: "5%",
            }}
          >
            <div
              style={{
                marginTop: 50,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BiCalendarEdit size={20} />
              <LabelItem>{"위택스비밀번호(선택입력)"}</LabelItem>
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 10 }}
            >
              <div>
                <input
                  type="text"
                  value={wetaxpw}
                  style={{ width: "260px", marginLeft: 10 }}
                  onChange={(e) => {
                    setWetaxpw(e.target.value);
                    setRefresh((refresh) => refresh + 1);
                  }}
                  className="inputcommon"
                  placeholder={"위택스 비밀번호를 입력해주세요"}
                  onFocus={() => {
                    setWetaxpwfocus(true);
                    setWetaxidfocus(false);
                  }}
                  onBlur={() => setWetaxpwfocus(false)}
                ></input>
                {wetaxpwfocus == true ? (
                  <div className="inputFocus"></div>
                ) : (
                  <div className="inputNoFocus"></div>
                )}
              </div>
            </div>
          </div>

          <Next
            callback={_handleskip}
            enable={!enable}
            label={"건너뛰기"}
            containerStyle={{ width: "100%", marginTop: 120 }}
          />

          <Next
            callback={_handlenext}
            enable={enable}
            containerStyle={{ width: "100%" }}
          />
        </PCContainer>
      </PC>
    </>
  );
};

export default WetaxInfocontainer;
