import React,{useState, useEffect} from 'react';
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, Link, useNavigate} from "react-router-dom";
import styled from 'styled-components';
import Button from '../common/Button';
import { colors } from '../theme/theme';

const Container = styled.div`


`


const Next = ({containerStyle, callback, enable, label}) => {

  const navigate = useNavigate();
   useEffect(()=>{
    async function fetchData(){
		}
		fetchData();
  }, [])


  const handlenext = () => {
    callback();
  };
  const handleno = () => {

  };
  

  return (
    <Container style={containerStyle}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        {enable == true ? (
          <Button
            buttonText={label}
            callback={handlenext}
            containerStyle={{
              backgroundColor: colors.mainbuttoncolor,
              color: "#fff",
              margin: "10px",
              width: "100%",
              height: 50,
              fontSize: 19,
              fontWeight: 700,
              borderRadius: "10px",
              fontFamily:'Pretendard-Bold'
            }}
          />
        ) : (
          <Button
            buttonText={label}
            callback={handleno}
            containerStyle={{
              backgroundColor: "#ededed",
              color: "#999",
              margin: "10px",
              width: "100%",
              height: 50,
              fontSize: 19,
              fontWeight: 700,
              borderRadius: "10px",
              fontFamily:'Pretendard-Bold'
            }}
          />
        )}
      </div>
    </Container>
  );
}


Next.defaultProps = {
  label: "다음",
};



export default Next;
