import React, {
  useState,
  useEffect,
  useContext,
  useLayoutEffect,
  useRef,
} from "react";
import {
  HashRouter,
  Route,
  Redirect,
  BrowserRouter,
  Routes,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Swipe from "../common/Swipe";

import styled from "styled-components";

import Guide from "../components/Guide";
import Swipe2 from "../common/Swipe2";
import Button from "../common/Button";
import { colors, theme } from "../theme/theme";
import InputItem from "../components/InputItem";
import InputReturn from "../components/InputReturn";
import Next from "../components/Next";
import { Mobile, PC } from "../components/Responsive";
import useDimension from "../common/UseDimension";
import SelectItem from "../components/SelectItem";
import { UserContext } from "../context/User";
import { get_userInfoForUID } from "../service/UserService";
import { BiCalendarEdit } from "react-icons/bi";
import { FcInfo } from "react-icons/fc";

const Container = styled.div`
  padding: 80px 20px;
  background: white;

`;
const PCContainer = styled.div`
width: 27%;
margin: 0px 37%;
  background: white;
  padding-top: 80px;
  height: ${({height}) =>height -80}px;

`;
const LabelItem = styled.div`
  font-weight: 400;
  line-height: 1.5;
  font-size: 1.2rem;
  text-decoration-line: none;
  color: #000;
  padding-left: 10px;
`;


 
const phoneorg = ["SKT", "KT", "LGU+", "기타"];

const PrivacyInfocontainer = () => {

   const PhoneSelect = (data) => {
    setNumberorg(data);
    setRefresh((refresh) => refresh + 1);
   }

  const navigate = useNavigate();
  
  const { user, dispatch2 } = useContext(UserContext);

  const [height, setHeight] = useState(window.innerHeight);

  const [postarea, setPostarea] = useState([]);

  // const [name, setName] = useState('이행렬');
  // const [number, setNumber] = useState('01062149756');
  // const [numberorg, setNumberorg] = useState("SKT");
  // const [jumin, setJumin] = useState("7611161654916");
  // const [hometaxid, setHometaxid] = useState('kkan222');
  // const [hometaxpw, setHometaxpw] = useState("1q2w3e4r");
  // const [wetaxid, setWetaxid] = useState("wetax");
  // const [wetaxpw, setWetaxpw] = useState("1234232");
  // const [bank, setBank] = useState("국민 21780204015837");

  const [name, setName] = useState("");
  
  const [namefocus, setNamefocus] = useState(true);


  const [numberfocus, setNumberfocus] = useState(false);
  const [juminfocus, setJuminfocus] = useState(false);
  

    const [number, setNumber] = useState("");
    const [numberorg, setNumberorg] = useState("SKT");
    const [jumin, setJumin] = useState("");
    const [hometaxid, setHometaxid] = useState("");
    const [hometaxpw, setHometaxpw] = useState("");
    const [wetaxid, setWetaxid] = useState("");
    const [wetaxpw, setWetaxpw] = useState("");
    const [bank, setBank] = useState("");
  
  const nameRef = useRef();
  const numberRef = useRef();
  const juminRef = useRef();
  
  
  const [enable, setEnable] = useState(false);
  const [refresh, setRefresh] = useState(1);
  
  const _handleautonext = () => {
      // if (!enable) {
      //   return;
      // }
      // _handlenext();
    };

  
  const _handlenext = async() => {

    user["name"] = name;
    user["phone"] = number;
    user["phoneorg"] = numberorg;
    user["jumin"] = jumin;
    user["hometaxid"] = hometaxid;
    user["hometaxpw"] = hometaxpw;
    user["wetaxid"] = wetaxid;
    user["wetaxpw"] = wetaxpw;
    user["bank"] = bank;
    user["biznum"] = [];
    
    dispatch2(user);

    console.log("user", user);

    const username = name;
    const phone = number;
    const userinfo = await get_userInfoForUID({ username, phone });

    if (userinfo != null) {
      alert("이미 등록된 고객이십니다");
      navigate("/");
    } else {
      navigate("/authselect");
    }


  }

  const namecallback = (callbackdata) => {
    // setName(callbackdata);
    // console.log("username", callbackdata);

    // if (
    //   name != "" &&
    //   number != "" &&
    //   jumin != "" 
    // ) {
    //   setEnable(true);
    // }
    // numberRef.current.focus();
    // setRefresh((refresh) => refresh + 1);
  }
  const hometaxidcallback = (callbackdata) => {
      setHometaxid(callbackdata);

      if (
        name != "" &&
        number != "" &&
        jumin != ""
      ) {
        setEnable(true);
      }
    setRefresh((refresh) => refresh + 1);
  }; 
  const hometaxpwcallback = (callbackdata) => {
    setHometaxpw(callbackdata);

    if (
      name != "" &&
      number != "" &&
      jumin != ""
    ) {
      setEnable(true);

    }
    setRefresh((refresh) => refresh + 1);
  };

  const wetaxidcallback = (callbackdata) => {
    setWetaxid(callbackdata);
    setRefresh((refresh) => refresh + 1);
  }; 

  const wetaxpwcallback = (callbackdata) => {
    setWetaxpw(callbackdata);
    setRefresh((refresh) => refresh + 1);
  }; 

  const jumincallback = (callbackdata) => {
    setJumin(callbackdata);
    console.log("jumin number", callbackdata);
    if (
      name != "" &&
      number != "" &&
      jumin != ""
    ) {
      setEnable(true);
    }
    
      setRefresh((refresh) => refresh + 1);
  };

  const bankcallback = (callbackdata) => {
    setBank(callbackdata);
        if (
          name != "" &&
          number != "" &&
          jumin != "" 
        ) {
          setEnable(true);
        }
    setRefresh((refresh) => refresh + 1);
  };
  

  const [border, setBorder] = useState(false);
  const handleFocusBorder = () => {
    setBorder(true);
  };

  const handleNameFocusBorder = () => {
    setBorder(true);
    setNamefocus(true);
  };
  

  const handleIputValidate = () => {
    setBorder(true);
  };
 


  useEffect(() => {
 
    setNamefocus(namefocus);

    
  }, [refresh])
 
  useEffect(() => {
    window.scrollTo(0, 0);

    // nameRef.current.focus();

    return () => {};
  }, []);

    const activeEnter = (e) => {
      if (e.key === "Enter") {


        alert("test");
      }
    };
  
  return (
    <>
      <Mobile>
        <Container height={height}>
          <Guide
            main1={"세금 신고에 필요한 개인정보 입력 받습니다"}
            main2={""}
            sub={""}
          ></Guide>

          <div
            style={{
              background: "#ededed",
              height: 35,
              borderRadius: 5,
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              padding: "0px 15px",
            }}
          >
            <div style={{ display: "flex" }}>
              <FcInfo />
            </div>
            <div style={{ fontSize: 11, display: "flex" }}>
              {"입력하신 정보는 세금 신고시 필요한 자료 수집에만 사용되요"}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              marginLeft: "5%",
            }}
          >
            <div
              style={{
                marginTop: 50,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BiCalendarEdit size={20} />
              <LabelItem>{"이름(필수입력)"}</LabelItem>
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 10 }}
            >
              <div>
                <input
                  ref={nameRef}
                  type="text"
                  value={name}
                  style={{ width: "260px", marginLeft: 10 }}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  onFocus={() => setNamefocus(true)}
                  onBlur={() => setNamefocus(false)}
                  className="inputcommon"
                  placeholder={"이름을 입력해주세요"}
                  returnKeyType={"다음"}
                  autoCapitalize="none"
                  autoCorrect={false}
                ></input>

                {namefocus == true ? (
                  <div className="inputFocus"></div>
                ) : (
                  <div className="inputNoFocus"></div>
                )}
              </div>
            </div>
            <div style={{ fontSize: 14, display: "flex", marginTop: 5 }}>
              <div style={{ color: "#ff0000" }}>TIP</div>
              <div style={{ paddingLeft: 5, color: "#959595" }}>
                실명이 입력되어 있는지 확인해주세요
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              marginLeft: "5%",
            }}
          >
            <div
              style={{
                marginTop: 50,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BiCalendarEdit size={20} />
              <LabelItem>{"휴대폰번호(필수입력)"}</LabelItem>
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 10 }}
            >
              <select
                style={{
                  padding: 10,
                  marginLeft: 10,
                  width: "80px",
                  border: "none",
                  fontSize: 16,
                  outline: 0,
                  color: "#7b7979",
                  background: "white",
                }}
                onChange={(e) => {
                  PhoneSelect(e.target.value);
                }}
              >
                {phoneorg.map((option) => (
                  <option
                    key={option}
                    value={option}
                    style={{ color: "#7b7979" }}
                  >
                    {option}
                  </option>
                ))}
              </select>

              <div>
                <input
                  ref={numberRef}
                  type="number"
                  value={number}
                  style={{ width: "140px", marginLeft: 10 }}
                  onChange={(e) => {
                    setNumber(e.target.value);
                    setRefresh((refresh) => refresh + 1);
                  }}
                  className="inputcommon"
                  placeholder={"휴대폰을 입력해주세요"}
                  onFocus={() => {
                    setNamefocus(false);
                    setNumberfocus(true);
                  }}
                  onBlur={() => setNumberfocus(false)}
                ></input>
                {numberfocus == true ? (
                  <div className="inputFocus"></div>
                ) : (
                  <div className="inputNoFocus"></div>
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              marginLeft: "5%",
            }}
          >
            <div
              style={{
                marginTop: 50,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BiCalendarEdit size={20} />
              <LabelItem>{"주민등록번호(필수입력)"}</LabelItem>
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 10 }}
            >
              <div>
                <input
                  ref={juminRef}
                  type="text"
                  value={jumin}
                  style={{ width: "260px", marginLeft: 10 }}
                  onChange={(e) => {
                    setJumin(e.target.value);
                  }}
                  className="inputcommon"
                  placeholder={"주민등록번호를 입력해주세요"}
                  onFocus={() => {
                    setNamefocus(false);
                    setNumberfocus(false);
                    setJuminfocus(true);
                  }}
                  onBlur={() => setJuminfocus(false)}
                ></input>
                {juminfocus == true ? (
                  <div className="inputFocus"></div>
                ) : (
                  <div className="inputNoFocus"></div>
                )}
              </div>
            </div>
          </div>
          {/* <InputItem
            label={"홈택스 id(필수입력)"}
            type={"text"}
            placeholder={"홈택스 id를 입력해 주세요"}
            callback={hometaxidcallback}
          />

          <InputItem
            label={"홈택스 비밀번호(필수입력)"}
            type={"password"}
            placeholder={"홈택스 비밀번호를 입력해 주세요"}
            callback={hometaxpwcallback}
          />

          <InputItem
            label={"위택스 id(선택입력)"}
            type={"text"}
            placeholder={"위택스 id를 입력해 주세요"}
            callback={wetaxidcallback}
          />

          <InputItem
            label={"위택스 비밀번호(선택입력)"}
            type={"password"}
            placeholder={"위택스 비밀번호를 입력해 주세요"}
            callback={wetaxpwcallback}
          />

          <InputItem
            label={"본인명의 환급계좌정보(필수입력)"}
            type={"text"}
            placeholder={"본인명의 환급계좌정보를 입력해 주세요"}
            callback={bankcallback}
            callback2={_handleautonext}
          /> */}
          <Next
            callback={_handlenext}
            enable={enable}
            containerStyle={{ width: "100%", marginTop: 30 }}
          />
        </Container>
      </Mobile>
      <PC>
        <PCContainer height={"100%"}>
          <Guide
            main1={"세금 신고에 필요한 개인정보 입력 받습니다"}
            main2={""}
            sub={
              "청람세무회계사무소에서는 소득신고에 필요한 경우 이외에는 어떠한 이유로도 개인정보를 사용하지 않습니다"
            }
          ></Guide>

          <InputItem
            label={"이름(필수입력)"}
            placeholder={"이름을 입력해 주세요"}
            callback={namecallback}
            callback2={() => {}}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              marginLeft: "5%",
            }}
          >
            <div
              style={{
                marginTop: 30,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BiCalendarEdit />
              <LabelItem>{"휴대폰번호(필수입력)"}</LabelItem>
            </div>

            <div style={{ display: "flex", flexDirection: "row" }}>
              <select
                style={{
                  padding: 10,
                  marginLeft: 10,
                  width: "80px",
                  border: "none",
                  fontSize: 16,
                  outline: 0,
                  color: "#7b7979",
                  background: "white",
                }}
                onChange={(e) => {
                  PhoneSelect(e.target.value);
                }}
              >
                {phoneorg.map((option) => (
                  <option
                    key={option}
                    value={option}
                    style={{ color: "#7b7979" }}
                  >
                    {option}
                  </option>
                ))}
              </select>

              <div>
                <input
                  type="number"
                  value={number}
                  style={{ width: "150px", marginLeft: 10 }}
                  onChange={(e) => {
                    setNumber(e.target.value);
                    setRefresh((refresh) => refresh + 1);
                  }}
                  className="inputcommon"
                  placeholder={"휴대폰을 입력해주세요"}
                  onFocus={() => handleFocusBorder()}
                  onBlur={() => handleIputValidate()}
                ></input>
                <div className="inputNoFocus"></div>
              </div>
            </div>
          </div>

          <InputItem
            label={"주민등록번호(필수입력)"}
            type={"number"}
            placeholder={"주민등록번호를 입력해 주세요"}
            callback={jumincallback}
          />

          <InputItem
            label={"홈택스 id(필수입력)"}
            type={"text"}
            placeholder={"홈택스 id를 입력해 주세요"}
            callback={hometaxidcallback}
          />

          <InputItem
            label={"홈택스 비밀번호(필수입력)"}
            type={"password"}
            placeholder={"홈택스 비밀번호를 입력해 주세요"}
            callback={hometaxpwcallback}
          />

          <InputItem
            label={"위택스 id(선택입력)"}
            type={"text"}
            placeholder={"위택스 id를 입력해 주세요"}
            callback={wetaxidcallback}
          />

          <InputItem
            label={"위택스 비밀번호(선택입력)"}
            type={"password"}
            placeholder={"위택스 비밀번호를 입력해 주세요"}
            callback={wetaxpwcallback}
          />

          <InputItem
            label={"본인명의 환급계좌정보(필수입력)"}
            type={"text"}
            placeholder={"본인명의 환급계좌정보를 입력해 주세요"}
            callback={bankcallback}
            callback2={_handleautonext}
          />

          <Next
            callback={_handlenext}
            enable={enable}
            containerStyle={{ width: "100%", marginTop: 30 }}
          />
        </PCContainer>
      </PC>
    </>
  );
};

export default PrivacyInfocontainer;
