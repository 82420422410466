import * as React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import Typography from '@mui/material/Typography';
import { useSpring, animated } from '@react-spring/web';
import Text from '../common/Text';
import { imageDB } from '../utility/imageData';
import styled from 'styled-components';
import Label from '../common/Label';
import { colors, theme } from '../theme/theme';
import { Mobile, PC } from './Responsive';
import Button from '../common/Button';
import { UserContext } from '../context/User';


const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};

const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 340,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderTopRightRadius: "10px",
  borderTopLeftRadius: "10px",
  p: "22px",
};
const style2 = {
  position: "absolute",
  top: "20%",
  left: "50%",
  height: "670px",
  transform: "translate(-50%, -50%)",
  width: '30%',
  bgcolor: "background.paper",
  boxShadow: 24,
  borderTopRightRadius: "10px",
  borderTopLeftRadius: "10px",
  padding: "22px",
  overflow: "auto"
};

const Agreecontent = styled.div`
  display: flex;
  justify-content: flex-start;
  text-align: left;
  margin-top: 10px;
`;


export default function AcceptDocModalEx({callback}) {
  const [open, setOpen] = React.useState(true);
  const [refresh, setRefresh] = React.useState(1);



  const handleClose = () =>{
    setOpen(false);
    callback(false);
  } 


  React.useEffect(()=>{

  }, [refresh])
  
  const _handlenext = () => {
    callback(true);
  }


  return (
    <>
      <Mobile>
        <div>
          <div>
            <Modal
              aria-labelledby="spring-modal-title"
              aria-describedby="spring-modal-description"
              open={open}
              onClose={handleClose}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  TransitionComponent: Fade,
                },
              }}
            >
              <Fade in={open}>
                <Box sx={style}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      marginTop: 50,
                    }}
                  >
                    <div style={{ fontSize: 18, fontWeight: 600 }}>
                      개인(세무ᆞ회계)정보 수집ᆞ이용ᆞ제공 동의서
                    </div>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      (수임 계약시)
                    </div>
                    <Agreecontent>
                      본인은 청람세무회계사무소와의 업무 수임계약과 관련하여
                      청람세무회계사무소가 본인의 개인(세무ᆞ회계)정보를
                      수집ᆞ이용하거나 제3자에게 제공하고자 하는
                      경우에는「개인정보 보호법」 제15조, 제17조에 따라 본인의
                      동의를 얻어야 합니다. 이에 본인은 청람세무회계사무소가
                      아래의 내용과 같이 본인의 개인(세무ᆞ회계)정보를 수집, 이용
                      또는 제공하는 것에 동의합니다.
                    </Agreecontent>

                    <img src={imageDB.agree1} style={{ height: "170px" }} />

                    <img src={imageDB.agree2} style={{ height: "250px" }} />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "white",
                      }}
                    >
                      <Button
                        buttonText={"동의서를 모두 확인하였습니다"}
                        callback={_handlenext}
                        containerStyle={{
                          backgroundColor: colors.mainbuttoncolor,
                          color: "#fff",
                          marginTop: "20px",
                          width: "85%",
                          height: 40,
                          fontSize: 17,
                          fontWeight: 700,
                          borderRadius: "10px",
                        }}
                      />
                    </div>
                  </div>
                </Box>
              </Fade>
            </Modal>
          </div>
        </div>
      </Mobile>
      <PC>
        <div>
          <Modal
            aria-labelledby="spring-modal-title"
            aria-describedby="spring-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                TransitionComponent: Fade,
              },
            }}
          >
            <Fade in={open}>
              <Box sx={style}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <div style={{ fontSize: 18, fontWeight: 600 }}>
                    개인(세무ᆞ회계)정보 수집ᆞ이용ᆞ제공 동의서
                  </div>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    (수임 계약시)
                  </div>
                  <Agreecontent>
                    본인은 청람세무회계사무소와의 업무 수임계약과 관련하여
                    청람세무회계사무소가 본인의 개인(세무ᆞ회계)정보를
                    수집ᆞ이용하거나 제3자에게 제공하고자 하는 경우에는「개인정보
                    보호법」 제15조, 제17조에 따라 본인의 동의를 얻어야 합니다.
                    이에 본인은 청람세무회계사무소가 아래의 내용과 같이 본인의
                    개인(세무ᆞ회계)정보를 수집, 이용 또는 제공하는 것에
                    동의합니다.
                  </Agreecontent>

                  <img src={imageDB.agree1} style={{ height: 170 }} />

                  <img src={imageDB.agree2} style={{ height: 370 }} />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      buttonText={"동의서를 모두 확인하였습니다"}
                      callback={_handlenext}
                      containerStyle={{
                        backgroundColor: colors.mainbuttoncolor,
                        color: "#fff",
                        marginTop: "20px",
                        width: "85%",
                        height: 40,
                        fontSize: 17,
                        fontWeight: 700,
                        borderRadius: "10px",
                      }}
                    />
                  </div>
                </div>
              </Box>
            </Fade>
          </Modal>
        </div>
      </PC>
    </>
  );
}