import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";
import { UserContext } from "../context/User";
import { useSleep } from "../utility/common";

import { imageDB } from "../utility/imageData";
import { Mobile, PC } from "./Responsive";

const Container = styled.div`
position:absolute;
top:25%;
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
left: 12%;

`;
const PCContainer = styled.div`
width: 27%;
margin: 0px 37%;
background: white;
padding-top: 80px;
height: 100%;
height: ${({ height }) => height - 10}px;

border: 1px solid;
`;


const Loading2 = ({ containerStyle }) => {

  const { user, dispatch2 } = useContext(UserContext);
  const [height, setHeight] = useState(window.innerHeight);



  const navigate = useNavigate();

  useEffect(()=>{

    async function FetchData(){

     const update = await useSleep(3000);


      const logintype = user.authtype;
      navigate("/wetaxget", { state: { LOGINTYPE: logintype } });

    }
    FetchData();

  },[])

  return (
    <>
    <Mobile>
    <Container style={containerStyle}>
      <img src={imageDB.loading} style={{ width: "100px" }} />
      <div style={{padding:"20px 20px 5px", fontFamily:"Pretendard-Bold"}}>자료 수집의 마지막 절차입니다. 위택스 인증이 필요합니다</div>

        <div style={{padding:"5px 20px", fontFamily:"Pretendard-Bold"}}>
        위택스 인증을 위해 이동하겠습니다</div>
    </Container>
    </Mobile>

<PC>
<PCContainer height={height}>
<img src={imageDB.loading} style={{ width: "100px" }} />
    <div style={{padding:"20px 20px 5px", fontFamily:"Pretendard-Bold"}}>자료 수집의 마지막 절차입니다. 위택스 인증이 필요합니다</div>

        <div style={{padding:"5px 20px", fontFamily:"Pretendard-Bold"}}>
        위택스 인증을 위해 이동하겠습니다</div>
</PCContainer>
</PC>
</>

  );
};

export default Loading2;
