import React, {
  useState,
  useEffect,
  useContext,
  useLayoutEffect,
  useRef,
} from "react";
import {
  HashRouter,
  Route,
  Redirect,
  BrowserRouter,
  Routes,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Swipe from "../common/Swipe";

import styled from "styled-components";

import Guide from "../components/Guide";
import Swipe2 from "../common/Swipe2";
import Button from "../common/Button";
import { colors, theme } from "../theme/theme";
import InputItem from "../components/InputItem";
import InputReturn from "../components/InputReturn";
import Next from "../components/Next";
import { Mobile, PC } from "../components/Responsive";
import useDimension from "../common/UseDimension";
import SelectItem from "../components/SelectItem";
import { UserContext } from "../context/User";

const Container = styled.div`
  padding-top: 70px;
  background: white;

`;
const PCContainer = styled.div`
width: 27%;
margin: 0px 37%;
  background: white;
  padding-top: 80px;
  height: ${({height}) =>height -80}px;

`;
const LabelItem = styled.div`
  font-weight: 400;
  line-height: 1.8;
  font-size: 16px;
  text-decoration-line: none;
  color: #000;
  display: flex;
  text-align: left;
`;

const ButtonDisableSt = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10;
  background-color: ${({ enable }) =>
    enable == false ? "#68686a" : "rgb(240 240 242)"};
  color: ${({ enable }) => (enable == false ? "#fff" : "rgb(125 124 124)")};
  margin: 10px;
  width: 40%;
  height: 40px;
  fontsize: 14;
  fontweight: 400;
  border-radius: 10px;
`;
const ButtonSt = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10;
  background-color: ${({ enable }) =>
    enable == true ? "#68686a" : "rgb(240 240 242)"};
  color: ${({ enable }) => (enable == true ? "#fff" : "rgb(125 124 124)")};
  margin: 10px;
  width: 40%;
  height: 40px;
  fontsize: 14;
  fontweight: 400;
  border-radius: 10px;
`;


const Optioncontainer = () => {


  const navigate = useNavigate();
  const { user, dispatch2 } = useContext(UserContext);
  const [height, setHeight] = useState(window.innerHeight);
  const [enable, setEnable] = useState(false);
  const [refresh, setRefresh] = useState(1);
  

  const [bizcheck, setBizcheck] = useState(false);
  const [bizrealstatecheck, setBizrealstatecheck] = useState(false);
  const [bizhumancostcheck, setBizhumancostcheck] = useState(false);


  const _handlebizcheck = () => {
    setBizcheck(!bizcheck);
    setRefresh(refresh => refresh + 1);
  }

  const _handlebizrealstatecheck = () => {
    setBizrealstatecheck(!bizrealstatecheck);
    setRefresh((refresh) => refresh + 1);
  };

  const _handlebizhumancostcheck = () => {
    setBizhumancostcheck(!bizhumancostcheck);
    setRefresh((refresh) => refresh + 1);
  };
  
  
  useEffect(() => {
    setEnable(enable);
    setBizcheck(bizcheck);
    setBizrealstatecheck(bizrealstatecheck);
    setBizhumancostcheck(bizhumancostcheck);
   }, [refresh]);

  const _handlenext = () => {
    if (bizcheck == true) {
      user["bizrealstate"] = bizrealstatecheck;
      user["bizhumancost"] = bizhumancostcheck;
      dispatch2(user);
       
      navigate("/biz"); 
    } else {
      navigate("/authselect");
    }
    
  }


 
  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {};
  }, []);
  
  return (
    <>
      <Mobile>
        <Container height={height}>
          <Guide main1={"기초 질문사항입니다"}></Guide>

          <div style={{ padding: "0px 5%" }}>
            <LabelItem>
              1. 사업자등록증이 있나여?(사업자 등록증이 없으면 아니오를
              선택해주세요)
            </LabelItem>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 20,
                justifyContent: "center",
              }}
            >
              <ButtonDisableSt onClick={_handlebizcheck} enable={bizcheck}>
                아니오
              </ButtonDisableSt>

              <ButtonSt onClick={_handlebizcheck} enable={bizcheck}>
                예
              </ButtonSt>
            </div>
          </div>

          <div style={{ padding: "0px 5%" }}>
            <LabelItem>
              2. 사업자 등록증 중에 상가나 사무실 임대가 있나여?(아니면 아니오를
              선택해주세요)
            </LabelItem>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 20,
                justifyContent: "center",
              }}
            >
              <ButtonDisableSt
                onClick={_handlebizrealstatecheck}
                enable={bizrealstatecheck}
              >
                아니오
              </ButtonDisableSt>

              <ButtonSt
                onClick={_handlebizrealstatecheck}
                enable={bizrealstatecheck}
              >
                예
              </ButtonSt>
            </div>
          </div>

          <div style={{ padding: "0px 5% 0px" }}>
            <LabelItem>
              3. 인건비 관련 신고를 직접 하셨나요?(아니면 아니오를 선택해주세요)
            </LabelItem>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 20,
                justifyContent: "center",
              }}
            >
              <ButtonDisableSt
                onClick={_handlebizhumancostcheck}
                enable={bizhumancostcheck}
              >
                아니오
              </ButtonDisableSt>
              <ButtonSt
                onClick={_handlebizhumancostcheck}
                enable={bizhumancostcheck}
              >
                예
              </ButtonSt>
            </div>
          </div>

          <Next
            callback={_handlenext}
            enable={true}
            containerStyle={{ width: "100%", marginBottom: 40 }}
          />
        </Container>
      </Mobile>
      <PC>
        <PCContainer height={height}>
          <Guide main1={"기초 질문사항입니다"}></Guide>

          <div style={{ padding: "0px 5%" }}>
            <LabelItem>
              1. 사업자등록증이 있나여?(사업자 등록증이 없으면 아니오를
              선택해주세요)
            </LabelItem>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 20,
                justifyContent: "center",
              }}
            >
              <ButtonDisableSt onClick={_handlebizcheck} enable={bizcheck}>
                아니오
              </ButtonDisableSt>

              <ButtonSt onClick={_handlebizcheck} enable={bizcheck}>
                예
              </ButtonSt>
            </div>
          </div>

          <div style={{ padding: "0px 5%" }}>
            <LabelItem>
              2. 사업자 등록증 중에 상가나 사무실 임대가 있나여?(아니면 아니오를
              선택해주세요)
            </LabelItem>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 20,
                justifyContent: "center",
              }}
            >
              <ButtonDisableSt
                onClick={_handlebizrealstatecheck}
                enable={bizrealstatecheck}
              >
                아니오
              </ButtonDisableSt>

              <ButtonSt
                onClick={_handlebizrealstatecheck}
                enable={bizrealstatecheck}
              >
                예
              </ButtonSt>
            </div>
          </div>

          <div style={{ padding: "0px 5% 0px" }}>
            <LabelItem>
              3. 인건비 관련 신고를 직접 하셨나요?(아니면 아니오를 선택해주세요)
            </LabelItem>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 20,
                justifyContent: "center",
              }}
            >
              <ButtonDisableSt
                onClick={_handlebizhumancostcheck}
                enable={bizhumancostcheck}
              >
                아니오
              </ButtonDisableSt>
              <ButtonSt
                onClick={_handlebizhumancostcheck}
                enable={bizhumancostcheck}
              >
                예
              </ButtonSt>
            </div>
          </div>

          <Next
            callback={_handlenext}
            enable={true}
            containerStyle={{ width: "100%", marginTop: 70, marginBottom: 40 }}
          />
        </PCContainer>
      </PC>
    </>
  );
};

export default Optioncontainer;
