import React, {
  useState,
  useEffect,
  useContext,
  useLayoutEffect,
  useRef,
} from "react";
import {
  HashRouter,
  Route,
  Redirect,
  BrowserRouter,
  Routes,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Swipe from "../common/Swipe";

import styled from "styled-components";

import Guide from "../components/Guide";
import Swipe2 from "../common/Swipe2";
import Button from "../common/Button";
import { colors, theme } from "../theme/theme";
import InputItem from "../components/InputItem";
import InputReturn from "../components/InputReturn";
import Next from "../components/Next";
import { Mobile, PC } from "../components/Responsive";
import useDimension from "../common/UseDimension";
import SelectItem from "../components/SelectItem";
import { UserContext } from "../context/User";
import { get_userInfoForUID } from "../service/UserService";
import { BiCalendarEdit } from "react-icons/bi";
import { FcInfo } from "react-icons/fc";
import { imageDB } from "../utility/imageData";
import Image from "../common/Image";

import { FaCheckCircle } from "react-icons/fa";
import Info from "../common/Info";

const Container = styled.div`
  padding: 80px 20px;
  background: white;

`;
 const PCContainer = styled.div`
    width: 30%;
    margin: 0px 35%;
   background: white;
   padding-top: 80px;
   height: 100%;
   height: ${({ height }) => height - 80}px;
   border: 1px solid;
 `;
const LabelItem = styled.div`
  font-weight: 400;
  line-height: 1.5;
  font-size: 1.2rem;
  text-decoration-line: none;
  color: #000;
  padding-left: 10px;
`;

const Box = styled.div`
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;

  margin: 10px;
  border: ${({ enablebiz }) =>
    enablebiz == true ? "2px solid #3268f3;" : "1px solid #ededed;"};
`;

const DisableBox = styled.div`
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;

  margin: 10px;
  border: ${({ disablebiz }) =>
    disablebiz == true ? "2px solid #3268f3;" : "1px solid #ededed;"};
`;



 


const BizTypeInfocontainer = () => {


  const navigate = useNavigate();
  
  const {user, dispatch2 } = useContext(UserContext);
  const [height, setHeight] = useState(window.innerHeight);


  
  const [enable, setEnable] = useState(false);
  const [refresh, setRefresh] = useState(1);
  const [enablerefresh, setEnablerefresh] = useState(1);


  const [bizrealstateenablecheck, setBizrealstateenablecheck] = useState(false);
  const [bizrealstatedisablecheck, setBizrealstatedisablecheck] =
    useState(false);
  
  const [bizhumancostenablecheck, setBizhumancostenablecheck] = useState(false);
  const [bizhumancostdisablecheck, setBizhumancostdisablecheck] =
     useState(false); 

  
  const _handlenext = async () => {
    


    if(bizrealstateenablecheck)
    {
      user["bizrealstate"] = true;
    } else {
      user["bizrealstate"] = false;
    }

    if (bizhumancostenablecheck) {
      user["bizhumancost"] = true;
    } else {
      user["bizhumancost"] = false;
    }


    dispatch2(user);
    navigate("/authselect");

  }

  const _handleenablerealstate = (type) => {
    setBizrealstateenablecheck(true);
    setBizrealstatedisablecheck(false);
    setRefresh((refresh) => refresh + 1);
  }

  const _handledisablerealstate = (type) => {
    setBizrealstateenablecheck(false);
    setBizrealstatedisablecheck(true);
    setRefresh((refresh) => refresh + 1);
  };

  const _handleenablehumancoststate = (type) => {
    setBizhumancostenablecheck(true);
    setBizhumancostdisablecheck(false);
    
    setRefresh((refresh) => refresh + 1);
  };

  const _handledisablehumancoststate = (type) => {
    setBizhumancostenablecheck(false);
    setBizhumancostdisablecheck(true);
    
    
    setRefresh((refresh) => refresh + 1);
  };
  

  useEffect(() => {
    setBizrealstateenablecheck(bizrealstateenablecheck);
    setBizrealstatedisablecheck(bizrealstatedisablecheck);
    setBizhumancostenablecheck(bizhumancostenablecheck);
    setBizhumancostdisablecheck(bizhumancostdisablecheck);
    
    if (
      (bizrealstateenablecheck == true || bizrealstatedisablecheck == true) &&
      (bizhumancostenablecheck == true || bizhumancostdisablecheck == true)
    ) {
      setEnable(true);
    }

  }, [refresh])
  
  useEffect(() => {
    setEnable(enable);
  }, [enablerefresh]);

 
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);


  
  return (
    <>
      <Mobile>
        <Container height={height}>
          <Guide
            main1={"보유하고 계신 사업장의 자세한 정보를  입력해주세요"}
            main2={""}
            sub={""}
          ></Guide>

          <Info content={"사업자 등록증에 기입된 업종를 보고 선택해주세요"} />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              className="button"
              enablebiz={bizrealstateenablecheck}
              onClick={() => {
                _handleenablerealstate(true);
              }}
            >
              <Image
                source={imageDB.logo}
                containerStyle={{ width: 55, paddingLeft: 10, height: 45 }}
              />
              <div style={{ color: "#999999" }}>
                상가나 사무실 임대 하고있나여?
              </div>

              {bizrealstateenablecheck == true && (
                <div style={{ position: "absolute" }}>
                  <FaCheckCircle color={"#5974e3"} size={30} />
                </div>
              )}
            </Box>
            <DisableBox
              className="button"
              disablebiz={bizrealstatedisablecheck}
              onClick={() => {
                _handledisablerealstate(true);
              }}
            >
              <Image
                source={imageDB.logo}
                containerStyle={{ width: 55, paddingLeft: 10, height: 45 }}
              />
              <div style={{ color: "#999999" }}>
                상가나 사무실 임대를 하지않음
              </div>

              {bizrealstatedisablecheck == true && (
                <div style={{ position: "absolute" }}>
                  <FaCheckCircle color={"#5974e3"} size={30} />
                </div>
              )}
            </DisableBox>
          </div>

          <Info content={"인건비 신고를 하셨나여?"} />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              className="button"
              enablebiz={bizhumancostenablecheck}
              onClick={() => {
                _handleenablehumancoststate(true);
              }}
            >
              <Image
                Radius={0}
                source={imageDB.maingbg1}
                containerStyle={{
                  width: 75,
                  paddingLeft: 10,
                  height: 55,
                  borderRadius: 0,
                }}
              />
              <div style={{ color: "#999999" }}>
                인건비 관련 신고를 하셨나여?
              </div>

              {bizhumancostenablecheck == true && (
                <div style={{ position: "absolute" }}>
                  <FaCheckCircle color={"#5974e3"} size={30} />
                </div>
              )}
            </Box>
            <DisableBox
              className="button"
              disablebiz={bizhumancostdisablecheck}
              onClick={() => {
                _handledisablehumancoststate(true);
              }}
            >
              <Image
                Radius={0}
                source={imageDB.maingbg1}
                containerStyle={{
                  width: 75,
                  paddingLeft: 10,
                  height: 55,
                  borderRadius: 0,
                }}
              />
              <div style={{ color: "#999999" }}>
                인건비 관련 신고를 하지않음
              </div>

              {bizhumancostdisablecheck == true && (
                <div style={{ position: "absolute" }}>
                  <FaCheckCircle color={"#5974e3"} size={30} />
                </div>
              )}
            </DisableBox>
          </div>

          <Next
            callback={_handlenext}
            enable={enable}
            containerStyle={{ width: "100%", marginTop: 30 }}
          />
        </Container>
      </Mobile>
      <PC>
        <PCContainer height={height}>
          <Guide
            main1={"보유하고 계신 사업장의 자세한 정보를  입력해주세요"}
            main2={""}
            sub={""}
          ></Guide>


          <Info content={"사업자 등록증에 기입된 업종를 보고 선택해주세요"} />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              className="button"
              enablebiz={bizrealstateenablecheck}
              onClick={() => {
                _handleenablerealstate(true);
              }}
            >
              <Image
                source={imageDB.logo}
                containerStyle={{ width: 55, paddingLeft: 10, height: 45 }}
              />
              <div style={{ color: "#999999" }}>
                상가나 사무실 임대 하고있나여?
              </div>

              {bizrealstateenablecheck == true && (
                <div style={{ position: "absolute" }}>
                  <FaCheckCircle color={"#5974e3"} size={30} />
                </div>
              )}
            </Box>
            <DisableBox
              className="button"
              disablebiz={bizrealstatedisablecheck}
              onClick={() => {
                _handledisablerealstate(true);
              }}
            >
              <Image
                source={imageDB.logo}
                containerStyle={{ width: 55, paddingLeft: 10, height: 45 }}
              />
              <div style={{ color: "#999999" }}>
                상가나 사무실 임대를 하지않음
              </div>

              {bizrealstatedisablecheck == true && (
                <div style={{ position: "absolute" }}>
                  <FaCheckCircle color={"#5974e3"} size={30} />
                </div>
              )}
            </DisableBox>
          </div>

          <Info content={"인건비 신고를 하셨나여?"} />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              className="button"
              enablebiz={bizhumancostenablecheck}
              onClick={() => {
                _handleenablehumancoststate(true);
              }}
            >
              <Image
                Radius={0}
                source={imageDB.maingbg1}
                containerStyle={{
                  width: 75,
                  paddingLeft: 10,
                  height: 55,
                  borderRadius: 0,
                }}
              />
              <div style={{ color: "#999999" }}>
                인건비 관련 신고를 하셨나여?
              </div>

              {bizhumancostenablecheck == true && (
                <div style={{ position: "absolute" }}>
                  <FaCheckCircle color={"#5974e3"} size={30} />
                </div>
              )}
            </Box>
            <DisableBox
              className="button"
              disablebiz={bizhumancostdisablecheck}
              onClick={() => {
                _handledisablehumancoststate(true);
              }}
            >
              <Image
                Radius={0}
                source={imageDB.maingbg1}
                containerStyle={{
                  width: 75,
                  paddingLeft: 10,
                  height: 55,
                  borderRadius: 0,
                }}
              />
              <div style={{ color: "#999999" }}>
                인건비 관련 신고를 하지않음
              </div>

              {bizhumancostdisablecheck == true && (
                <div style={{ position: "absolute" }}>
                  <FaCheckCircle color={"#5974e3"} size={30} />
                </div>
              )}
            </DisableBox>
          </div>

          <Next
            callback={_handlenext}
            enable={enable}
            containerStyle={{ width: "100%", marginTop: 30 }}
          />
        </PCContainer>
      </PC>
    </>
  );
};

export default BizTypeInfocontainer;
