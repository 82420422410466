import React, { useState, useEffect, useRef } from "react";
import {
  HashRouter,
  Route,
  Switch,
  Redirect,
  BrowserRouter,
  Routes,
  Link,
  useNavigate,
} from "react-router-dom";
import styled from "styled-components";
import { imageDB } from "../utility/imageData";
import Button from "../common/Button";
import { update_userfile, uploadImage } from "../service/UserService";
import { IoIosCloseCircle } from "react-icons/io";
import { AttachType } from "../utility/contentDefine";
import { FcInfo } from "react-icons/fc";
import Info from "../common/Info";
import { SiMicrosoftexcel } from "react-icons/si";
import { FaTrashAlt } from "react-icons/fa";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 2;
  width: 90%;
  background: ${({ bgcolor }) => bgcolor};
  margin: 5px 0px;
  box-shadow: 1px 1px 1px 2px #f5f5f5;
  border-radius: 5px;
`;

const LabelItem = styled.div`
  font-weight: 500;
  line-height: 1.4;
  font-size: 0.875rem;
  text-decoration-line: none;
  color: rgb(58, 64, 71);
  display: flex;
`;

const LabelItem2 = styled.div`
  font-weight: 500;
  line-height: 1.4;
  font-size: 0.875rem;
  text-decoration-line: none;
  color: rgb(58, 64, 71);
  display: flex;
  padding-left:20px;
  margin-top:-5px;
`;


const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content : center;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px;
`;

const ItemData = styled.div`
  font-weight: 600;
  line-height: 1.5;
  font-size: 14px;
  text-decoration-line: none;
  color: #788391;
  text-align: left;
  padding: 0px 10px;
`;

const LineControl = styled.div`
  margin-top: 20px;
  width: 100%;
  height: 1px;
  background-color: #f0f1f2;
`;

const ImageItem = styled.div`
  margin :5px;
  
`;

const ExcelItem = styled.div`
  width: 100%;
  margin: 20px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 5px;
`;
const CloseIcon = styled.div`
  position: relative;
  top: 100px;
  left: 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
`;

const Tag = styled.div`
  width: 30%;
  font-size: 14px;
  background-color: #f85b2d;
  color: #fff;
  border-radius: 5px;
`;

const DocumentItem = ({
  containerStyle,
  label,
  label2,
  label3,
  content,
  color,
  attachtype,
  taxid,
  images,
  note,
}) => {
  const [imageurls, setImageUrls] = useState(images);

  console.log("imageurls", imageurls);
  const [refresh, setRefresh] = useState(1);
  const [reload, setReload] = useState(1);

  const navigate = useNavigate();


  const _handleupload = () => {

    if ((attachtype == AttachType.TYPE6) | (attachtype == AttachType.TYPE7)) {
      navigate("/excelinfo", { state: { type: attachtype, taxid: taxid } });
    } else {
      navigate("/imageinfo", { state: { type: attachtype, taxid: taxid } });
    }

  };

  return (
    <Container style={containerStyle}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "10px 20px",
          alignItems: "center",
        }}
      >
        <div style={{display:"flex", flexDirection:"column"}}>
        <LabelItem>{label}</LabelItem>
  
        </div>
     
        {note == true ? (
          <Tag className="button">입력하기</Tag>
        ) : (
          <>
            <Tag className="button" onClick={_handleupload}>
              첨부하기
            </Tag>
          </>
        )}
      </div>
      <LabelItem2>{label2}</LabelItem2>
      <Content>
    
        {imageurls.length == 0 && (
          <Info content={content} containerStyle={{ padding: "0px 10px" }} />
        )}

        {attachtype == AttachType.TYPE6 && imageurls.length != 0 && (
          <table style={{ marginTop: 5, fontSize: 14, width: "100%" }}>
            <thead>
              <tr>
                <th scope="col">순번</th>
                <th scope="col">내용</th>
              </tr>
            </thead>

            <tbody>
              {imageurls.map((data, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>
                    <ExcelItem>
                      <SiMicrosoftexcel size={20} />

                      <a
                        target={"_blank"}
                        href={data.path}
                        style={{ marginLeft: 10 }}
                      >
                        <div
                          style={{
                            color: "#000",
                            textDecoration: "underline",
                            fontSize: 13,
                          }}
                        >
                          {data.name}
                        </div>
                      </a>
                    </ExcelItem>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {attachtype == AttachType.TYPE7 && imageurls.length != 0 && (
          <table style={{ marginTop: 5, fontSize: 14, width: "100%" }}>
            <thead>
              <tr>
                <th scope="col">순번</th>
                <th scope="col">내용</th>
              </tr>
            </thead>

            <tbody>
              {imageurls.map((data, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>
                    <ExcelItem>
                      <SiMicrosoftexcel size={20} />

                      <a
                        target={"_blank"}
                        href={data.path}
                        style={{ marginLeft: 10 }}
                      >
                        <div
                          style={{
                            color: "#000",
                            textDecoration: "underline",
                            fontSize: 13,
                          }}
                        >
                          {data.name}
                        </div>
                      </a>
                    </ExcelItem>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {attachtype != AttachType.TYPE6 && attachtype != AttachType.TYPE7 && (
          <>
            {imageurls.map((data, index) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  border: "1px solid",
                  margin: 5,
                }}
              >
                <ImageItem>
                  <div className="button" style={{display:"flex", flexDirection:"row", justifyContent:"flex-end", marginBottom:3}}>
                    <FaTrashAlt />
                  </div>

                  <img
                    src={data}
                    style={{ height: "100px", width: "100px", display: "flex" }}
                  />
                </ImageItem>
              </div>
            ))}
          </>
        )}
      </Content>
      <LineControl />
    </Container>
  );
};

export default DocumentItem;
