import React, {
  useState,
  useEffect,
  useContext,
  useLayoutEffect,
  useRef,
} from "react";
import {
  HashRouter,
  Route,
  Redirect,
  BrowserRouter,
  Routes,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Swipe from "../common/Swipe";

import styled from "styled-components";
import Guide from "../components/Guide";
import Swipe2 from "../common/Swipe2";
import Button from "../common/Button";
import { colors, theme } from "../theme/theme";
import InputItem from "../components/InputItem";
import { imageDB } from "../utility/imageData";
import AcceptModalEx from "../components/AcceptModalEx";
import ContentItem from "../components/ContentItem";
import { Mobile, PC } from "../components/Responsive";
import NoticeItem from "../components/NoticeItem";
import { getDateFullTime } from "../utility/common";
import { UserContext } from "../context/User";
import { get_tax } from "../service/UserService";


const PCContainer = styled.div`
width: 27%;
margin: 0px 37%;
  background: white;
  padding-top: 80px;
  height:650px;
  border :1px solid;
`;
const Container = styled.div`
  width: 100%;
  background: white;
  padding-top: 80px;
`;


const Noticecontainer = () => {
  const navigate = useNavigate();
  const { user, dispatch2 } = useContext(UserContext);
  const [msgitems, setMsgitems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [taxid, setTaxid] = useState('');

    useEffect(() => {
      window.scrollTo(0, 0);
      return () => {
      };
    }, []);
  
  useEffect(() => {
    async function FetchData() {
      const username = user.name;
      const phonedata = user.phone.replaceAll("-", "");

      console.log("info", username, phonedata);
      const taxitem = await get_tax({ username, phonedata });

      setTaxid(taxitem.tax_id);

      console.log("taxitem", taxitem.alarm);
      setMsgitems(taxitem.alarm);
      setLoading(true);
    }
    FetchData();
  }, [])
  
  return (
    <>
      {loading == true ? (
        <div>
          <Mobile>
            <Container>
              <Guide
                main1={"청람세무회계 사무소에서 보내온 알림 내용입니다"}
              ></Guide>

              <div style={{ marginTop: 30 }}></div>
              <div>각 항목을 클릭하면 자세한 내용을 확인할수가 있습니다</div>

              {msgitems.map((data, index) => (
                <NoticeItem
                  label={getDateFullTime(data.msgregistdate)}
                  read={data.msgread}
                  content={data.msg}
                  index={index}
                  items={msgitems}
                  tax_id={taxid}
                />
              ))}

              <div style={{ height: 30 }}></div>
            </Container>
          </Mobile>
          <PC>
            <PCContainer>
              <Guide
                main1={"청람세무회계 사무소에서 보내온 알림 내용입니다"}
              ></Guide>

              <div style={{ marginTop: 30 }}></div>
              <div>각 항목을 클릭하면 자세한 내용을 확인할수가 있습니다</div>
              
              {msgitems.map((data, index) => (
                <NoticeItem
                  label={getDateFullTime(data.msgregistdate)}
                  read={data.msgread}
                  content={data.msg}
                  index={index}
                  items={msgitems}
                  tax_id={taxid}
                />
              ))}

              <div style={{ height: 30 }}></div>
            </PCContainer>
          </PC>
        </div>
      ) : null}
    </>
  );
};

export default Noticecontainer;
