import React,{useState, useEffect, useContext} from 'react';
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, Link, useNavigate} from "react-router-dom";
import styled from 'styled-components';
import { imageDB } from '../utility/imageData';
import Relationbtn from './Relationbtn';
import Button from '../common/Button';
import { colors } from '../theme/theme';
import { GrAddCircle } from "react-icons/gr";
import { CgUserRemove } from 'react-icons/cg';
import { UserContext } from '../context/User';
import { get_tax, update_user2 } from '../service/UserService';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 2;
  width: 90%;
  background: #e8e8e8;
  margin: 5px 5%;
  box-shadow: 2px 2px 2px 2px #adabab;
  border-radius: 5px;
`;

const LabelItem = styled.div`
  font-weight: 700;
  line-height: 1.4;
  font-size: 1rem;
  text-decoration-line: none;
  color: rgb(58, 64, 71);
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  padding-left: 30px;
  align-items: center;
  flex-direction: row;
  width: 90%;
`;

const Content = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  margin-top:5px;
`
const ItemLabel = styled.div`
  width: 50px;
  height: 50px;
  background-color: #ededed;
  border-radius: 100%;
  position: absolute;
  left: 30px;
`;
const ItemData = styled.div`
  font-weight: 600;
  line-height: 1.5;
  font-size: 14px;
  text-decoration-line: none;
  color: #788391;;
  text-align:left;
  padding : 0px 10px;
`;

const LineControl = styled.div`
  margin-top:20px;
  width: 100%;
  height: 1px;
  background-color: #f0f1f2;
`;

const Note = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  text-align: right;
  font-size: 16px;
  margin-top: 20px;
  align-items: flex-end;
`;

const Info = styled.div`
  font-size: 14px;
  text-align: left;
  padding-left: 20px;
`;

const OPTIONS = [
  { value: "장애없음", name: "장애없음" },
  { value: "장애있음", name: "장애있음" },
];

const SelectBox = (props) => {
  return (
    <select style={{padding:3, outline:0, margin:5}}>
      {props.options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.name}
        </option>
      ))}
    </select>
  );
};


const ResultItem = ({
  containerStyle,
  item,
  label,
  content,
  color,
  callback,
  bgcolor,
}) => {
  const navigate = useNavigate();





  return (
    <Container style={containerStyle} bgcolor={bgcolor}>
      <Content>
        <LabelItem>[청람세무회계 김인웅세무사]</LabelItem>



        <div style={{ marginTop: 20, width: "100%" }}>
          <Info>귀하의 귀속 종합소득세 신고를 완료하고 결과 안내드립니다</Info>
        </div>

        <table style={{ marginTop: 5, fontSize: 12, width: "90%", height:150 }}>
          <thead>
            <tr>
              <th scope="col">구분</th>
              <th scope="col">내용</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>부양가족 공제</td>
              <td>{item.familysu}명</td>
            </tr>
            <tr>
              <td>종합소득세</td>
              <td>{item.expense1}원 납부</td>
            </tr>
            <tr>
              <td>지방소득세</td>
              <td>{item.expense2}원 납부</td>
            </tr>
          </tbody>
        </table>
        <div style={{ marginTop: 20, width: "100%" }}>
          <Info>납부 {item.expense3}원으로 신고 되었습니다</Info>
        </div>

        <div style={{ marginTop: 20, width: "100%" }}>
          <Info>신고수수료 {item.expense4}원</Info>
          <Info>국민은행 339-202-303303-222</Info>
          <Info>하나은행 3493-33-3-32420</Info>
          <Info>기업은행 982-29292-22234</Info>

          <Info>예금주 김인웅</Info>
          <Info>31일까지 편한 계좌로 송금해주시기 바랍니다</Info>

          <Info>
            보내주셨던 자료애 정정할 내용이 있거나 신고내용 확인이 필요하신 경우
            연락주시기 바랍니다
          </Info>
          <Info>늘 건강과 행복	이 함께 하기를 기원합니다</Info>
        </div>

      </Content>

      <LineControl />
    </Container>
  );
};

ResultItem.defaultProps = {
  bgcolor: "#FFF",
};


export default ResultItem;
