import React,{useState, useEffect} from 'react';
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, Link, useNavigate} from "react-router-dom";
import styled from 'styled-components';
import { imageDB } from '../utility/imageData';
import Relationbtn from './Relationbtn';
import Button from '../common/Button';
import { CommaFormatted } from '../utility/common';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 2;
  background: ${({bgcolor}) => bgcolor};
  margin: 5px 0px;
  border-radius: 5px;
`;

const LabelItem = styled.div`
  font-weight: 700;
  line-height: 1.4;
  font-size: 1rem;
  text-decoration-line: none;
  color: rgb(58, 64, 71);
  margin-top:20px;
  display:flex;
  justify-content:flex-start;
  padding-left:30px;
  align-items:center;
  flex-direction:row;
`;

const Content = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  margin-top:5px;
`
const ItemLabel = styled.div`
  width: 50px;
  height: 50px;
  background-color: #ededed;
  border-radius: 100%;
  position: absolute;
  left: 30px;
`;
const ItemData = styled.div`
  font-weight: 600;
  line-height: 1.5;
  font-size: 14px;
  text-decoration-line: none;
  color: #788391;;
  text-align:left;
  padding : 0px 10px;
`;

const LineControl = styled.div`
  margin-top:20px;
  width: 100%;
  height: 1px;
  background-color: #f0f1f2;
`;

const Note = styled.div`
  font-size: 12px;
  text-align: left;
  padding-left: 20px;
`;

const Info = styled.div`
  font-size: 14px;
  text-align: left;
  padding-left: 20px;
`;

const Items = [
  { name: "건강보험료", money: "" },
  { name: "고용보험료", money: "" },
  { name: "국민연금보험료", money: "" },
  { name: "보장성보험료", money: "" },
  { name: "의료비", money: "" },
  { name: "실손의료보험금", money: "" },
  { name: "신용카드", money: "" },
  { name: "직불카드", money: "" },
  { name: "현금영수증", money: "" },
  { name: "기부금", money: "" },
  { name: "건강보험료", money: "" },
];

const YearItem = ({
  containerStyle,
  label,
  items,
  content,
  color,
  callback,
  bgcolor,
}) => {
  const navigate = useNavigate();
  useEffect(() => {
    async function fetchData() {}
    fetchData();
  }, []);

  const _handlecallback = () => {

  };
  const handleCheck = () => {
    
  }

  function codecheck(code){
    if (code == "GOO01") {
      return "보험료";
    } else {
      return "";
    }
  }

  return (
    <Container
      style={containerStyle}
      onClick={_handlecallback}
      bgcolor={bgcolor}
    >
      <LabelItem>
        <div style={{ paddingLeft: 5 }}>{label}</div>
      </LabelItem>
      <Content>
        {/* <ItemData color={color}>{content}</ItemData> */}
        {items == undefined ? (
          <div>홈택스에서 정보를 가져오지 못하였습니다</div>
        ) : (
          <table style={{ marginTop: 5, fontSize: 14, width: "90%" }}>
            <thead>
              <tr>
                <th scope="col">내용</th>
                <th scope="col">금액</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td style={{ width: "60%" }}>{"보험료	"}</td>
                <td style={{ width: "40%" }}>
                  {CommaFormatted(items.G0001.totSumAmt)}원
                </td>
              </tr>
              <tr>
                <td style={{ width: "60%" }}>{"의료비"}</td>
                <td style={{ width: "50%" }}>
                  {CommaFormatted(items.G0003.totSumAmt)}원
                </td>
              </tr>
              <tr>
                <td style={{ width: "60%" }}>{"교육비"}</td>
                <td style={{ width: "50%" }}>
                  {CommaFormatted(items.G0004.totSumAmt)}원
                </td>
              </tr>
              <tr>
                <td style={{ width: "60%" }}>{"개인연금/연금계좌"}</td>
                <td style={{ width: "50%" }}>
                  {CommaFormatted(items.G0009.totSumAmt)}원
                </td>
              </tr>
              <tr>
                <td style={{ width: "60%" }}>{"신용카드"}</td>
                <td style={{ width: "50%" }}>
                  {CommaFormatted(items.G0012.totSumAmt)}원
                </td>
              </tr>
              <tr>
                <td style={{ width: "60%" }}>{"현금영수증"}</td>
                <td style={{ width: "50%" }}>
                  {CommaFormatted(items.G0013.totSumAmt)}원
                </td>
              </tr>
              <tr>
                <td style={{ width: "60%" }}>{"직불카드등"}</td>
                <td style={{ width: "50%" }}>
                  {CommaFormatted(items.G0014.totSumAmt)}원
                </td>
              </tr>
              <tr>
                <td style={{ width: "60%" }}>{"주택마련저축"}</td>
                <td style={{ width: "50%" }}>
                  {CommaFormatted(items.G0018.totSumAmt)}원
                </td>
              </tr>
              <tr>
                <td style={{ width: "60%" }}>{"소기업소상공인공제부금"}</td>
                <td style={{ width: "50%" }}>
                  {CommaFormatted(items.G0019.totSumAmt)}원
                </td>
              </tr>
              <tr>
                <td style={{ width: "60%" }}>{"기부금"}</td>
                <td style={{ width: "50%" }}>
                  {CommaFormatted(items.G0020.totSumAmt)}원
                </td>
              </tr>
              <tr>
                <td style={{ width: "60%" }}>{"장기집합투자증권저축"}</td>
                <td style={{ width: "50%" }}>
                  {CommaFormatted(items.G0029.totSumAmt)}원
                </td>
              </tr>
              <tr>
                <td style={{ width: "60%" }}>{"건강보험"}</td>
                <td style={{ width: "50%" }}>
                  {CommaFormatted(items.G0030.totSumAmt)}원
                </td>
              </tr>
              <tr>
                <td style={{ width: "60%" }}>{"국민연금"}</td>
                <td style={{ width: "50%" }}>
                  {CommaFormatted(items.G0031.totSumAmt)}원
                </td>
              </tr>
              <tr>
                <td style={{ width: "60%" }}>{"월세액"}</td>
                <td style={{ width: "50%" }}>
                  {CommaFormatted(items.G0037.totSumAmt)}원
                </td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>{"장애인증명서"}</td>
                <td style={{ width: "50%" }}>
                  {CommaFormatted(items.G0041.totSumAmt)}원
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </Content>

      <LineControl />
    </Container>
  );
};

YearItem.defaultProps = {
  bgcolor: "#FFF",
};


export default YearItem;
