import React,{useState, useEffect} from 'react';
import { CgUserAdd, CgUserRemove } from 'react-icons/cg';
import { GrAddCircle } from 'react-icons/gr';
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, Link, useNavigate} from "react-router-dom";
import styled from 'styled-components';
import { MdOutlineRefresh } from "react-icons/md";
import { SiMicrosoftexcel } from "react-icons/si";

const Container = styled.div`
    margin: 10px 5%;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    width: 90%;
    height: 30px;
    font-size: 9px;
    font-family: Pretendard-Regular;
    border: 1px solid rgb(179, 174, 174);
    display: flex;
    justify-content: center;
    align-items: center;
 
`

const Button = ({containerStyle, buttonText, callback, index, icon, gif}) => {


  const _handleClick = () =>{
    callback(index);
  }



  return (
    <Container style={containerStyle} onClick={_handleClick} className="button">
      <div>{buttonText}</div>
    </Container>
  );
}
Button.defaultProps = {

};

export default Button;
