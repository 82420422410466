

import React from "react";
import DisablePrevHeader from "./Header/DisablePrevHeader";
import PrevHeader from "./Header/PrevHeader";



const DisablePrevLayout = (props) => {


  return (
    <div>
      {props.header == true && <DisablePrevHeader headername={props.headername} />}
      <main>{props.children}</main>
      {/* <Footer  menu ={props.menu} bottom ={props.bottom}  type={"home"}/> */}
    </div>
  );
};

export default DisablePrevLayout;
