

import React, { Fragment, useEffect} from "react";

import { useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { imageDB } from "../../../utility/imageData";
import Image from "../../../common/Image";
import Text from "../../../common/Text";
import { Mobile, PC } from "../../../components/Responsive";
import { GrPrevious } from "react-icons/gr";

const Container = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;
  height: 50px;
  background-color: #424141;
  color: white;
  position: fixed;

  z-index: 10;
  width: 100%;
  
`;

const PCContainer = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;
  height: 50px;
  background-color: white;
  color: white;
  position: fixed;
  top: 0px;
  left: 37%;
  width:27.1%;

  z-index: 10;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:center;
  height: 50px;
  background-color: #424141;
  color: white;
  position: fixed;
  z-index: 10;
`;
const PCRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 50px;
  background-color: #424141;
  color: white;
  position: fixed;
  z-index: 10;
  width : 27.1%;
`;

const DisablePrevHeader = ({containerStyle, headername}) => {

  const navigation = useNavigate();

  const _handlePrev = () =>{
    navigation(-1);

  }

  return (
    <>
      <Mobile>
        <Container>
          <Row>
            <div style={{paddingLeft:10}}>
              <Text
                size={18}
                color={"white"}
                value={"청람회계 세무사무소"}
              ></Text>
            </div>
          </Row>
        </Container>
      </Mobile>
      <PC>
        <PCContainer>
          <PCRow>
          <div style={{paddingLeft:10}}>
              <Text
                size={18}
                color={"white"}
                value={"청람회계 세무사무소"}
              ></Text>
            </div>
          </PCRow>
        </PCContainer>
      </PC>
    </>
  );
};

export default DisablePrevHeader;