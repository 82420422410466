import React,{useState, useEffect, useContext} from 'react';
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, Link, useNavigate} from "react-router-dom";
import styled from 'styled-components';
import { imageDB } from '../utility/imageData';
import Relationbtn from './Relationbtn';
import Button from '../common/Button';
import { colors } from '../theme/theme';
import { GrAddCircle } from "react-icons/gr";
import { CgUserRemove } from 'react-icons/cg';
import { UserContext } from '../context/User';
import { get_tax, update_user2, update_usersingle, update_userwoman } from '../service/UserService';
import { FcInfo } from "react-icons/fc";
import Info from '../common/Info';
import { JuminConvert } from '../utility/common';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 2;
  width: 90%;
  background: ${({ bgcolor }) => bgcolor};
  margin: 5px 0px;
  box-shadow: 1px 1px 1px 2px #e7e6e6;
  border-radius: 5px;
`;

const LabelItem = styled.div`
  font-weight: 700;
  line-height: 1.4;
  font-size: 1rem;
  text-decoration-line: none;
  color: rgb(58, 64, 71);
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  padding-left: 30px;
  align-items: center;
  flex-direction: row;
  width: 90%;
`;

const Content = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  margin-top:5px;
 
`
const ItemLabel = styled.div`
  width: 50px;
  height: 50px;
  background-color: #ededed;
  border-radius: 100%;
  position: absolute;
  left: 30px;
`;
const ItemData = styled.div`
  font-weight: 600;
  line-height: 1.5;
  font-size: 14px;
  text-decoration-line: none;
  color: #788391;;
  text-align:left;
  padding : 0px 10px;
`;

const LineControl = styled.div`
  margin-top:20px;
  width: 100%;
  height: 1px;
  background-color: #f0f1f2;
`;

const Note = styled.div`
  font-size: 12px;
  text-align: left;
  padding-left: 20px;
  margin-top:20px;
`;

const InfoData = styled.div`
  font-size: 14px;
  text-align: left;
  padding-left: 20px;
`;

const ButtonLayer = styled.div`
  display : flex;
  flex-direction : row;
  justify-content: space-between;
  padding : 0x 10px;
`
const OPTIONS = [
  { value: "장애없음", name: "장애없음" },
  { value: "장애있음", name: "장애있음" },
];

const SelectBox = (props) => {
  return (
    <select style={{padding:3, outline:0, margin:5, width:"90%"}}>
      {props.options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.name}
        </option>
      ))}
    </select>
  );
};


const HumanItem = ({
  containerStyle,
  items,
  label,
  content,
  color,
  callback,
  bgcolor,
}) => {
  const navigate = useNavigate();

  const [woman, setWoman] = useState(false);
  const [single, setSingle] = useState(false);
  const [refresh, setRefresh] = useState(1);
  const { user, dispatch2 } = useContext(UserContext);
  const [etchumanitems, setEtchumanitems] = useState([]);
  const [tax, setTax] = useState({});

  useEffect(() => {
    async function fetchData() {

      const username = user.name;
      const phonedata = user.phone;
      const taxdata = await get_tax({ username, phonedata });
      setTax(taxdata);
      setWoman(taxdata.woman);
      setSingle(taxdata.single);

      if (taxdata.etchumandata != undefined) {
        setEtchumanitems(taxdata.etchumandata);
      }

    }
    fetchData();
  }, []);

  const _handlecallback = () => {
   // callback();
  };
  const handleAdd = () => {
    navigate("/humanregister");
  }
  const handleDelete = async(data) => {

    const FindIndex = etchumanitems.findIndex(x => x.name == data.name);

    etchumanitems.splice(FindIndex, 1);
    const username = user.name;
    const phonedata = user.phone;

    const taxitem = await get_tax({ username, phonedata });
    
    let etchumandata = [];
    const tax_id = taxitem.tax_id;
    etchumandata = etchumanitems;

    const u = await update_user2({
      tax_id,
      etchumandata,
    });

    setEtchumanitems(etchumandata);
    setRefresh(refresh => refresh + 1);
    

  }
  const handlewoman = async() => {
    
    setWoman(!woman);
    console.log("woman", woman);

    const username = user.name;
    const phonedata = user.phone;

    const taxitem = await get_tax({ username, phonedata });

    console.log("taxitem", taxitem);
    
    const tax_id = taxitem.tax_id;
   
    let womandata = false;
    if (woman == true) {
      womandata = false;
    } else {
      womandata = true;
    }
    const userupdate = await update_userwoman({tax_id, womandata})


    setRefresh(refresh => refresh + 1);
  }
  const handlesingle = async() => {

    setSingle(!single);

    const username = user.name;
    const phonedata = user.phone;

    const taxitem = await get_tax({ username, phonedata });

    const tax_id = taxitem.tax_id;
        
    let singledata = false;
    
    if (single == true) {
      singledata = false;
    } else {
      singledata = true;
    }
    const userupdate = await update_usersingle({tax_id, singledata})

    setRefresh((refresh) => refresh + 1);
  }
  useEffect(() => {
    setWoman(woman);
    setSingle(single);
    setEtchumanitems(etchumanitems);
  },[refresh])

  return (
    <Container
      style={containerStyle}
      onClick={_handlecallback}
      bgcolor={bgcolor}
    >
      <LabelItem>
        <div style={{ paddingLeft: 5 }}>{label}</div>
        {/* <div>{content}</div> */}
        <Button
          icon={"useradd"}
          buttonText={"대상추가"}
          callback={handleAdd}
          containerStyle={{
            margin: "10px",
            width: "100px",
            height: 30,
            fontSize: 14,
            fontWeight: 300,
            backgroundColor: "#f85b2d",
            color: "#fff",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "space-around",
          }}
        />
      </LabelItem>

      <Info
        containerStyle={{ padding: 10 }}
        content={
          "인적공제 대상자를 직접 추가 해주세요 대상추가버튼을 클릭해 주세요"
        }
      />

      <Content>
        {etchumanitems.map((data, index) => (
          <table style={{ marginTop: 5, fontSize: 15, width: "90%" }}>
            <thead>
              <tr>
                <th scope="col">구분</th>
                <th scope="col">내용</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>삭제</td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      buttonText={"삭제"}
                      callback={() => {
                        handleDelete(data);
                      }}
                      containerStyle={{
                        border: "1px solid #ededed",
                        margin: "10px",
                        width: "50%",
                        height: 25,
                        fontSize: 12,
                        fontWeight: 300,
                        backgroundColor: "#3268f3",
                        color: "#fff",
                        borderRadius: "5px",
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>이름</td>
                <td>{data.name}</td>
              </tr>
              <tr>
                <td>주민번호</td>
                <td>{JuminConvert(data.jumin)}</td>
              </tr>
              <tr>
                <td>관계</td>
                <td>{data.relation}</td>
              </tr>
              <tr>
                <td>장애여부</td>
                <td>{data.disable}</td>
              </tr>
            </tbody>
          </table>
        ))}

        <Note>
          * 공제대상 부양가족은 연간 소득 100만원 이하의 1963.12.31이전 출생
          양가부모(동거무관) 및 2003.01.01 이후 출생 비속과 형제
        </Note>

        <div style={{ marginTop: 20, width: "100%" }}>
          <InfoData>
            배우자 있는 여성 또는 부양가족 있는 여성 세대주로 연소득 3천만원
            이하인경우는 부녀자 공제 선택함를 클릭해주세요
          </InfoData>

          <ButtonLayer>
        
            <Button
              buttonText={"부녀자공제 선택안함"}
              callback={handlewoman}
              containerStyle={{
                border: "1px solid #ededed",
                backgroundColor:
                  woman == true ? "#f0f0f0" : colors.mainbuttoncolor,
                color: woman == true ? "#000" : "#fff",
                marginLeft: "15px",
                width: "140px",
                height: 35,
                fontSize: 13,
                fontWeight: 300,
                borderRadius: "5px",
              }}
            />

            <Button
              buttonText={"부녀자공제 선택함"}
              callback={handlewoman}
              containerStyle={{
                border: "1px solid #ededed",
                backgroundColor:
                  woman == true ? colors.mainbuttoncolor : "#f0f0f0",
                color: woman == true ? "#fff" : "#000",
                marginLeft: "15px",
                width: "140px",
                height: 35,
                fontSize: 13,
                fontWeight: 300,
                borderRadius: "5px",
              }}
            />
          </ButtonLayer>
        </div>

        <div style={{ marginTop: 20, width: "100%" }}>
          <InfoData>
            배우자 없고 직계비속만 있는분은 한부모 공제 선택함를 클릭해주세요
          </InfoData>
          <ButtonLayer>

            <Button
              buttonText={"한부모공제 선택안함"}
              callback={handlesingle}
              containerStyle={{
                border: "1px solid #ededed",
                backgroundColor:
                  single == true ? "#f0f0f0" : colors.mainbuttoncolor,
                color: single == true ? "#000" : "#fff",
                marginLeft: "15px",
                width: "140px",
                height: 35,
                fontSize: 13,
                fontWeight: 300,
                borderRadius: "5px",
              }}
            />
            <Button
              buttonText={"한부모공제 선택함"}
              callback={handlesingle}
              containerStyle={{
                border: "1px solid #ededed",
                backgroundColor:
                  single == true ? colors.mainbuttoncolor : "#f0f0f0",
                color: single == true ? "#fff" : "#000",
                marginLeft: "15px",
                width: "140px",
                height: 35,
                fontSize: 13,
                fontWeight: 300,
                borderRadius: "5px",
              }}
            />
        
          </ButtonLayer>
        </div>
      </Content>

      <LineControl />
    </Container>
  );
};

HumanItem.defaultProps = {
  bgcolor: "#FFF",
};


export default HumanItem;
