import React,{useState, useEffect} from 'react';
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, Link, useNavigate} from "react-router-dom";
import styled from 'styled-components';
import { imageDB } from '../utility/imageData';
import { LoginType } from '../utility/contentDefine';
import { Mobile, PC } from './Responsive';

const PCContainer = styled.div`

`;
const Container = styled.div`

`;

const Layer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  box-shadow: 2px 3px 4px #e6dfdf;
`;

const LayerM = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  box-shadow: 2px 3px 4px #e6dfdf;
`;


const Layercontent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: self-start;
  font-size:14px;
`;

const Point = styled.div`
    position: absolute;
    top: 70px;
    left: 300px;
    z-index :10;
`;

const PointM = styled.div`
  position: absolute;
  top: 60px;
  left: 250px;
`;

const AuthAlert = ({containerStyle,logintype, agency}) => {

  const navigate = useNavigate();
   useEffect(()=>{
    async function fetchData(){
		}
		fetchData();
  }, [])



  return (
    <>
      <PC>
        <PCContainer style={containerStyle}>
          <Layer>
            <div>
              {logintype == LoginType.kakao && (
                <img src={imageDB.kakaoauth} style={{ width: 40 }} />
              )}
              {logintype == LoginType.samsung && (
                <img src={imageDB.samsungauth} style={{ width: 40 }} />
              )}
              {logintype == LoginType.pass && (
                <img src={imageDB.passauth} style={{ width: 40 }} />
              )}
              {logintype == LoginType.payco && (
                <img src={imageDB.paycoauth} style={{ width: 40 }} />
              )}
              {logintype == LoginType.kb && (
                <img src={imageDB.kbauth} style={{ width: 40 }} />
              )}
            </div>
            <Layercontent>
              <div>인증요청이 도착하였습니다</div>
              <div>{logintype}에서 확인해주세요</div>
            </Layercontent>

            <div style={{ color: "#b0aeae" }}>지금</div>
            <Point className="blink">
              <img src={imageDB.point} style={{ width: 70 }} />
            </Point>
          </Layer>
        </PCContainer>
      </PC>
      <Mobile>
        <Container style={containerStyle}>
          <LayerM>
            <div>
              {logintype == LoginType.kakao && (
                <img src={imageDB.kakaoauth} style={{ width: 40 }} />
              )}
              {logintype == LoginType.samsung && (
                <img src={imageDB.samsungauth} style={{ width: 40 }} />
              )}
              {logintype == LoginType.pass && (
                <img src={imageDB.passauth} style={{ width: 40 }} />
              )}
              {logintype == LoginType.payco && (
                <img src={imageDB.paycoauth} style={{ width: 40 }} />
              )}
              {logintype == LoginType.kb && (
                <img src={imageDB.kbauth} style={{ width: 40 }} />
              )}
            </div>
            <Layercontent>
              <div>인증요청이 도착하였습니다</div>
              <div>{logintype} 에서 확인해주세요</div>
            </Layercontent>

            <div style={{ color: "#b0aeae" }}>지금</div>
            <PointM className="blink">
              <img src={imageDB.point} style={{ width: 70 }} />
            </PointM>
          </LayerM>
        </Container>
      </Mobile>
    </>
  );
}

AuthAlert.defaultProps = {
  FontValue: 25,
};



export default AuthAlert;
