import React, {
  useState,
  useEffect,
  useContext,
  useLayoutEffect,
  useRef,
} from "react";
import {
  HashRouter,
  Route,
  Redirect,
  BrowserRouter,
  Routes,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Swipe from "../common/Swipe";

import styled from "styled-components";
import Guide from "../components/Guide";
import Swipe2 from "../common/Swipe2";
import Button from "../common/Button";
import { colors, theme } from "../theme/theme";
import InputItem from "../components/InputItem";
import { imageDB } from "../utility/imageData";
import AcceptModalEx from "../components/AcceptModalEx";
import ContentItem from "../components/ContentItem";
import { Mobile, PC } from "../components/Responsive";
import NoticeItem from "../components/NoticeItem";

const Container = styled.div`
  padding-top: 50px;
  background : #fff;
`;

const PCContainer = styled.div`
width: 27%;
margin: 0px 37%;
  background: white;
  padding-top: 50px;
`;

const MainItem = styled.div`
  font-size: 20px;
  font-weight: 700;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  text-align :left;
  height: 50px;
  color: {({theme})=>theme.maintaxcolor}
`;

const DateItem = styled.div`
  font-size: 15px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: flex-end;
  text-align :left;
  height: 50px;
  color: {({theme})=>theme.maintaxcolor}
  padding-right:20px;
`;


const Newsdetailcontainer = () => {
  const navigate = useNavigate();

  


  return (
    <>
      <Mobile>
        <Container>
          <MainItem>{"종합소득세 신고기간입니다 "}</MainItem>
          <DateItem>{"2024년 3월 29일 09:12"}</DateItem>

          <div
            style={{
              background: "#f9f9f9",
              height: 500,
              paddingTop: 20,
              paddingLeft: 20,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            {"종합소득세 신고 기간입니다. 신고기간을 확인해주시기 바랍니다"}
          </div>
        </Container>
      </Mobile>
      <PC>
        <PCContainer>
          <MainItem>{"종합소득세 신고기간입니다 "}</MainItem>
          <DateItem>{"2024년 3월 29일 09:12"}</DateItem>

          <div
            style={{
              background: "#f9f9f9",
              height: 500,
              paddingTop: 20,
              paddingLeft: 20,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            {"종합소득세 신고 기간입니다. 신고기간을 확인해주시기 바랍니다"}
          </div>
        </PCContainer>
      </PC>
    </>
  );
};

export default Newsdetailcontainer;
