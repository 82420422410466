import React, {
  useState,
  useEffect,
  useContext,
  useLayoutEffect,
  useRef,
} from "react";
import {
  HashRouter,
  Route,
  Redirect,
  BrowserRouter,
  Routes,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Swipe from "../common/Swipe";

import styled from "styled-components";
import Guide from "../components/Guide";
import Swipe2 from "../common/Swipe2";
import Button from "../common/Button";
import { colors, theme } from "../theme/theme";
import InputItem from "../components/InputItem";
import { imageDB } from "../utility/imageData";
import AcceptModalEx from "../components/AcceptModalEx";
import ContentItem from "../components/ContentItem";
import { Mobile, PC } from "../components/Responsive";
import { UserContext } from "../context/User";
import { CommaFormatted, getDateFullTime } from "../utility/common";
import { get_tax, update_user3 } from "../service/UserService";
import InputItem2 from "../components/InputItem2";
import ResultItem from "../components/ResultItem";

const Container = styled.div`
  background : #fff;
  height:750px;
`;

const PCContainer = styled.div`
width: 27%;
margin: 0px 37%;
  background: white;
  padding-top: 80px;
`;

const AuthLayer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 30px;
  flex-wrap: wrap;
  padding: 0px 10%;
`;

const AuthButton = styled.div`
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  border: 1px solid #ededed;
  margin: 2px;
  background: #fafafa;
  border-radius: 10px;
`;
const Resultcontainer = ({name}) => {
  const navigate = useNavigate();

  const { user, dispatch2 } = useContext(UserContext);

  const [acceptstatus, setAcceptstatus] = useState(true);
  const [updatetime, setUpdatetime] = useState('');
  const [useritem, setUseritem] = useState({});

  const [hometaxid, setHometaxid] = useState('');
  const [hometaxpass, setHometaxpass] = useState("");
  const [wetaxid, setWetaxid] = useState("");
  const [wetaxpass, setWetaxpass] = useState("");

  const [refresh, setRefresh] = useState(1);
  const [taxitem, setTaxitem] = useState({});


  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  

  useEffect(() => {

  }, [refresh])
  
  useEffect(() => { 

    async function FetchData(){

      let phone_ = user.phone.replaceAll("-", "");
      const phonedata = phone_;
      const username = user.name;
      const taxitem = await get_tax({ username, phonedata });
      setTaxitem(taxitem);

    }

    FetchData();

  }, []);
  

  return (
    <>
      <Mobile>
        <Container>
          <Guide main1={name + "님 안녕하세요 "}></Guide>
          <ResultItem item={taxitem} />
        </Container>
      </Mobile>
      <PC>
        <PCContainer>
          <Guide main1={name + "님 안녕하세요 "}></Guide>
          <ResultItem item={taxitem} />
        </PCContainer>
      </PC>
    </>
  );
};

export default Resultcontainer;
