import React,{useState, useEffect} from 'react';
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, Link, useNavigate} from "react-router-dom";
import styled from 'styled-components';
import { Mobile, PC } from './Responsive';

const Container = styled.div`
  padding : 0px 10%;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  line-height: 2;
`;

const LabelItem = styled.div`
  font-weight: 400;
  line-height: 1.5;
  font-size: 0.75rem;
  text-decoration-line: none;
  color: rgb(120, 131, 145);
`;


const InputItem2 = ({containerStyle, label, placeholder, callback, type,item}) => {

  const [data, setData] = useState('');
  const [refresh, setRefresh] = useState(1);
  const [border, setBorder] = useState(false);
  const navigate = useNavigate();
   useEffect(()=>{
    async function fetchData(){
		}
		fetchData();
  }, [])


  const handleFocusBorder = () => {
    setBorder(true);
  }
  const handleIputValidate = () => {

  };

  const setCallback = (_data) => {
     callback(_data);
  }
  useEffect(() => {
    setData(data);
    setBorder(border);
    setCallback(data);
  },[refresh])

  return (
    <Container style={containerStyle}>
      <LabelItem>{label}</LabelItem>
      <div>
        <Mobile><input
          type={type}
          value={item}
          onChange={(e) => {
            setData(e.target.value);
            setRefresh((refresh) => refresh + 1);
          
          }}
          style={{width:250}}
          className="inputcommon"
          placeholder={placeholder}
          onFocus={() => handleFocusBorder()}
          onBlur={() => handleIputValidate()}
        ></input></Mobile>
        <PC>
          <input
            type={type}
            value={item}
            onChange={(e) => {
              setData(e.target.value);
              setRefresh((refresh) => refresh + 1);
            }}
            className="inputcommon"
            placeholder={placeholder}
            onFocus={() => handleFocusBorder()}
            onBlur={() => handleIputValidate()}
          ></input>
        </PC>

        <div className="inputNoFocus" />

   
      </div>
    </Container>
  );
}
InputItem2.defaultProps = {
  type: "text",
};

export default InputItem2;
