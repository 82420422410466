import React,{useState, useEffect} from 'react';
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, Link, useNavigate} from "react-router-dom";
import styled from 'styled-components';

const Container = styled.div`
  padding : 0px 5%;

`
const MainItem = styled.div`
  font-size: 22px;
  font-weight: 700;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: flex-start;
  text-align :left;
  height: ${({height}) => height}px;
  color: #000;
  font-family:Pretendard-Bold;
`;

const SubItem = styled.div`
  color: #788391;
  font-size: 16px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: flex-start;
  margin-top:10px;
  text-align : left;
`;

const Guide = ({containerStyle, main1, main2, sub, FontValue,height}) => {

  const navigate = useNavigate();
   useEffect(()=>{
    async function fetchData(){
		}
		fetchData();
  }, [])



  return (
    <Container style={containerStyle} height={height}>
      <MainItem FontValue={FontValue}>
        <div>{main1}</div>
        <div>{main2}</div>
      </MainItem>

      <SubItem>{sub}</SubItem>
    </Container>
  );
}

Guide.defaultProps = {
  FontValue: 25,
  height : 70
};



export default Guide;
