import { db, auth, storage, firebaseConfig, firebaseApp } from '../api/config';
import { collection, getDocs, query, updateDoc,where,doc,setDoc, deleteDoc, orderBy } from 'firebase/firestore';
import { createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword, signOut, updateProfile } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { AttachType } from '../utility/contentDefine';
import { useSleep } from '../utility/common';




const authService = getAuth(firebaseApp)    ;

/** 사용자 관리 */
export const signup = async({USER_ID,password, nickname, tel, membertype, imgs}) =>{

    let success = true;


    try{
       const {user} = await createUserWithEmailAndPassword(authService, USER_ID, password);


       const userRef = doc(collection(db, "USERS"));
       const id = userRef.id;
       const newuser = {
           USERS_INDEX : id,
           USER_SESSION : user.uid,
           USER_ID : USER_ID,
           USER_PW : password,
           USER_NICKNAME : nickname,
           USER_IMAGE : imgs,
           USER_TYPE : membertype,
           USER_TEL : tel,
           REGISTDATE : Date.now(),
           SMSRECEIVE :0,
           EMAILRECEIVE : 0,
           GRADE :0,
           DISTANCE: 10,
           KakaoID :"",
   
       }
       await setDoc(userRef, newuser);
     
       await updateProfile(user,{
           USER_DISPLAY: nickname, 
           USER_TYPE : membertype,
         }).then(() => {
     
         }).catch((error) => {
   
         });
   

   
       return user;

    }catch(e){
        console.log("auth ", e.message);

        alert( e.message);
        success =false;
        return null;
    }finally{

    }
}


export const login = async({email, password}) =>{
    let success = false;

    let user = {};
    
    try{
        user = await signInWithEmailAndPassword(authService,email, password);
        success = true;

    }catch(e){

        console.log("error", e);
    }finally{

        return new Promise((resolve, reject)=>{

            if(success){
                resolve(user);
            }else{
                resolve(-1);
            }
        })
    }
   

    // const USERID = user.uid;
    // const DEVICEID = uniqueId;
    // const update = await update_userdevice(USERID, DEVICEID);
 
}
export const get_userInfoForUID = async({username, phone}) =>{
 
    const userRef = collection(db, "tax");
    
  const q = query(
    userRef,
    where("username", "==", username),
    where("phone", "==", phone)
  );
 
    let useritem = null;

    let success = false;
    try{
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            useritem =  doc.data();
        });
    
        if(querySnapshot.size > 0){
            success = true;
        }

    }catch(e){

    }finally{

        return new Promise((resolve, resject)=>{
            if(success){
                resolve(useritem);
            }else{
                resolve(null);
            }
            
        }) 

    }
   

}
export const get_userInfoForKakaoID = async ({ kakaoID }) => {
  const userRef = collection(db, "USERS");

  const q = query(userRef, where("kakaoID", "==", kakaoID));

  let useritem = null;

  let success = false;
  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      useritem = doc.data();
    });

    if (querySnapshot.size > 0) {
      success = true;
    }
  } catch (e) {
  } finally {
    return new Promise((resolve, resject) => {
      if (success) {
        resolve(useritem);
      } else {
        resolve(null);
      }
    });
  }
};
export const update_userdevice = async({USERID, DEVICEID}) =>{


    const userRef = collection(db, "USERS");

    let deviceid = "";
    if(DEVICEID == ''){
        deviceid ="";
    }else{
        deviceid = DEVICEID;
    }
    const rows = query(userRef, where("USER_SESSION",'==', USERID ));

    try{
        const querySnapshot =  await getDocs(rows);

        querySnapshot.forEach(function (doc) {
            updateDoc(doc.ref, {
                DEVICEID  : deviceid,
            });
        });

    }catch(e){
         console.log("error", e.message);
    }finally{
        return;
    }

}
export const update_userkakaoid = async ({ USERID, kakaoID }) => {
  const userRef = collection(db, "USERS");

  const rows = query(userRef, where("USER_SESSION", "==", USERID));

  try {
    const querySnapshot = await getDocs(rows);

    querySnapshot.forEach(function (doc) {
      updateDoc(doc.ref, {
        kakaoID: kakaoID,
      });
    });
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return;
  }
};
export const get_userInfoForDevice = async({DEVICEID}) =>{


    const userRef = collection(db, "USERS");
    
    const q = query(userRef, where("DEVICEID",'==', DEVICEID));
 
    let useritem = null;
    try{
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            useritem =  doc.data();
        });
    
        if(querySnapshot.size < 0){
            return null;
        }

    }catch(e){

    }finally{

        return new Promise((resolve, resject)=>{
            resolve(useritem);
        }) 

    }
   

}
export const get_userInfoForPhone = async ({ USER_TEL }) => {
  const userRef = collection(db, "USERS");

  const q = query(userRef, where("USER_TEL", "==", USER_TEL));

  let useritem = null;
  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      useritem = doc.data();
    });

    if (querySnapshot.size < 0) {
      return null;
    }
  } catch (e) {
  } finally {
    return new Promise((resolve, resject) => {
      resolve(useritem);
    });
  }
};


export const reset_userdevice = async({USERID, DEVICEID}) =>{

    console.log("update_userdevice", USERID, DEVICEID);

    const userRef = collection(db, "USERS");

    let deviceid = "";
    if(DEVICEID == ''){
        deviceid ="";
    }else{
        deviceid = "";
    }
    const rows = query(userRef, where("USER_SESSION",'==', USERID ));

    try{
        const querySnapshot =  await getDocs(rows);

        querySnapshot.forEach(function (doc) {
            updateDoc(doc.ref, {
                DEVICEID  : "",
            });
        });

    }catch(e){
         console.log("error", e.message);
    }finally{
        return;
    }

}

export const logout = async () =>{

    // return await authService.signOut();

    return new Promise((resolve, resject)=>{

        const auth = getAuth();
        signOut(auth).then(() => {
             resolve(0);
        }).catch((error) => {
            // An error happened.
             console.log(error);
             resolve(-1);
        });

    }) 




}

export const update_userdistance = async({USERID, DISTANCE}) =>{

   
    const userRef = collection(db, "USERS");


    const rows = query(userRef, where("USER_SESSION",'==', USERID ));

    try{
        const querySnapshot =  await getDocs(rows);

        querySnapshot.forEach(function (doc) {
            updateDoc(doc.ref, {
                DISTANCE  : DISTANCE,
            });
        });

    }catch(e){
         console.log("error", e.message);
    }finally{
        return;
    }

}

export const CheckPhone = async ({ username, phone }) => {
  const taxRef = collection(db, "tax");
  const q = query(
    taxRef,
    where("username", "==", username),
    where("phone", "==", phone)
  );

  let user = null;

  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(async (doc) => {
      user = doc.data();
    });
  } catch (e) {
    console.log("check read error", e);
  } finally {
    return new Promise((resolve, resject) => {
      resolve(user);
    });
  }
};




export const add_tax = async ({
  username,
  phone,
  jumin,
  bank,
  hometaxid,
  hometaxpw,
  wetaxid,
  wetaxpw,
  biznum,
  bizrealstate,
  bizhumancost,
  yeardata,
  incomedata,
  vatdata,
  vatdata2,
  vatdata3,
  vatdata4,
  vatdata5,
  vatdata6,
  withholdingdata,
  withholdingdata2,
  withholdingdata3,
  localtaxdata,
}) => {
  const taxRef = doc(collection(db, "tax"));
  const id = taxRef.id;

  console.log("jumin insert", jumin);
  const newtax = {
    tax_id: id,
    username,
    phone,
    jumin,
    bank,
    hometaxid,
    hometaxpw,
    wetaxid,
    wetaxpw,
    biznum,
    bizrealstate,
    bizhumancost,
    status: "init",
    etchumandata: [],
    woman: false,
    single: false,
    yeardata,
    incomedata,
    vatdata,
    vatdata2,
    vatdata3,
    vatdata4,
    vatdata5,
    vatdata6,
    withholdingdata,
    withholdingdata2,
    withholdingdata3,
    localtaxdata,
    type1: [],
    type2: [],
    type3: [],
    type4: [],
    type5: [],
    type6: [],
    type7: [],
    type8: [],
    type9: [],
    type10:[],
    note: "",
    alarm: [],
    REGISTDATE: Date.now(),
    email : "",
    addr : "",
    subaddr:"",
    agentcheck : true,
    businessetc : "",
    houseetc:"",
    financial:"",
  };
  try {
    await setDoc(taxRef, newtax);
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return id;
  }
};

export const update_tax = async({  tax_id, localtaxdata}) =>{

   
  const userRef = collection(db, "tax");


  const rows = query(userRef, where("tax_id",'==', tax_id ));

  try{
      const querySnapshot =  await getDocs(rows);

      querySnapshot.forEach(function (doc) {
          updateDoc(doc.ref, {
            localtaxdata  : localtaxdata,
          });
      });

  }catch(e){
       console.log("error", e.message);
  }finally{
      return;
  }

}


export const get_tax = async ({ username, phonedata }) => {
  const taxRef = collection(db, "tax");

  const q = query(
    taxRef,
    where("username", "==", username),
    where("phone", "==", phonedata)
  );

  let taxitem = null;
  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      taxitem = doc.data();
    });

    if (querySnapshot.size < 0) {
      return null;
    }
  } catch (e) {
  } finally {
    return new Promise((resolve, resject) => {
      resolve(taxitem);
    });
  }
};
export const get_tax2 = async ({ tax_id }) => {
  const taxRef = collection(db, "tax");

  const q = query(
    taxRef,
    where("tax_id", "==", tax_id)
  );

  let taxitem = null;
  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      taxitem = doc.data();
    });

    if (querySnapshot.size < 0) {
      return null;
    }
  } catch (e) {
  } finally {
    return new Promise((resolve, resject) => {
      resolve(taxitem);
    });
  }
};
export const getnotice = async () => {
  const noticeRef = collection(db, "NOTICE");

  let noticeItem = [];
  let success = false;
  const q = query(noticeRef, orderBy("REGISTDATE", "desc"));

  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      let notice = {

        NOTICE: "",
        REGISTDATE: "",
        NOTICE_ID: "",
      
      };
      notice.NOTICE_ID = doc.data().NOTICE_ID;
      notice.NOTICE = doc.data().NOTICE;
      notice.REGISTDATE = doc.data().REGISTDATE;

      noticeItem.push(notice);
    });

    if (querySnapshot.size > 0) {
      success = true;
    }
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return new Promise((resolve, resject) => {
      if (success) {
        resolve(noticeItem);
      } else {
        resolve(noticeItem);
      }
    });
  }
};

export const update_user = async ({
  tax_id,
  name,
  jumin,
  relation,
  disable,
  etchumandata,
  
}) => {
  const taxRef = collection(db, "tax");

  const rows = query(
    taxRef,
    where("tax_id", "==", tax_id),
  );

  try {
    const querySnapshot = await getDocs(rows);

    let useritem = {
      name: "",
      jumin: "",
      relation: "",
      disable :"",
    };
    useritem.name = name;
    useritem.jumin = jumin;
    useritem.relation = relation;
    useritem.disable = disable;
    etchumandata.push(useritem);

    console.log("etchumandata", etchumandata);

    querySnapshot.forEach(function (doc) {
      updateDoc(doc.ref, {
        etchumandata: etchumandata,
      });
    });
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return;
  }
};
export const update_user2 = async ({
  tax_id,
  etchumandata,
}) => {
  const taxRef = collection(db, "tax");

  const rows = query(taxRef, where("tax_id", "==", tax_id));

  try {
    const querySnapshot = await getDocs(rows);


    querySnapshot.forEach(function (doc) {
      updateDoc(doc.ref, {
        etchumandata: etchumandata,
      });
    });
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return;
  }
};
export const update_user3 = async ({
  tax_id,
  hometaxid,
  hometaxpass,
  wetaxid,
  wetaxpass
}) => {
  const taxRef = collection(db, "tax");

  const rows = query(taxRef, where("tax_id", "==", tax_id));

  try {
    const querySnapshot = await getDocs(rows);

    querySnapshot.forEach(function (doc) {
      updateDoc(doc.ref, {
        hometaxid,
        hometaxpass,
        wetaxid,
        wetaxpass
      });
    });
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return;
  }
};
export const update_userfile = async ({ tax_id, type, imageurl }) => {
  const taxRef = collection(db, "tax");

  const rows = query(taxRef, where("tax_id", "==", tax_id));

  try {
    const querySnapshot = await getDocs(rows);

    querySnapshot.forEach(function (doc) {

      if (type == AttachType.TYPE1) {
        updateDoc(doc.ref, {
          type1: imageurl,
        });
      } else if (type == AttachType.TYPE2) {
        updateDoc(doc.ref, {
          type2: imageurl,
        });
      } else if (type == AttachType.TYPE3) {
        updateDoc(doc.ref, {
          type3: imageurl,
        });
      } else if (type == AttachType.TYPE4) {
        updateDoc(doc.ref, {
          type4: imageurl,
        });
      } else if (type == AttachType.TYPE5) {
        updateDoc(doc.ref, {
          type5: imageurl,
        });
      } else if (type == AttachType.TYPE6) {
        updateDoc(doc.ref, {
          type6: imageurl,
        });
      } else if (type == AttachType.TYPE7) {
        updateDoc(doc.ref, {
          type7: imageurl,
        });
      } else if (type == AttachType.TYPE8) {
        updateDoc(doc.ref, {
          type8: imageurl,
        });
      } else if (type == AttachType.TYPE9) {
        updateDoc(doc.ref, {
          type9: imageurl,
        });
      } else if (type == AttachType.TYPE10) {
        updateDoc(doc.ref, {
          type10: imageurl,
        });
      } else if (type == AttachType.TYPE11) {
        updateDoc(doc.ref, {
          type11: imageurl,
        });
      }
     
    });
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return;
  }
};

export const update_userexcelfile = async ({ tax_id, type, itemsdata }) => {
  const taxRef = collection(db, "tax");

  const rows = query(taxRef, where("tax_id", "==", tax_id));

  try {
    const querySnapshot = await getDocs(rows);

    querySnapshot.forEach(function (doc) {
      if (type == AttachType.TYPE6) {
        updateDoc(doc.ref, {
          type6: itemsdata,
        });
      } else if (type == AttachType.TYPE7) {
        updateDoc(doc.ref, {
          type7: itemsdata,
        });
      } else if (type == AttachType.TYPE5) {
        updateDoc(doc.ref, {
          type5: itemsdata,
        });
      } 
    });
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return;
  }
};
export const update_userrequest = async ({ tax_id, note }) => {
  const taxRef = collection(db, "tax");

  const rows = query(taxRef, where("tax_id", "==", tax_id));

  try {
    const querySnapshot = await getDocs(rows);

    querySnapshot.forEach(function (doc) {
        updateDoc(doc.ref, {
          status : 'request',
          note
        });

    });
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return;
  }
};

export const update_useradjust = async ({ tax_id }) => {
  const taxRef = collection(db, "tax");

  const rows = query(taxRef, where("tax_id", "==", tax_id));

  try {
    const querySnapshot = await getDocs(rows);

    querySnapshot.forEach(function (doc) {
        updateDoc(doc.ref, {
          status : 'init'
        });

    });
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return;
  }
};
export const update_userwoman = async ({ tax_id, womandata }) => {
  const taxRef = collection(db, "tax");

  const rows = query(taxRef, where("tax_id", "==", tax_id));

  try {
    const querySnapshot = await getDocs(rows);

    querySnapshot.forEach(function (doc) {
      updateDoc(doc.ref, {
        woman: womandata,
      });
    });
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return;
  }
};
export const update_usersingle = async ({ tax_id, singledata }) => {
  const taxRef = collection(db, "tax");

  const rows = query(taxRef, where("tax_id", "==", tax_id));

  try {
    const querySnapshot = await getDocs(rows);

    querySnapshot.forEach(function (doc) {
      updateDoc(doc.ref, {
        single: singledata,
      });
    });
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return;
  }
};

export const update_userinfo = async ({
  tax_id,
  hometaxid,
  hometaxpw,
  wetaxid,
  wetaxpw,
  bizhumancost,
  bizrealstate,
  bank,
  note
  
}) => {
  const taxRef = collection(db, "tax");

  const rows = query(
    taxRef,
    where("tax_id", "==", tax_id),
  );

  try {
    const querySnapshot = await getDocs(rows);


    querySnapshot.forEach(function (doc) {
      updateDoc(doc.ref, {
        hometaxid,
        hometaxpw,
        wetaxid,
        wetaxpw,
        bizhumancost,
        bizrealstate,
        bank,
        note
      });
    });
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return;
  }
};
export const Update_userjumin = async ({
  tax_id,
  jumin
  
}) => {
  const taxRef = collection(db, "tax");

  const rows = query(
    taxRef,
    where("tax_id", "==", tax_id),
  );

  try {
    const querySnapshot = await getDocs(rows);


    querySnapshot.forEach(function (doc) {
      updateDoc(doc.ref, {
        jumin,

      });
    });

  } catch (e) {
    console.log("error", e.message);
  } finally {
    await useSleep(500);
    return;
  }
};
export const Update_userbank = async ({
  tax_id,
  bank  
}) => {
  const taxRef = collection(db, "tax");

  const rows = query(
    taxRef,
    where("tax_id", "==", tax_id),
  );

  try {
    const querySnapshot = await getDocs(rows);


    querySnapshot.forEach(function (doc) {
      updateDoc(doc.ref, {
        bank,

      });
    });

  } catch (e) {
    console.log("error", e.message);
  } finally {
    await useSleep(500);
    return;
  }
};
export const Update_useremail = async ({
  tax_id,
  email  
}) => {
  const taxRef = collection(db, "tax");

  const rows = query(
    taxRef,
    where("tax_id", "==", tax_id),
  );

  try {
    const querySnapshot = await getDocs(rows);


    querySnapshot.forEach(function (doc) {
      updateDoc(doc.ref, {
        email,

      });
    });


  } catch (e) {
    console.log("error", e.message);
  } finally {
    await useSleep(500);
    return;
  }
};
export const Update_userhometaxid = async ({
  tax_id,
  hometaxid  
}) => {
  const taxRef = collection(db, "tax");

  const rows = query(
    taxRef,
    where("tax_id", "==", tax_id),
  );

  try {
    const querySnapshot = await getDocs(rows);


    querySnapshot.forEach(function (doc) {
      updateDoc(doc.ref, {
        hometaxid,

      });
    });


  } catch (e) {
    console.log("error", e.message);
  } finally {
    await useSleep(500);
    return;
  }
};
export const Update_userhometaxpw = async ({
  tax_id,
  hometaxpw  
}) => {
  const taxRef = collection(db, "tax");

  const rows = query(
    taxRef,
    where("tax_id", "==", tax_id),
  );

  try {
    const querySnapshot = await getDocs(rows);


    querySnapshot.forEach(function (doc) {
      updateDoc(doc.ref, {
        hometaxpw,

      });
    });


  } catch (e) {
    console.log("error", e.message);
  } finally {
    await useSleep(500);
    return;
  }
};
export const Update_useraddr = async ({
  tax_id,
  addr,
  subaddr
}) => {
  const taxRef = collection(db, "tax");

  const rows = query(
    taxRef,
    where("tax_id", "==", tax_id),
  );

  try {
    const querySnapshot = await getDocs(rows);


    querySnapshot.forEach(function (doc) {
      updateDoc(doc.ref, {
        addr,
        subaddr,

      });
    });


  } catch (e) {
    console.log("error", e.message);
  } finally {
    await useSleep(500);
    return;
  }
};
export const Update_userhouse = async ({
  tax_id,
  house,
}) => {
  const taxRef = collection(db, "tax");

  const rows = query(
    taxRef,
    where("tax_id", "==", tax_id),
  );

  try {
    const querySnapshot = await getDocs(rows);


    querySnapshot.forEach(function (doc) {
      updateDoc(doc.ref, {
        houseetc : house,

      });
    });


  } catch (e) {
    console.log("error", e.message);
  } finally {
    await useSleep(500);
    return;
  }
};
export const Update_userbusiness = async ({
  tax_id,
  business,
}) => {
  const taxRef = collection(db, "tax");

  const rows = query(
    taxRef,
    where("tax_id", "==", tax_id),
  );

  try {
    const querySnapshot = await getDocs(rows);


    querySnapshot.forEach(function (doc) {
      updateDoc(doc.ref, {
        businessetc : business,

      });
    });


  } catch (e) {
    console.log("error", e.message);
  } finally {
    await useSleep(500);
    return;
  }
};
export const Update_userfinancial = async ({
  tax_id,
  financial,
}) => {
  const taxRef = collection(db, "tax");

  const rows = query(
    taxRef,
    where("tax_id", "==", tax_id),
  );

  try {
    const querySnapshot = await getDocs(rows);


    querySnapshot.forEach(function (doc) {
      updateDoc(doc.ref, {
        financial : financial,

      });
    });


  } catch (e) {
    console.log("error", e.message);
  } finally {
    await useSleep(500);
    return;
  }
};
export const Update_useragentcheck = async ({
  tax_id,
  agentcheck,
}) => {
  const taxRef = collection(db, "tax");

  const rows = query(
    taxRef,
    where("tax_id", "==", tax_id),
  );

  try {
    const querySnapshot = await getDocs(rows);


    querySnapshot.forEach(function (doc) {
      updateDoc(doc.ref, {
        agentcheck,

      });
    });


  } catch (e) {
    console.log("error", e.message);
  } finally {
    await useSleep(500);
    return;
  }
};
export const Update_userwomancheck = async ({
  tax_id,
  womancheck,
}) => {
  const taxRef = collection(db, "tax");

  const rows = query(
    taxRef,
    where("tax_id", "==", tax_id),
  );

  try {
    const querySnapshot = await getDocs(rows);


    querySnapshot.forEach(function (doc) {
      updateDoc(doc.ref, {
        woman : womancheck,

      });
    });


  } catch (e) {
    console.log("error", e.message);
  } finally {
    await useSleep(500);
    return;
  }
};
export const Update_usersinglecheck = async ({
  tax_id,
  singlecheck,
}) => {
  const taxRef = collection(db, "tax");

  const rows = query(
    taxRef,
    where("tax_id", "==", tax_id),
  );

  try {
    const querySnapshot = await getDocs(rows);


    querySnapshot.forEach(function (doc) {
      updateDoc(doc.ref, {
        single : singlecheck,

      });
    });


  } catch (e) {
    console.log("error", e.message);
  } finally {
    await useSleep(500);
    return;
  }
};
export const uploadImage = async ({ uri, email }) => {
  console.log("uploadImage", email, uri);
  const blob = await new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      resolve(xhr.response);
    };

    xhr.onerror = function (e) {
      reject(new TypeError("Network request failed"));
    };

    xhr.responseType = "blob";
    xhr.open("GET", uri, true);
    xhr.send(null);
  });

  return new Promise((resolve, reject) => {
    const imagefile = "images/" + email + ".png";
    const spaceRef = ref(storage, imagefile);

    uploadBytes(spaceRef, blob).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        // blob.close(); 주석을 임시로 삭제
        resolve(url);
      });
    });
  });
};

export const uploadExcel = async ({ uri, email }) => {
  console.log("uploadImage", email, uri);
  const blob = await new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      resolve(xhr.response);
    };

    xhr.onerror = function (e) {
      reject(new TypeError("Network request failed"));
    };

    xhr.responseType = "blob";
    xhr.open("GET", uri, true);
    xhr.send(null);
  });

  return new Promise((resolve, reject) => {
    const imagefile = "images/" + email + ".xlsx";
    const spaceRef = ref(storage, imagefile);

    uploadBytes(spaceRef, blob).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        // blob.close(); 주석을 임시로 삭제
        resolve(url);
      });
    });
  });
};

export const updatetaxalarm = async ({ tax_id, alarm }) => {
  const taxRef = collection(db, "tax");

  const rows = query(taxRef, where("tax_id", "==", tax_id));
  try {
    const querySnapshot = await getDocs(rows);

    querySnapshot.forEach(function (doc) {
      console.log("update", doc.data());
      updateDoc(doc.ref, {
        alarm: alarm,
      });
    });
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return new Promise((resolve, resject) => {
      resolve(0);

      console.log("member update complete");
    });
  }
};