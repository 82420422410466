import React,{useState, useEffect} from 'react';
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, Link, useNavigate} from "react-router-dom";
import styled from 'styled-components';
import Button from '../common/Button';
import { colors } from '../theme/theme';
import Text from '../common/Text';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;



const RelationData = ["부녀자공제", "한부모공제"];
const Relationbtn = ({containerStyle, callback}) => {

  const [refresh, setRefresh] = useState(1);
  
  const navigate = useNavigate();
   useEffect(()=>{
    async function fetchData(){
		}
		fetchData();
  }, [])

  const [checkitems, setCheckitems] = React.useState([0, 0, 0,0]);

  const handleCheck = (index) => {

    console.log("handleCheck", index)

    checkitems.map((data, index) => {
      checkitems[index] = 0;
    })
    if (checkitems[index] == 0) {
      checkitems[index] = 1;
    } else {
      checkitems[index] = 0;
    }
    setCheckitems(checkitems);

    setRefresh((refresh) => refresh + 1);
  };

  useEffect(() => {
    setCheckitems(checkitems);
  }, [refresh]);

  const handleno = () => {
   
 }
  

  return (
    <Container style={containerStyle}>
      {RelationData.map((data, index) => (
        <>
          {checkitems[index] == 0 ? (
            <Button
              buttonText={data}
              index={index}
              callback={handleCheck}
              containerStyle={{
                border: "1px solid #ededed",
                backgroundColor: "#f0f0f0",
                color: "#000",
                margin: "5px",
                width: "80px",
                height: 30,
                fontSize: 14,
                fontWeight: 300,
                borderRadius: "10px",
              }}
            />
          ) : (
            <Button
              buttonText={data}
              index={index}
              callback={handleCheck}
              containerStyle={{
                border: "1px solid #ededed",
                backgroundColor: colors.mainbuttoncolor,
                color: "#fff",
                margin: "5px",
                width: "80px",
                height: 30,
                fontSize: 14,
                fontWeight: 300,
                borderRadius: "10px",
              }}
            />
          )}
        </>
      ))}
    </Container>
  );
}

export default Relationbtn;
