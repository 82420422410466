import React, {
  useState,
  useEffect,
  useContext,
  useLayoutEffect,
  useRef,
} from "react";
import {
  HashRouter,
  Route,
  Redirect,
  BrowserRouter,
  Routes,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Swipe from "../common/Swipe";

import styled from "styled-components";

import Guide from "../components/Guide";
import Swipe2 from "../common/Swipe2";
import Button from "../common/Button";
import { colors, theme } from "../theme/theme";
import InputItem from "../components/InputItem";
import InputReturn from "../components/InputReturn";
import Next from "../components/Next";
import { Mobile, PC } from "../components/Responsive";
import useDimension from "../common/UseDimension";
import SelectItem from "../components/SelectItem";
import { UserContext } from "../context/User";
import { get_userInfoForUID } from "../service/UserService";
import { BiCalendarEdit } from "react-icons/bi";
import { FcInfo } from "react-icons/fc";
import { imageDB } from "../utility/imageData";
import Image from "../common/Image";

import { FaCheckCircle } from "react-icons/fa";
import { setRef } from "@mui/material";
import Info from "../common/Info";
import YearItem from "../components/YearItem";
import IncomeItem from "../components/IncomeItem";
import VatItem from "../components/VatItem";
import { BizConvert, IsEmpty } from "../utility/common";
import WidthhodlingItem from "../components/WithholdingItem";
import LocalTaxItem from "../components/LocalTaxItem";

const Container = styled.div`
  padding: 80px 20px;
  background: white;

`;
 const PCContainer = styled.div`
 width: 27%;
 margin: 0px 37%;
   background: white;
   padding-top: 80px;
   height: 100%;

   border: 1px solid;
 `;
const LabelItem = styled.div`
  font-weight: 400;
  line-height: 1.5;
  font-size: 1.2rem;
  text-decoration-line: none;
  color: rgb(120, 131, 145);
  padding-left:10px;
`;

const Box = styled.div`
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;

  margin: 10px;
  border: ${({ enablebiz }) =>
    enablebiz == true ? "2px solid #3268f3;" : "1px solid #ededed;"};
`;

const DisableBox = styled.div`
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;

  margin: 10px;
  border: ${({ disablebiz }) =>
    disablebiz == true ? "2px solid #3268f3;" : "1px solid #ededed;"};
`;



 



const TableInfocontainer = ({data, type}) => {


  const navigate = useNavigate();
  
  const {user, dispatch2 } = useContext(UserContext);
  const [height, setHeight] = useState(window.innerHeight);
  const [refresh, setRefresh] = useState(1);

  

  console.log("year data", data);

  

  useEffect(() => {

  }, [refresh])
  

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  const _handleprev = () =>{
    navigate(-1);
  }


  
  return (
    <>
      <Mobile>
        <Container height={height}>
          {type == "year" && (
            <>
              <Guide
                main1={"홈텍스에서 가져온 연말정산 신고 내역입니다"}
              ></Guide>
              <YearItem
                label="연말정산"
                items={data}
                content={"연말정산 세금자료확인"}
              />
            </>
          )}

          {type == "income" && (
            <>
              <Guide main1={"홈텍스에서 가져온 소득 신고 내역입니다"}></Guide>
              <IncomeItem
                label="소득금엑"
                items={data}
                content={"소득금액 세금자료확인"}
              />
            </>
          )}

          {type == "vattax" && (
            <>
              <Guide
                main1={"홈텍스에서 가져온 부가가치세 신고 내역입니다"}
              ></Guide>

              {IsEmpty(data.vatdata) == false && (
                <VatItem
                  label="부가가치세"
                  item={data.vatdata}
                  content={"사업자번호 : " + BizConvert(data.biznum[0])}
                />
              )}
              {IsEmpty(data.vatdata4) == false && (
                <VatItem
                  label="부가가치세"
                  item={data.vatdata4}
                  content={"사업자번호 : " + BizConvert(data.biznum[0])}
                />
              )}
              {IsEmpty(data.vatdata2) == false && (
                <VatItem
                  label="부가가치세"
                  item={data.vatdata2}
                  content={"사업자번호 : " + BizConvert(data.biznum[1])}
                />
              )}
              {IsEmpty(data.vatdata5) == false && (
                <VatItem
                  label="부가가치세"
                  item={data.vatdata5}
                  content={"사업자번호 : " + BizConvert(data.biznum[1])}
                />
              )}
              {IsEmpty(data.vatdata3) == false && (
                <VatItem
                  label="부가가치세"
                  item={data.vatdata3}
                  content={"사업자번호 : " + BizConvert(data.biznum[2])}
                />
              )}
              {IsEmpty(data.vatdata6) == false && (
                <VatItem
                  label="부가가치세"
                  item={data.vatdata6}
                  content={"사업자번호 : " + BizConvert(data.biznum[2])}
                />
              )}
            </>
          )}

          {type == "withholdingtax" && (
            <>
              <Guide main1={"홈텍스에서 가져온 원천세 납부 내역입니다"}></Guide>
              <WidthhodlingItem
                label="원천세 납부내역"
                items={data}
                content={"원천세 납부 세금자료확인"}
              />
            </>
          )}

          {type == "localtax" && (
            <>
              <Guide main1={"홈텍스에서 가져온 지방세 납부 내역입니다"}></Guide>
              <LocalTaxItem
                label="지방세 납부내역"
                items={data}
                content={"지방세 납부 세금자료확인"}
              />
            </>
          )}

          <Button
            buttonText={"뒤로 가기"}
            callback={_handleprev}
            containerStyle={{
              backgroundColor: colors.mainbuttoncolor,
              color: "#fff",
              margin: "30px 10x",
              width: "95%",
              height: 50,
              fontSize: 16,
              fontWeight: 700,
              borderRadius: "10px",
              fontFamily :"Pretendard-Bold"
            }}
          />
        </Container>
      </Mobile>
      <PC>
        <PCContainer height={height}>
          {type == "year" && (
            <>
              <Guide
                main1={"홈텍스에서 가져온 연말정산 신고 내역입니다"}
              ></Guide>
              <YearItem
                label="연말정산"
                items={data}
                content={"연말정산 세금자료확인"}
              />
            </>
          )}

          {type == "income" && (
            <>
              <Guide main1={"홈텍스에서 가져온 소득 신고 내역입니다"}></Guide>
              <IncomeItem
                label="소득금엑"
                items={data}
                content={"소득금액 세금자료확인"}
              />
            </>
          )}

          {type == "vattax" && (
            <>
              <Guide
                main1={"홈텍스에서 가져온 부가가치세 신고 내역입니다"}
              ></Guide>

              {IsEmpty(data.vatdata) == false && (
                <VatItem
                  label="부가가치세"
                  item={data.vatdata}
                  content={"사업자번호 : " + BizConvert(data.biznum[0])}
                />
              )}
              {IsEmpty(data.vatdata4) == false && (
                <VatItem
                  label="부가가치세"
                  item={data.vatdata4}
                  content={"사업자번호 : " + BizConvert(data.biznum[0])}
                />
              )}
              {IsEmpty(data.vatdata2) == false && (
                <VatItem
                  label="부가가치세"
                  item={data.vatdata2}
                  content={"사업자번호 : " + BizConvert(data.biznum[1])}
                />
              )}
              {IsEmpty(data.vatdata5) == false && (
                <VatItem
                  label="부가가치세"
                  item={data.vatdata5}
                  content={"사업자번호 : " + BizConvert(data.biznum[1])}
                />
              )}
              {IsEmpty(data.vatdata3) == false && (
                <VatItem
                  label="부가가치세"
                  item={data.vatdata3}
                  content={"사업자번호 : " + BizConvert(data.biznum[2])}
                />
              )}
              {IsEmpty(data.vatdata6) == false && (
                <VatItem
                  label="부가가치세"
                  item={data.vatdata6}
                  content={"사업자번호 : " + BizConvert(data.biznum[2])}
                />
              )}
            </>
          )}

          {type == "withholdingtax" && (
            <>
              <Guide main1={"홈텍스에서 가져온 원천세 납부 내역입니다"}></Guide>
              <WidthhodlingItem
                label="원천세 납부내역"
                items={data}
                content={"원천세 납부 세금자료확인"}
              />
            </>
          )}

          {type == "localtax" && (
            <>
              <Guide main1={"홈텍스에서 가져온 지방세 납부 내역입니다"}></Guide>
              <LocalTaxItem
                label="지방세 납부내역"
                items={data}
                content={"지방세 납부 세금자료확인"}
              />
            </>
          )}

                  <Button
                      buttonText={"뒤로 가기"}
                      callback={_handleprev}
                      containerStyle={{
                        backgroundColor: colors.mainbuttoncolor,
                        color: "#fff",
                        margin: "30px 10x",
                        width: "95%",
                        height: 50,
                        fontSize: 16,
                        fontWeight: 700,
                        borderRadius: "10px",
                        fontFamily :"Pretendard-Bold"
                      }}
                    />
        </PCContainer>
      </PC>
    </>
  );
};

export default TableInfocontainer;
