import React, {
  useState,
  useEffect,
  useContext,
  useLayoutEffect,
  useRef,
} from "react";
import {
  HashRouter,
  Route,
  Redirect,
  BrowserRouter,
  Routes,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Swipe from "../common/Swipe";

import styled from "styled-components";

import Guide from "../components/Guide";
import Swipe2 from "../common/Swipe2";
import Button from "../common/Button";
import { colors, theme } from "../theme/theme";
import Label from "../common/Label";
import ServiceItem from "../components/ServiceItem";
import ServiceItem2 from "../components/DocumentItem";
import { Mobile, PC } from "../components/Responsive";
import HumanItem from "../components/HumanItem";
import YearItem from "../components/YearItem";
import FinancialItem from "../components/IncomeItem";
import VatItem from "../components/VatItem";
import TaxItem from "../components/LocalTaxItem";
import { imageDB } from "../utility/imageData";
import {
  get_tax,
  update_userfile,
  update_usernote,
  update_userrequest,
} from "../service/UserService";
import { UserContext } from "../context/User";
import { Alarm, AttachType } from "../utility/contentDefine";
import { CommaFormatted, IsEmpty, useSleep } from "../utility/common";
import ServiceItem3 from "../components/ServiceItem3";
import WidthhodlingItem from "../components/WithholdingItem";
import ContentItem from "../components/ContentItem";
import DocumentItem from "../components/DocumentItem";
import RequestItem from "../components/RequestItem";
import ResultItem from "../components/ResultItem";

const Container = styled.div`
  padding-top: 30px;
  background: white;
`;
const PCContainer = styled.div`
width: 27%;
margin: 0px 37%;
  background: white;
  padding-top: 35px;
  border : 1px solid
`;
const ServiceLayer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 10px 0px;
`;

const InitContainer = styled.div``;

const Homecontainer = ({}) => {
  const navigate = useNavigate();
  const [tax, setTax] = useState({});
  const [humandata, setHumandata] = useState("");
  const [humaninfo, setHumaninfo] = useState("");
  const [refresh, setRefresh] = useState(1);
  const [loading, setLoading] = useState(true);
  const { user, dispatch2 } = useContext(UserContext);
  const [note, setNote] = useState("");
  const [status, setStatus] = useState("init");

  const [incomemoney, setIncomemoney] = useState(0);
  const [vatcount, setVatcount] = useState(0);
  const [withholdingmoney, setWithholdingmoney] = useState(0);
  const [localmoney, setLocalmoney] = useState(0);

  const [reload, setReload] = useState(0);
  const items = [
    {
      label:
        "2024년 종합소득세 신고 기간입니다. 신고는 4월 20일까지 완료 되어야 합니다",
      background: "#fef0d3",
      img: imageDB.maingbg6,
      width: "150px",
      button: true,
    },
    {
      label: "2025년 종합소득세 신고를 빠르게 도와드립니다",
      background: "#f1f8ff",
      img: imageDB.maingbg3,
      width: "150px",
      height: "100px",
      button: true,
    },
    {
      label:
        "기존의 번거로운 세무자료를 직접 할 필요가 없습니다 세금신고 자료를 보내주세요",
      background: "#f5f6f8",
      img: imageDB.maingbg4,
      width: "150px",
      height: "100px",
      button: true,
    },
    {
      label: "공제 대상 자료는 다음에서 확인해주세요",
      background: "#f5f6f8",
      img: imageDB.maingbg1,
      width: "150px",
      height: "100px",
      button: true,
    },
  ];

  const _handlehuman = () => {};

  const _handleYearend = () => {};
  const _handlerequest = async () => {
    console.log("note", note);

    const tax_id = tax.tax_id;

    const u = await update_userrequest({ tax_id });

    const u2 = await useSleep(500);

    setStatus("request");

    setRefresh((refresh) => refresh + 1);

    // async function FetchData() {
    //   setLoading(true);
    //   let phone_ = user.phone.replaceAll("-", "");

    //   const phonedata = phone_;

    //   const username = user.name;

    //   useSleep(1000);

    //   const taxitem = await get_tax({ username, phonedata });
    //   setTax(taxitem);
    //   setNote(taxitem.note);

    //   setStatus(taxitem.status);
    //   setLoading(false);

    //   setRefresh((refresh) => refresh + 1);
    // }
    // FetchData();
  };

  const _handlefinancial = () => {};

  useEffect(() => {
    // setHumaninfo(humaninfo);
    setTax(tax);
    setNote(note);
    setStatus(status);

    console.log("tax", tax);
  }, [refresh]);

  const onNoteChange = (e) => {
    setNote(e.target.value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    async function FetchData() {
      setLoading(true);

      if (user.name != "") {
        const userObjString = JSON.stringify(user);
        window.localStorage.setItem("user", userObjString);
      } else {
        const userObj = JSON.parse(window.localStorage.getItem("user"));
      
        if (userObj != undefined) {
          user.name = userObj.name;
          user.phone = userObj.phone;


          dispatch2(user);
        }
      }
      
      
      let phone_ = user.phone.replaceAll("-", "");

      const phonedata = phone_;

      const username = user.name;

      console.log("user information", user);
     
 

      

      const taxitem = await get_tax({ username, phonedata });

      if (!IsEmpty(taxitem)) {
        setTax(taxitem);
        if (taxitem) {
          setNote(taxitem.note);
          setStatus(taxitem.status);

          let incomemoneydata = 0;

          if (!IsEmpty(taxitem.incomedata)) {
            taxitem.incomedata.list1.map((data, index) => {
              incomemoneydata += parseInt(data.totaPymnAmt);
            });
            taxitem.incomedata.list2.map((data, index) => {
              incomemoneydata += parseInt(data.totaPymnAmt);
            });
            taxitem.incomedata.list3.map((data, index) => {
              incomemoneydata += parseInt(data.totaPymnAmt);
            });
            taxitem.incomedata.list4.map((data, index) => {
              incomemoneydata += parseInt(data.totaPymnAmt);
            });

            setIncomemoney(incomemoneydata);
          }

          let vatcount = 0;
          if (!IsEmpty(taxitem.vatdata) || !IsEmpty(taxitem.vatdata4)) {
            vatcount += 1;
          }
          if (!IsEmpty(taxitem.vatdata2) || !IsEmpty(taxitem.vatdata5)) {
            vatcount += 1;
          }
          if (!IsEmpty(taxitem.vatdata3) || !IsEmpty(taxitem.vatdata6)) {
            vatcount += 1;
          }

          setVatcount(vatcount);

          let withholdingmoneydata = 0;

          if (!IsEmpty(taxitem.withholdingdata)) {
            taxitem.withholdingdata.map((data, index) => {
              withholdingmoneydata += parseInt(data.incPymnAmt);
            });

            setWithholdingmoney(withholdingmoneydata);
          }
          let localmoney = 0;

          if (!IsEmpty(taxitem.localtaxdata)) {
            taxitem.localtaxdata.list.map((data, index) => {
              localmoney += parseInt(data.sumAmt);
            });

            console.log("localtax", taxitem.localtaxdata);

            setLocalmoney(localmoney);
          }
          
  
        }
      }

      setLoading(false);

      setRefresh((refresh) => refresh + 1);
    }
    FetchData();
  }, []);

  useEffect(() => {
    async function FetchData() {
      setLoading(true);
      let phone_ = user.phone.replaceAll("-", "");

      const phonedata = phone_;

      const username = user.name;

      const taxitem = await get_tax({ username, phonedata });

      if (!IsEmpty(taxitem)) {
        setTax(taxitem);
        if (taxitem) {
          setNote(taxitem.note);
          setStatus(taxitem.status);

          let incomemoneydata = 0;

          if (!IsEmpty(taxitem.incomedata)) {
            taxitem.incomedata.list1.map((data, index) => {
              incomemoneydata += parseInt(data.totaPymnAmt);
            });
            taxitem.incomedata.list2.map((data, index) => {
              incomemoneydata += parseInt(data.totaPymnAmt);
            });
            taxitem.incomedata.list3.map((data, index) => {
              incomemoneydata += parseInt(data.totaPymnAmt);
            });
            taxitem.incomedata.list4.map((data, index) => {
              incomemoneydata += parseInt(data.totaPymnAmt);
            });

            setIncomemoney(incomemoneydata);
          }

          let vatcount = 0;
          if (!IsEmpty(taxitem.vatdata) || !IsEmpty(taxitem.vatdata4)) {
            vatcount += 1;
          }
          if (!IsEmpty(taxitem.vatdata2) || !IsEmpty(taxitem.vatdata5)) {
            vatcount += 1;
          }
          if (!IsEmpty(taxitem.vatdata3) || !IsEmpty(taxitem.vatdata6)) {
            vatcount += 1;
          }

          setVatcount(vatcount);

          let withholdingmoneydata = 0;

          if (!IsEmpty(taxitem.withholdingdata)) {
            taxitem.withholdingdata.map((data, index) => {
              withholdingmoneydata += parseInt(data.incPymnAmt);
            });

            setWithholdingmoney(withholdingmoneydata);
          }
          let localmoney = 0;

          if (!IsEmpty(taxitem.localtaxdata)) {
            taxitem.localtaxdata.list.map((data, index) => {
              localmoney += parseInt(data.sumAmt);
            });

            console.log("localtax", taxitem.localtaxdata);

            setLocalmoney(localmoney);
          }
        }
      }

      setLoading(false);

      setRefresh((refresh) => refresh + 1);
    }
    FetchData();
  }, [reload]);

  const refreshcallback = () =>{
    setStatus('init');
    setRefresh((refresh) => refresh +1);
  }

  return (
    <>
      <Mobile>
        {loading == false && (
          <Container>
            {status == "init" ? (
              <InitContainer>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    padding: "0px 10%",
                    marginTop: "70px",
                    textAlign: "left",
                  }}
                >
                  <Label
                    content={
                      "신고자료를 간편인증을 통해 다음과 같이 가져왔습니다"
                    }
                    fontsize={"20px"}
                    fontweight={700}
                  />
                </div>

                <ServiceLayer>
                  {IsEmpty(tax.yeardata.info) == false ? (
                    <ContentItem
                      label="연말정산"
                      content={"연말정산간소화자료를 조회했습니다"}
                      type="year"
                      data={tax.yeardata.info}
                      enable={true}
                    />
                  ) : (
                    <ContentItem
                      label="연말정산"
                      content={"연말정산 세금자료가 확인되지 않고 있습니다"}
                      type="year"
                      enable={false}
                    />
                  )}
                  {IsEmpty(tax.incomedata) == false ? (
                    <ContentItem
                      label="소득자료"
                      content={
                        "소득금액이 " +
                        CommaFormatted(incomemoney) +
                        "원 확인됩니다"
                      }
                      type="income"
                      data={tax.incomedata}
                      enable={true}
                    />
                  ) : (
                    <ContentItem
                      label="소득자료"
                      content={"소득자료가 확인되지 않고 있습니다"}
                      type="year"
                      enable={false}
                    />
                  )}
                  {vatcount > 0 ? (
                    <ContentItem
                      label="부가가치세"
                      content={
                        vatcount +
                        "개의 사업장에서 부가가치세 납부내역을 확인하였습니다"
                      }
                      type="vattax"
                      enable={true}
                      data={tax}
                    />
                  ) : (
                    <ContentItem
                      label="부가가치세"
                      content={"부가가치세 납부내역을 확인할수가 없습니다"}
                      type="vattax"
                      enable={false}
                    />
                  )}

                  {IsEmpty(tax.withholdingdata) == false && (
                    <ContentItem
                      label="인건비 신고"
                      content={
                        "인건비 납부내역이 " +
                        CommaFormatted(withholdingmoney) +
                        "원 확인됩니다"
                      }
                      type="withholdingtax"
                      data={tax.withholdingdata}
                      enable={true}
                    />
                  )}

                  {IsEmpty(tax.localtaxdata) == false ? (
                    <ContentItem
                      label="지방세"
                      content={
                        "지방세 납부내역이 " +
                        CommaFormatted(localmoney) +
                        "원 확인됩니다"
                      }
                      type="localtax"
                      data={tax.localtaxdata}
                      enable={true}
                    />
                  ) : (
                    <ContentItem
                      label="지방세"
                      content={"지방세 세금자료가 확인되지 않고 있습니다"}
                      type="localtax"
                      enable={false}
                    />
                  )}
                </ServiceLayer>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    padding: "0px 10%",
                    marginTop: "70px",
                    textAlign: "left",
                  }}
                >
                  <Label
                    content={"세금 신고를 위해 다음 사항을 입력하거나 첨부해주세요"}
                    fontsize={"20px"}
                    fontweight={700}
                  />
                </div>
                <ServiceLayer>
                  <HumanItem
                    callback={_handlehuman}
                    label="인적공제"
                    items={tax.etchumandata}
                    content={humaninfo}
                  />
                </ServiceLayer>

                {IsEmpty(tax.withholdingdata) == true && <ServiceLayer>
                  <DocumentItem
                    label="인건비"
                    content={
                      "인건비 납부 내역이 확인 가능한 이미지 파일이나 사진을 첨부해 주세요"
                    }
                    attachtype={AttachType.TYPE9}
                    images={tax.type9}
                    taxid={tax.tax_id}
                  />
                </ServiceLayer>}

                <ServiceLayer>
                  <DocumentItem
                    label="지역건보료 납부확인서"
                    label2 = "국민건강보험 홈페이지 / 전화번호 1577-1000"
                    content={
                      "지역 건보료 납부 내역이 확인 가능한 이미지 파일이나 사진을 첨부해 주세요"
                    }
                    attachtype={AttachType.TYPE1}
                    images={tax.type1}
                    taxid={tax.tax_id}
                  />
                </ServiceLayer>

                <ServiceLayer>
                  <DocumentItem
                    label="자동차보험증권(보험회사)"
                    content={
                      "차량등록증이나 자동차보험증권 이미지 파일이나 사진을 첨부해 주세요"
                    }
                    attachtype={AttachType.TYPE2}
                    images={tax.type2}
                    taxid={tax.tax_id}
                  />
                </ServiceLayer>

                <ServiceLayer>
                  <DocumentItem
                    label="자동차 리스계약서"
                    content={
                      "자동차 리스계약서나 리스스케줄표가 확인가능한  이미지 파일이나 사진을 첨부해 주세요"
                    }
                    attachtype={AttachType.TYPE3}
                    images={tax.type3}
                    taxid={tax.tax_id}
                  />
                </ServiceLayer>

                <ServiceLayer>
                  <DocumentItem
                    label="특고 고용산재보험"
                    label2="(고용산재보험토탈서비스)"
                    content={
                      "다음은 개별요청 받은 분이 첨부해주는 자료입니다 준비 가능한 분은 임의로 제출하셔도 됩니다"
                    }
                    attachtype={AttachType.TYPE4}
                    images={tax.type4}
                    taxid={tax.tax_id}
                  />
                </ServiceLayer>

                <ServiceLayer>
                  <DocumentItem
                    label="시설대금 원리금 상환내역서"
                    content={
                      "임대업을 영위하는 사업자이시면 시설대금 원리금 상환내역서 확인가능한  이미지 파일이나 사진을 첨부해 주세요"
                    }
                    attachtype={AttachType.TYPE5}
                    images={tax.type5}
                    taxid={tax.tax_id}
                  />
                </ServiceLayer>

                <ServiceLayer>
                  <DocumentItem
                    label="지출통장내역(은행 인터넷뱅킹)"
                    content={
                      "지출통장내역 확인가능한 파일을 엑셀파일로 첨부해 주세요  개별요청자만 첨부해주세요"
                    }
                    attachtype={AttachType.TYPE6}
                    images={tax.type6}
                    taxid={tax.tax_id}
                  />
                </ServiceLayer>

                <ServiceLayer>
                  <DocumentItem
                    label="카드사용내역서(카드사 홈페이지)"
                    content={
                      "카드 사용건별 확인가능한 파일을 엑셀파일로 첨부해 주세요 개별 요청자만 첨부해주세요"
                    }
                    attachtype={AttachType.TYPE7}
                    images={tax.type7}
                    taxid={tax.tax_id}
                  />
                </ServiceLayer>

       
                  <Button
                    buttonText={"세무대리 접수하기"}
                    callback={_handlerequest}
                    containerStyle={{
                      backgroundColor: colors.mainbuttoncolor,
                      color: "#fff",
                      margin: "10px 10%",
                      width: "80%",
                      height: 40,
                      fontSize: 17,
                      fontWeight: 700,
                      borderRadius: "10px",
                    }}
                  />
                
              </InitContainer>
            ) : (


              <>
              {
                status == 'request' ? (<RequestItem tax={tax} callback={refreshcallback}/>):(    <div style={{ background: "white", height: "700px" }}>
                <div
                  style={{
                    margin: "50px 20px",
                    textAlign: "left",
                    lineHeight: 2,
                  }}
                >
                  <ResultItem item={tax}/>
                </div>
              </div>)
              }
            
              </>
            )}
          </Container>
        )}
      </Mobile>
      <PC>
        {loading == false && (
          <PCContainer>
            {status == "init" ? (
              <InitContainer>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    padding: "0px 10%",
                    marginTop: "70px",
                    textAlign: "left",
                  }}
                >
                  <Label
                    content={
                      "신고자료를 간편인증을 통해 다음과 같이 가져왔습니다"
                    }
                    fontsize={"20px"}
                    fontweight={700}
                  />
                </div>

                <ServiceLayer>
                  {IsEmpty(tax.yeardata.info) == false ? (
                    <ContentItem
                      label="연말정산"
                      content={"연말정산간소화자료를 조회했습니다"}
                      type="year"
                      data={tax.yeardata.info}
                      enable={true}
                    />
                  ) : (
                    <ContentItem
                      label="연말정산"
                      content={"연말정산 세금자료가 확인되지 않고 있습니다"}
                      type="year"
                      enable={false}
                    />
                  )}
                  {IsEmpty(tax.incomedata) == false ? (
                    <ContentItem
                      label="소득자료"
                      content={
                        "소득금액이 " +
                        CommaFormatted(incomemoney) +
                        "원 확인됩니다"
                      }
                      type="income"
                      data={tax.incomedata}
                      enable={true}
                    />
                  ) : (
                    <ContentItem
                      label="소득자료"
                      content={"소득자료가 확인되지 않고 있습니다"}
                      type="year"
                      enable={false}
                    />
                  )}
                  {vatcount > 0 ? (
                    <ContentItem
                      label="부가가치세"
                      content={
                        vatcount +
                        "개의 사업장에서 부가가치세 납부내역을 확인하였습니다"
                      }
                      type="vattax"
                      enable={true}
                      data={tax}
                    />
                  ) : (
                    <ContentItem
                      label="부가가치세"
                      content={"부가가치세 납부내역을 확인할수가 없습니다"}
                      type="vattax"
                      enable={false}
                    />
                  )}

                  {IsEmpty(tax.withholdingdata) == false && (
                    <ContentItem
                      label="인건비 신고"
                      content={
                        "인건비 납부내역이 " +
                        CommaFormatted(withholdingmoney) +
                        "원 확인됩니다"
                      }
                      type="withholdingtax"
                      data={tax.withholdingdata}
                      enable={true}
                    />
                  )}

                  {IsEmpty(tax.localtaxdata) == false ? (
                    <ContentItem
                      label="지방세"
                      content={
                        "지방세 납부내역이 " +
                        CommaFormatted(localmoney) +
                        "원 확인됩니다"
                      }
                      type="localtax"
                      data={tax.localtaxdata}
                      enable={true}
                    />
                  ) : (
                    <ContentItem
                      label="지방세"
                      content={"지방세 세금자료가 확인되지 않고 있습니다"}
                      type="localtax"
                      enable={false}
                    />
                  )}
                </ServiceLayer>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    padding: "0px 10%",
                    marginTop: "70px",
                    textAlign: "left",
                  }}
                >
                  <Label
                    content={"세금 신고를 위해 다음자료가 필요해요"}
                    fontsize={"20px"}
                    fontweight={700}
                  />
                </div>
                <ServiceLayer>
                  <HumanItem
                    callback={_handlehuman}
                    label="인적공제"
                    items={tax.etchumandata}
                    content={humaninfo}
                  />
                </ServiceLayer>


                {IsEmpty(tax.withholdingdata) == true && <ServiceLayer>
                  <DocumentItem
                    label="인건비"
                    content={
                      "인건비 납부 내역이 확인 가능한 이미지 파일이나 사진을 첨부해 주세요"
                    }
                    attachtype={AttachType.TYPE9}
                    images={tax.type9}
                    taxid={tax.tax_id}
                  />
                </ServiceLayer>}

                <ServiceLayer>
                  <DocumentItem
                    label="지역건보료 납부확인서"
                    label2 = "국민건강보험 홈페이지 / 전화번호 1577-1000"
                    content={
                      "지역 건보료 납부 내역이 확인 가능한 이미지 파일이나 사진을 첨부해 주세요"
                    }
                    attachtype={AttachType.TYPE1}
                    images={tax.type1}
                    taxid={tax.tax_id}
                  />
                </ServiceLayer>

                <ServiceLayer>
                  <DocumentItem
                    label="자동차보험증권(보험회사)"
                    content={
                      "차량등록증이나 자동차보험증권 이미지 파일이나 사진을 첨부해 주세요"
                    }
                    attachtype={AttachType.TYPE2}
                    images={tax.type2}
                    taxid={tax.tax_id}
                  />
                </ServiceLayer>

                <ServiceLayer>
                  <DocumentItem
                    label="자동차 리스계약서"
                    content={
                      "자동차 리스계약서나 리스스케줄표가 확인가능한  이미지 파일이나 사진을 첨부해 주세요"
                    }
                    attachtype={AttachType.TYPE3}
                    images={tax.type3}
                    taxid={tax.tax_id}
                  />
                </ServiceLayer>

                <ServiceLayer>
                  <DocumentItem
                    label="특고 고용산재보험"
                    label2="(고용산재보험토탈서비스)"
                    content={
                      "다음은 개별요청 받은 분이 첨부해주는 자료입니다 준비 가능한 분은 임의로 제출하셔도 됩니다"
                    }
                    attachtype={AttachType.TYPE4}
                    images={tax.type4}
                    taxid={tax.tax_id}
                  />
                </ServiceLayer>

                <ServiceLayer>
                  <DocumentItem
                    label="시설대금 원리금 상환내역서"
                    content={
                      "임대업을 영위하는 사업자이시면 시설대금 원리금 상환내역서 확인가능한  이미지 파일이나 사진을 첨부해 주세요"
                    }
                    attachtype={AttachType.TYPE5}
                    images={tax.type5}
                    taxid={tax.tax_id}
                  />
                </ServiceLayer>

                <ServiceLayer>
                  <DocumentItem
                    label="지출통장내역(은행 인터넷뱅킹)"
                    content={
                      "지출통장내역 확인가능한 파일을 엑셀파일로 첨부해 주세요  개별요청자만 첨부해주세요"
                    }
                    attachtype={AttachType.TYPE6}
                    images={tax.type6}
                    taxid={tax.tax_id}
                  />
                </ServiceLayer>

                <ServiceLayer>
                  <DocumentItem
                     label="카드 사용건별 내역서(카드사 홈페이지)"
                    content={
                      "카드 사용건별 확인가능한 파일을 엑셀파일로 첨부해 주세요 개별 요청자만 첨부해주세요"
                    }
                    attachtype={AttachType.TYPE7}
                    images={tax.type7}
                    taxid={tax.tax_id}
                  />
                </ServiceLayer>



                    <Button
                      buttonText={"세무대리 접수하기"}
                      callback={_handlerequest}
                      containerStyle={{
                      backgroundColor: colors.mainbuttoncolor,
                      color: "#fff",
                      margin: "10px 10%",
                      width: "80%",
                      height: 40,
                      fontSize: 17,
                      fontWeight: 700,
                      borderRadius: "10px",
                    }}
                  />
             
              </InitContainer>
            ) : (
              <>
              {
                status == 'request' ? (<RequestItem tax={tax} callback={refreshcallback}/>):(    <div style={{ background: "white", height: "700px" }}>
                <div
                  style={{
                    margin: "50px 20px",
                    textAlign: "left",
                    lineHeight: 2,
                  }}
                >
                    <ResultItem item={tax}/>
                </div>
              </div>)
              }
            
              </>
            )}
          </PCContainer>
        )}
      </PC>
    </>
  );
};

export default Homecontainer;
