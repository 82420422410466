import React, { useContext, useEffect, useState } from "react";

import Layout from "../screen/Layout/HomeLayout";
import HomeLayout from "../screen/Layout/HomeLayout";
import { UserContext } from "../context/User";
import { TYPE } from "../utility/maroneDefine";
import { useLocation } from "react-router-dom";
import {
  get_userInfoForDevice,
  login,
  update_userdevice,
} from "../service/UserService";


import Resultcontainer from "../container/Resultcontainer";
import PrevLayout from "../screen/Layout/PrevLayout";

const Resultpage = () => {
  const { user, dispatch2 } = useContext(UserContext);
    const { state } = useLocation();

  return (
    <HomeLayout menu={true} bottom={false} header={true} headerdetail={false}>
      <Resultcontainer name={state.username} />
    </HomeLayout>
  );
};

export default Resultpage;
