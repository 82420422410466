import React,{useState, useEffect} from 'react';
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, Link, useNavigate} from "react-router-dom";
import styled from 'styled-components';
import { Mobile, PC } from './Responsive';
import { BiCalendarEdit } from "react-icons/bi";

const Container = styled.div`
  margin: 0px 5%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  line-height: 2;
`;

const LabelItem = styled.div`
  font-weight: 400;
  line-height: 1.5;
  font-size: 1.2rem;
  text-decoration-line: none;
  color: rgb(120, 131, 145);
  margin-left:5px;
`;


const InputItem = ({containerStyle, label, placeholder, callback, type, callback2}) => {

  const [data, setData] = useState('');
  const [refresh, setRefresh] = useState(1);
  const [border, setBorder] = useState(false);
  const navigate = useNavigate();
   useEffect(()=>{
    async function fetchData(){
		}
		fetchData();
  }, [])


  const handleFocusBorder = () => {
    setBorder(true);
  }
  const handleIputValidate = () => {

  };

 const activeEnter = (e) => {
   if (e.key === "Enter") {
     callback2();
   }
 };
  const setCallback = (_data) => {
     callback(_data);
  }
  useEffect(() => {
    setData(data);
    setBorder(border);
    setCallback(data);
  },[refresh])

  return (
    <Container style={containerStyle}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <BiCalendarEdit size={20} />
        <LabelItem>{label}</LabelItem>
      </div>

      <div>
        <Mobile>
          <input
            type={type}
            value={data}
            onChange={(e) => {
              setData(e.target.value);
              setRefresh((refresh) => refresh + 1);
            }}
            onKeyDown={(e) => activeEnter(e)}
            onSubmitEditing={() => {
              alert("test");
            }}
            className="inputcommon"
            placeholder={placeholder}
            onFocus={() => handleFocusBorder()}
            onBlur={() => handleIputValidate()}
          ></input>
        </Mobile>
        <PC>
          <input
            type={type}
            value={data}
            onChange={(e) => {
              setData(e.target.value);
              setRefresh((refresh) => refresh + 1);
            }}
            className="inputcommon"
            placeholder={placeholder}
            onFocus={() => handleFocusBorder()}
            onBlur={() => handleIputValidate()}
          ></input>
        </PC>

        <div className="inputNoFocus" />
      </div>
    </Container>
  );
}
InputItem.defaultProps = {
  
  type : "text",
}

export default InputItem;
