import React, { Component, useEffect } from "react";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes } from "react-router-dom";
import Homepage from "./page/Homepage";
import PrivacyInfopage from "./page/PrivacyInfopage";

import Resultpage from "./page/Resultpage";
import Gatepage from "./page/Gatepage";
import Humanregisterpage from "./page/Humanregisterpage";
import Noticepage from "./page/Noticepage";
import Noticedetailpage from "./page/Noticedetailpage";
import Loginpage from "./page/Loginpage";
import Newsdetailpage from "./page/Newsdetailpage";
import Agreepage from "./page/Authselectpage";
import Authrequestpage from "./page/Hometaxgetpage";
import Seekpage from "./page/Seekpage";
import Optionpage from "./page/Optionpage";
import Datapage from "./page/Datapage";
import BasicInfopage from "./page/BasicInfopage";
import HometaxInfopage from "./page/HometaxInfopage";
import WetaxInfopage from "./page/WetaxInfopage";
import BizInfopage from "./page/BizInfopage";
import BizTypeInfopage from "./page/BizTypeInfopage";
import TableInfopage from "./page/TableInfopage";
import ImageInfopage from "./page/Imagenfopage";
import ExcelInfopage from "./page/ExcelInfopage";
import Authselectpage from "./page/Authselectpage";
import Hometaxgetpage from "./page/Hometaxgetpage";
import Wetaxgetpage from "./page/Wetaxgetpage";
import JuminInfopage from "./page/JuminInfopage";
import Loading from "./components/Loading";
import Loading2 from "./components/Loading2";
import Requestpage from "./page/Requestpage";
import Infopage from "./page/Infopage";
import NewHomepage from "./page/NewHomepage";
import Authselectpage2 from "./page/Authselectpage2";





const App =() =>  {
 
  return (
    <Routes>
      <Route path="/" element={<Gatepage />} />
      <Route path="/basicinfo" element={<BasicInfopage />} />
      <Route path="/jumininfo" element={<JuminInfopage />} />
      <Route path="/hometaxinfo" element={<HometaxInfopage />} />
      <Route path="/wetaxinfo" element={<WetaxInfopage />} />
      <Route path="/bizinfo" element={<BizInfopage />} />
      <Route path="/biztypeinfo" element={<BizTypeInfopage />} />
      <Route path="/tableinfo" element={<TableInfopage />} />
      <Route path="/imageinfo" element={<ImageInfopage />} />
      <Route path="/excelinfo" element={<ExcelInfopage />} />
      <Route path="/authselect" element={<Authselectpage />} />
      <Route path="/authselect2" element={<Authselectpage2 />} />
      <Route path="/loading" element={<Loading />} />
      <Route path="/loading2" element={<Loading2 />} />
      <Route path="/login" element={<Loginpage />} />
      <Route path="/hometaxget" element={<Hometaxgetpage />} />
      <Route path="/wetaxget" element={<Wetaxgetpage />} />
      <Route path="/result" element={<Resultpage />} />
      <Route path="/reuest" element={<Requestpage />} />
      <Route path="/data" element={<Datapage />} />
      <Route path="/notice" element={<Noticepage />} />
      <Route path="/info" element={<Infopage />} />
      <Route path="/noticedetail" element={<Noticedetailpage />} />
      <Route path="/newsdetail" element={<Newsdetailpage />} />
      <Route path="/home" element={<Homepage />} />
      <Route path="/newhome" element={<NewHomepage />} />
      <Route path="/option" element={<Optionpage />} />
      <Route path="/seek" element={<Seekpage />} />
      <Route path="/humanregister" element={<Humanregisterpage />} />
    </Routes>
  );

 
}

export default App;
