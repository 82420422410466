export const ReviewContent = [{content:"친절해요", imagecontent:"😄"},
{content:"마사지를 잘해요", imagecontent:"👍"},
{content:"편안해요", imagecontent:"🛌"},
{content:"전문적이에요", imagecontent:"🏅"},
{content:"샤워가 가능해요", imagecontent:"🛁"},
{content:"꼼꼼해요", imagecontent:"🔍"},
{content:"마인드가 좋아요", imagecontent:"💖"},
{content:"비싸도 가치있어요", imagecontent:"💵"},
];

export const LoginType = {
    kakao: "카카오",
    samsung: "삼성패스",
    pass: "PASS",
    kb: "KB",
    payco: "PAYCO"
}

export const AttachType = {
  TYPE1: "지역건보료",
  TYPE2: "차량등록증/보험증권",
  TYPE3: "자동차 리스계약서",
  TYPE4: "특고 고용산재보험",
  TYPE5: "(임대업) 시설대금 원리금 상환내역서",
  TYPE6: "지출통장내역(개별요청자만)",
  TYPE7: "카드 사용 건별 내역서(개별요청자만)",
  TYPE8: "기타서류",
  TYPE9: "인건비",
  TYPE10: "기부금",
  TYPE11: "소득금액분배명세서"
};

export const InfoType = {
  JUMIN: "주민등록번호",
  BANK : "계좌번호",
  EMAIL : "이메일",
  HOMETAXID : "홈택스아이디",
  HOMETAXPW : "홈택스패스워드",
  ADDR : "주민등록주소지",
  HOUSE : "주택임대소득",
  FINANCIAL : "금융소득",
  BUSINESS:"사업자등록한분소득"

};



export const Alarm = {
  RESULT:
    "청람세무회계사무소에 세금 신고 접수를 신고 하였습니다. 신고 결과는 청람세무 회계사무소에서 정산작업후에 결과를 문자 알림 및 웹페이지에서 상세하게 알려드리겠습니다",
};
