

import React from "react";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";



const HomeLayout = (props) => {



  return (
    <div> 
        {
          (props.header == true) &&
            <Header/>
          
        }
        <main>
          {props.children}
        </main>
      {/* <Footer  menu ={props.menu} bottom ={props.bottom}  type={"home"}/> */}
    </div>
  );
};

export default HomeLayout;
