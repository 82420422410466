import React,{useState, useEffect, useContext} from 'react';
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, Link, useNavigate} from "react-router-dom";
import styled from 'styled-components';
import { imageDB } from '../utility/imageData';
import Relationbtn from './Relationbtn';
import Button from '../common/Button';
import { colors } from '../theme/theme';
import { GrAddCircle } from "react-icons/gr";
import { CgUserRemove } from 'react-icons/cg';
import { UserContext } from '../context/User';
import { get_tax, update_user2, update_useradjust } from '../service/UserService';
import { AiFillCloseCircle } from "react-icons/ai";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useSpring, animated } from "@react-spring/web";
import PropTypes from "prop-types";



const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};

const IconCloseView = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 2;
  width: 90%;
  background: #f8f8f8;
  margin: 50px 5% 0px 5%;
  box-shadow: 2px 2px 2px 2px #adabab;
  border-radius: 5px;
  height:580px;
`;

const LabelItem = styled.div`
  font-weight: 700;
  line-height: 1.4;
  font-size: 1rem;
  text-decoration-line: none;
  color: rgb(58, 64, 71);
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 90%;
`;

const Content = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  margin-top:5px;
`
const ItemLabel = styled.div`
  width: 50px;
  height: 50px;
  background-color: #ededed;
  border-radius: 100%;
  position: absolute;
  left: 30px;
`;
const ItemData = styled.div`
  font-weight: 600;
  line-height: 1.5;
  font-size: 14px;
  text-decoration-line: none;
  color: #788391;;
  text-align:left;
  padding : 0px 10px;
`;

const LineControl = styled.div`
  margin-top:20px;
  width: 100%;
  height: 1px;
  background-color: #f0f1f2;
`;

const Note = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  text-align: right;
  font-size: 16px;
  margin-top: 20px;
  align-items: flex-end;
`;

const Info = styled.div`
  font-size: 14px;
  text-align: left;
  padding: 0px 20px;
`;
const style = {
  position: "absolute",
  top: "50%",
  left: "80%",
  height: "600px",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  padding: "10px",
};



const RequestItem = ({
  containerStyle,
  tax,
  item,
  label,
  content,
  color,
  callback,
  bgcolor,
}) => {
  const navigate = useNavigate();


  const _handlerequest = async() =>{

    const tax_id =tax.tax_id;
    const u = await update_useradjust({ tax_id });

    callback();


  }




  return (
    <>
        <Container style={containerStyle} bgcolor={bgcolor}>
      <Content>
        <LabelItem>[온라인 세무대리 접수완료]</LabelItem>



        <div style={{ marginTop: 20, width: "100%" }}>
          <Info>2023년 귀속분 종합소득세 세무대리 온라인 접수가 정상적으로 완료 되었습니다. 추가로 확인 해야 하거나 부족한 서류는 개별적으로 연락 드리겠습니다</Info>
        </div>

   

        <div style={{ marginTop: 20, width: "100%" }}>
 
          <Info>신고 결과 안내 까지 보통 15일 정도 소요됩니다. 세무대리 접수관련 추가 변경이 있을 경우 전화(02-535-0330)또는 카톡으로 문의 바랍니다.</Info>

          <Info>
          <a href={"tel:02-533-0330"} target={'_self'} style={{textDecoration:"underline", color :"#000"}} >
            전화하기 02-533-0330</a></Info>
          <Info >
            <a href={"https://pf.kakao.com/_sxnVxixl"} target={'_self'} style={{textDecoration:"underline", color:"#000"}}>
              카톡하기</a></Info>

        </div>



      </Content>



      <LineControl />
        </Container>
        <Button
          buttonText={"수정하여 재신고하기"}
          callback={_handlerequest}
          containerStyle={{
            backgroundColor: colors.mainbuttoncolor,
            color: "#fff",
            marginLeft: "5%",
            width: "90%",
            height: 40,
            fontSize: 17,
            borderRadius: "10px",
            fontFamily:'Pretendard-Bold'
          }}
        />
    </>
  )

};

RequestItem.defaultProps = {
  bgcolor: "#FFF",
};


export default RequestItem;
