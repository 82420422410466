 export const imageDB = {
   sample: require("../assets/imageset/coupone.png"),
   splash: require("../assets/imageset/splashscreen.png"),
   logo: require("../assets/imageset/logo.png"),
   gps: require("../assets/imageset/gps.png"),
   prev: require("../assets/imageset/prev.png"),
   right: require("../assets/imageset/right.png"),
   bell: require("../assets/imageset/bell.png"),
   setting: require("../assets/imageset/setting.png"),
   loading: require("../assets/imageset/loading.gif"),
   search: require("../assets/imageset/search.png"),
   refresh: require("../assets/imageset/refresh.png"),
   thema: require("../assets/imageset/thema.png"),
   coupone: require("../assets/imageset/coupone.png"),
   type: require("../assets/imageset/type.png"),
   like: require("../assets/imageset/like.png"),
   price: require("../assets/imageset/price.png"),
   close: require("../assets/imageset/close.png"),
   heart: require("../assets/imageset/heart.png"),
   hearton: require("../assets/imageset/hearton.png"),
   question: require("../assets/imageset/question.png"),
   kakaomsg: require("../assets/imageset/kakaomsg.png"),
   navermsg: require("../assets/imageset/navermsg.png"),
   bottom_home: require("../assets/imageset/bottom_home.png"),
   bottom_home_disable: require("../assets/imageset/bottom_home_disable.png"),
   bottom_location: require("../assets/imageset/bottom_location.png"),
   bottom_location_disable: require("../assets/imageset/bottom_location_disable.png"),
   bottom_gps: require("../assets/imageset/bottom_gps.png"),
   bottom_chat: require("../assets/imageset/bottom_chat.png"),
   bottom_chat_disable: require("../assets/imageset/bottom_chat_disable.png"),
   bottom_profile: require("../assets/imageset/bottom_profile.png"),
   bottom_profile_disable: require("../assets/imageset/bottom_profile_disable.png"),
   check_recommend: require("../assets/imageset/recommend.png"),
   ThemaWoman: require("../assets/imageset/thema_woman.png"),
   ThemaMan: require("../assets/imageset/thema_man.png"),
   Thema20: require("../assets/imageset/thema_20.png"),
   Thema30: require("../assets/imageset/thema_30.png"),
   Thema40: require("../assets/imageset/thema_40.png"),
   ThemaCar: require("../assets/imageset/thema_car.png"),
   ThemaSleep: require("../assets/imageset/thema_sleep.png"),
   ThemaShower: require("../assets/imageset/thema_shower.png"),
   ThemaOneshop: require("../assets/imageset/thema_oneshop.png"),
   ThemaGroups: require("../assets/imageset/thema_groups.png"),
   ThemaCouple: require("../assets/imageset/thema_couple.png"),
   ThemaGroup: require("../assets/imageset/thema_group.png"),
   ThemaManOnly: require("../assets/imageset/thema_manonly.png"),
   ThemaWomanOnly: require("../assets/imageset/thema_womanonly.png"),
   ThemaComman: require("../assets/imageset/thema_common.png"),
   NavigationImage: require("../assets/imageset/navigation.png"),
   ReviewImage: require("../assets/imageset/review.png"),
   HeartImage: require("../assets/imageset/heart.png"),
   Address: require("../assets/imageset/addr.png"),
   Watch: require("../assets/imageset/watch.png"),
   Car: require("../assets/imageset/car.png"),
   Tel: require("../assets/imageset/tel.png"),
   Up: require("../assets/imageset/up.png"),
   Prev_White: require("../assets/imageset/prev_white.png"),
   Home: require("../assets/imageset/home.png"),
   STORE: require("../assets/imageset/store.png"),
   Massage: require("../assets/imageset/massage.png"),
   Heart_White: require("../assets/imageset/heart_white.png"),
   marone_person: require("../assets/imageset/maroneperson.png"),
   Progress: require("../assets/imageset/tranparentprogress.gif"),
   Profile:
     "https://firebasestorage.googleapis.com/v0/b/marone-d7e06.appspot.com/o/images%2Fprofile4040.png?alt=media&token=465b3bdd-bb9b-4344-8129-7a5cd98c57d5",
   basic: require("../assets/imageset/basic.png"),
   coupon: require("../assets/imageset/coupon.png"),
   kakaoauth: require("../assets/imageset/kakaoauth.png"),
   samsungauth: require("../assets/imageset/samsungauth.png"),
   passauth: require("../assets/imageset/passauth.png"),
   kbauth: require("../assets/imageset/kbauth.png"),
   paycoauth: require("../assets/imageset/paycoauth.png"),
   authsample: require("../assets/imageset/authsample.png"),
   iconsample: require("../assets/imageset/iconsample.png"),
   bgmain: require("../assets/imageset/bg_main.png"),
   maingbg1: require("../assets/imageset/mainbg1.png"),
   maingbg2: require("../assets/imageset/mainbg2.png"),
   maingbg3: require("../assets/imageset/mainbg3.png"),
   maingbg4: require("../assets/imageset/mainbg4.png"),
   maingbg5: require("../assets/imageset/mainbg5.png"),
   maingbg6: require("../assets/imageset/mainbg6.png"),
   agree1: require("../assets/imageset/agree1.png"),
   agree2: require("../assets/imageset/agree2.png"),
   point: require("../assets/imageset/point.png"),
   tax: require("../assets/imageset/tax.png"),
   info: require("../assets/imageset/info.png"),
   vattax: require("../assets/imageset/vattax.png"),
   income: require("../assets/imageset/income.png"),
   loading2 : require("../assets/imageset/loading2.gif"),
 };



