import React,{useState, useEffect} from 'react';
import { CgUserAdd, CgUserRemove } from 'react-icons/cg';
import { GrAddCircle } from 'react-icons/gr';
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, Link, useNavigate} from "react-router-dom";
import styled from 'styled-components';
import { MdOutlineRefresh } from "react-icons/md";
import { SiMicrosoftexcel } from "react-icons/si";

const Container = styled.div`
  margin: 2px 5%;
  background-color:${({bgcolor}) => bgcolor};
  color: rgb(0 0 0);
  width: 90%;
  height: 30px;
  font-size: 9px;
  font-family: Pretendard-Regular;
  border: 1px solid ${({bordercolor}) => bordercolor};
  display:flex;
  justify-content:center;
  align-items:center;
  box-shadow: 2px 1px 1px #e2dfdf;

`

const MainButton = ({containerStyle, buttonText, callback, index, icon, gif, bgcolor, bordercolor}) => {


  const _handleClick = () =>{
    callback(index);
  }



  return (
    <Container style={containerStyle} onClick={_handleClick} className="button" bgcolor={bgcolor} bordercolor={bordercolor}>
      {icon == "refresh" && <MdOutlineRefresh size={20} />}
      {icon == "add" && <GrAddCircle size={30} color={"#3268f3"} />}
      {icon == "excel" && <SiMicrosoftexcel size={30} />}
      {icon == "useradd" && <CgUserAdd size={20} />}
      {icon == "userremove" && <CgUserRemove size={20} />}
      {gif != "" && <img src= {gif} width ={70} height={70}/> }
      <div>{buttonText}</div>
    </Container>
  );
}
MainButton.defaultProps = {
  gif: "",
  bgcolor:"#f9f9f9",
  bordercolor :'#b3aeae'
};

export default MainButton;
