import React, { useContext, useEffect, useState } from "react";

import Layout from "../screen/Layout/HomeLayout";
import HomeLayout from "../screen/Layout/HomeLayout";
import { UserContext } from "../context/User";
import { TYPE } from "../utility/maroneDefine";
import { useLocation } from "react-router-dom";
import {
  get_userInfoForDevice,
  login,
  update_userdevice,
} from "../service/UserService";
import PrivacyInfocontainer from "../container/PrivacyInfocontainer";
import PrevLayout from "../screen/Layout/PrevLayout";
import Humanregistercontainer from "../container/Humanregistercontainer";

const Humanregisterpage = () => {
  const { user, dispatch2 } = useContext(UserContext);

  return (
    <PrevLayout menu={true} bottom={false} header={true} headername={'공제대상 추가'} headerdetail={false}>
      <Humanregistercontainer />
    </PrevLayout>
  );
};

export default Humanregisterpage;
