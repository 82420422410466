export const colors = {
  white: "#ffffff",
  main: "#FF4E19",
  grey: "#a1a1a1",
  tranparentmain: "#ff131399",
  black: "#000",
  reviewbackground: "#FFF1ED",
  reviewcheckbackground: "#588CFF",
  reviewHighbackground: "#ff4e1959",
  reviewLowbackground: "#FF4E1936",
  maintaxcolor: "#fff",
  mainbuttoncolor: "#3268f3",
};

export const fonts ={
    BLACK :"Pretendard-Black",
    BOLD : "Pretendard-Bold",
    SEMIBOLD : "Pretendard-SemiBold",
    REGULAR : "Pretendard-Regular",

}

export const theme = {
    background : colors.white,
    main : colors.main,
    white :colors.white,
    black : colors.black,
    transparentmain :colors.tranparentmain,
    grey : colors.grey,
    REVIEWBACKGROUND : colors.reviewbackground,
    REVIEWCHECKBACKGROUND : colors.reviewcheckbackground,
    REVIEWHIGHBACKGROUND : colors.reviewHighbackground,
    REVIEWLOWBACKGROUND : colors.reviewLowbackground,
    SEMIBOLD : fonts.SEMIBOLD,
    REGULAR : fonts.REGULAR,
   

}

