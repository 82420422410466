import React, {
  useState,
  useEffect,
  useContext,
  useLayoutEffect,
  useRef,
} from "react";
import {
  HashRouter,
  Route,
  Redirect,
  BrowserRouter,
  Routes,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Swipe from "../common/Swipe";

import styled from "styled-components";

import Guide from "../components/Guide";
import Swipe2 from "../common/Swipe2";
import Button from "../common/Button";
import { colors, theme } from "../theme/theme";
import InputItem from "../components/InputItem";
import InputReturn from "../components/InputReturn";
import Next from "../components/Next";
import { imageDB } from "../utility/imageData";
import { Mobile, PC } from "../components/Responsive";
import { getIncome, getPrivacy, getVat, getVatTax, getVatTax2, getWeTax, getWithholdingTax, getYear } from "../utility/api";
import { UserContext } from "../context/User";
import { add_tax } from "../service/UserService";
import { useSleep } from "../utility/common";

const Container = styled.div`
  padding-top: 70px;
  background: white;
  height: ${({ height }) => height - 30}px;
`;

const PCContainer = styled.div`
width: 27%;
margin: 0px 37%;
  background: white;
  padding-top: 80px;
  height: ${({ height }) => height - 80}px;
`;

const process = [
  "세금 신고에 필요한 필수 자료를 기관으로부터 가져오고 있습니다",
  "세금 신고에 필요한 필수 자료를 기관으로부터 가져왔습니다",
];

const Info = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 10%;
  bottom :350px;
  align-items: center;
  text-align: left;
  color: #788391;
  flex-direction:column;
`;
  


const Seekcontainer = () => {
  const navigate = useNavigate();
  const [height, setHeight] = useState(window.innerHeight);

  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(60);
  const [msg, setMsg] = useState(process[0]);
  const [refresh, setRefresh] = useState(1);
  const [complete, setComplete] = useState(false);

  const [government24, setGovernment24] = useState(false);
  const [hometax, setHometax] = useState(false);
  const [wetax, setWetax] = useState(false);

  const { user, dispatch2 } = useContext(UserContext);

  const message = {
    fail: ".......",
    success: "정보가져오기를성공하였습니다",
  };

  const [government24message, setGovernment24message] = useState(message.fail);
  const [hometaxmessage, setHometaxmessage] = useState(".....");
  const [wetaxmessage, setWetaxmessage] = useState(".......");

  const [yeardata, setYeardata] = useState({});
  const [incomedata, setIncomedata] = useState({});
  const [localtaxdata, setLocaltaxdata] = useState({});
  const [vatdata, setVatdata] = useState({});
  const [vatdata2, setVatdata2] = useState({});
  const [vatdata3, setVatdata3] = useState({});

  const [vatdata4, setVatdata4] = useState({});
  const [vatdata5, setVatdata5] = useState({});
  const [vatdata6, setVatdata6] = useState({});
  

  const [withholdingdata, setWithholdingdata] = useState({});
  const [withholdingdata2, setWithholdingdata2] = useState({});
  const [withholdingdata3, setWithholdingdata3] = useState({});

  const [yearsuccess, setYearsuccess] = useState(false);
  const [incomesuccess, setIncomesuccess] = useState(false);
  const [vatsuccess, setVatsuccess] = useState(false);

  const _handlenext = async () => {
    if (complete) {
      const username = user.name;
      const phone = user.phone;
      const bizrealstate = user.bizrealstate;
      const bizhumancost = user.bizhumancost;
      const biznum = user.biznum;
      const bank = user.bank;
      const hometaxid = user.hometaxid;
      const hometaxpw = user.hometaxpw;
      const wetaxid = user.wetaxid;
      const wetaxpw = user.wetaxpw;
      const addtax = await add_tax({
        username,
        phone,
        bank,
        hometaxid,
        hometaxpw,
        wetaxid,
        wetaxpw,
        biznum,
        bizrealstate,
        bizhumancost,
        yeardata,
        incomedata,
        vatdata,
        vatdata2,
        vatdata3,
        vatdata4,
        vatdata5,
        vatdata6,
        withholdingdata,
        withholdingdata2,
        withholdingdata3,
        localtaxdata,
      });

      navigate("/newhome");
    }
  };
  useEffect(() => {
    setMsg(msg);

    setHometaxmessage(hometaxmessage);
    setWetaxmessage(wetaxmessage);

    setYeardata(yeardata);
    setIncomedata(incomedata);
    setVatdata(vatdata);
    setVatdata2(vatdata2);
    setVatdata3(vatdata3);
    setVatdata4(vatdata4);
    setVatdata5(vatdata5);
    setVatdata6(vatdata6);


    setWithholdingdata(withholdingdata);
    setWithholdingdata2(withholdingdata2);
    setWithholdingdata3(withholdingdata3);

    setLocaltaxdata(localtaxdata);

    // if (hometaxmessage == message.success) {
    //   setComplete(true);
    // }
  }, [refresh]);
  useEffect(() => {
    user["name"] = localStorage.getItem("name");
    user["phone"] = localStorage.getItem("phone");
    user["phoneorg"] = localStorage.getItem("phoneorg");
    user["jumin"] = localStorage.getItem("jumin");
    user["hometaxid"] = localStorage.getItem("hometaxid");
    user["hometaxpw"] = localStorage.getItem("hometaxpw");
    user["wetaxid"] = localStorage.getItem("wetaxid");
    user["wetaxpw"] = localStorage.getItem("wetaxpw");
    user["bank"] = localStorage.getItem("bank");
    user["authtype"] = localStorage.getItem("authtype");

    user["biznum"] = JSON.parse(localStorage.getItem("biznum"));
    user["hometax"] = localStorage.getItem("hometax");
    user["wetax"] = localStorage.getItem("wetax");

    user["bizrealstate"] = localStorage.getItem("bizrealstate");
    user["bizhumancost"] = localStorage.getItem("bizhumancost");

    dispatch2(user);

    console.log("userinforamtion", user);

    localStorage.setItem("name", "");
    localStorage.setItem("phone", "");
    localStorage.setItem("phoneorg", "");
    localStorage.setItem("jumin", "");
    localStorage.setItem("hometaxid", "");
    localStorage.setItem("hometaxpw", "");
    localStorage.setItem("wetaxid", "");
    localStorage.setItem("wetaxpw", "");
    localStorage.setItem("bank", "");
    localStorage.setItem("authtype", "");
    localStorage.setItem("biznum", []);
    localStorage.setItem("hometax", "");
    localStorage.setItem("wetax", "");
    localStorage.setItem("bizrealstate", "");
    localStorage.setItem("bizhumancost", "");
  }, []);

  function AuthErroMsgFind(errMsg) {
    if (
      errMsg.indexOf("LOGIN-203") != -1 ||
      errMsg.indexOf("LOGIN-003") != -1 ||
      errMsg.indexOf("LOGIN-999") != -1 ||
      errMsg.indexOf("B6001-999") != -1 ||
      errMsg.indexOf("FDR02-999") != -1
    ) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    const countdown = setInterval(() => {
      if (parseInt(seconds) > 0) {
        setSeconds(parseInt(seconds) - 1);
        setComplete(false);
        setMsg(process[0]);
        setRefresh((refresh) => refresh + 1);
      }
      if (parseInt(seconds) === 0) {
        setMsg(process[1]);

        setComplete(true);
        setRefresh((refresh) => refresh + 1);

        if (parseInt(seconds) === 0) {
          clearInterval(countdown);
        }
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, [minutes, seconds]);

  // //연말정산 신청
  useEffect(() => {
    let ncount = 0;
    const countdown = setInterval(() => {
      if (ncount < 7) {
        async function FetchData() {
          ncount++;
          const yearkey = user.hometax;
          const yeardata = await getYear({ user, yearkey });
          await useSleep(3000);
          console.log("hometax get data year data", yeardata);

          if (AuthErroMsgFind(yeardata.data.common.errMsg)) {
            setRefresh((refresh) => refresh + 1);
            console.log("인증실패");
          } else {
            if (yeardata.data.common.errMsg != "") {
              console.log("데이타수집실패");
              setYeardata({});
              //  setYearsuccess(true);
              setHometaxmessage(message.fail);
              setRefresh((refresh) => refresh + 1);
              clearInterval(countdown);
            } else {
              console.log("데이타수집성공");
              setYeardata(yeardata.data.data);
              //  setYearsuccess(true);
              setHometaxmessage(message.success);
              setRefresh((refresh) => refresh + 1);
              clearInterval(countdown);
            }
          }
        }
        FetchData();
      }
    }, 10000);

    return () => clearInterval(countdown);
  }, []);
  // 소득금액 신청
  useEffect(() => {
    let ncount = 0;
    const countdown = setInterval(() => {
      if (ncount < 7) {
        async function FetchData() {
          ncount++;

          const incomekey = user.hometax;
          const incomedata = await getIncome({ user, incomekey });

          console.log("hometax get data income data", incomedata);

          if (AuthErroMsgFind(incomedata.data.common.errMsg)) {
            setRefresh((refresh) => refresh + 1);
            console.log("인증실패");
          } else {
            if (incomedata.data.common.errMsg != "") {
              console.log("데이타수집실패");
              setIncomedata({});
              // setIncomesuccess(true);
              setHometaxmessage(message.fail);
              setRefresh((refresh) => refresh + 1);
              clearInterval(countdown);
            } else {
              console.log("데이타수집성공");
              setIncomedata(incomedata.data.data);
              // setIncomesuccess(true);
              setHometaxmessage(message.success);
              setRefresh((refresh) => refresh + 1);
              clearInterval(countdown);
            }
          }
        }
        FetchData();
      }
    }, 15000);

    return () => clearInterval(countdown);
  }, []);

  // 부가세 1
  useEffect(() => {
    let ncount = 0;
    const countdown = setInterval(() => {
      if (ncount < 7) {
        async function FetchData() {
          if (user.biznum.length == 0) {
            console.log("1사업자 없어서 데이타수집안함");
            setVatdata({});
            // setIncomesuccess(true);
            setHometaxmessage(message.fail);
            setRefresh((refresh) => refresh + 1);
            clearInterval(countdown);
          }

          ncount++;

          const taxkey = user.hometax;
          const biznum = user.biznum[0];
          const vatdata = await getVatTax({ user, taxkey, biznum });

          console.log("hometax get data vat data", vatdata);

          if (AuthErroMsgFind(vatdata.data.common.errMsg)) {
            setRefresh((refresh) => refresh + 1);
            console.log("인증실패");
          } else {
            if (vatdata.data.common.errMsg != "") {
              console.log("데이타수집실패");
              setVatdata({});
              // setIncomesuccess(true);
              setHometaxmessage(message.fail);
              setRefresh((refresh) => refresh + 1);
              clearInterval(countdown);
            } else {
              console.log("데이타수집성공");
              setVatdata(vatdata.data.data);
              // setIncomesuccess(true);
              setHometaxmessage(message.success);
              setRefresh((refresh) => refresh + 1);
              clearInterval(countdown);
            }
          }
        }
        FetchData();
      }
    }, 15000);

    return () => clearInterval(countdown);
  }, []);

  // 부가세 2
  useEffect(() => {
    let ncount = 0;
    const countdown = setInterval(() => {
      if (ncount < 7) {
        async function FetchData() {
          if (user.biznum.length <= 1) {
            console.log("2사업자 없어서 데이타수집안함");
            setVatdata2({});
            // setIncomesuccess(true);
            setHometaxmessage(message.fail);
            setRefresh((refresh) => refresh + 1);
            clearInterval(countdown);
          }

          ncount++;

          const taxkey = user.hometax;
          const biznum = user.biznum[1];
          const vatdata = await getVatTax({ user, taxkey, biznum });

          console.log("hometax get data vat data", vatdata);

          if (AuthErroMsgFind(vatdata.data.common.errMsg)) {
            setRefresh((refresh) => refresh + 1);
            console.log("인증실패");
          } else {
            if (vatdata.data.common.errMsg != "") {
              console.log("데이타수집실패");
              setVatdata2({});
              // setIncomesuccess(true);
              setHometaxmessage(message.fail);
              setRefresh((refresh) => refresh + 1);
              clearInterval(countdown);
            } else {
              console.log("데이타수집성공");
              setVatdata2(vatdata.data.data);
              // setIncomesuccess(true);
              setHometaxmessage(message.success);
              setRefresh((refresh) => refresh + 1);
              clearInterval(countdown);
            }
          }
        }
        FetchData();
      }
    }, 15000);

    return () => clearInterval(countdown);
  }, []);

  // 부가세 3
  useEffect(() => {
    let ncount = 0;
    const countdown = setInterval(() => {
      if (ncount < 7) {
        async function FetchData() {
          if (user.biznum.length <= 2) {
            console.log("3사업자 없어서 데이타수집안함");
            setVatdata3({});
            // setIncomesuccess(true);
            setHometaxmessage(message.fail);
            setRefresh((refresh) => refresh + 1);
            clearInterval(countdown);
          }

          ncount++;

          const taxkey = user.hometax;
          const biznum = user.biznum[2];
          const vatdata = await getVatTax({ user, taxkey, biznum });

          console.log("hometax get data vat data", vatdata);

          if (AuthErroMsgFind(vatdata.data.common.errMsg)) {
            setRefresh((refresh) => refresh + 1);
            console.log("인증실패");
          } else {
            if (vatdata.data.common.errMsg != "") {
              console.log("데이타수집실패");
              setVatdata3({});
              // setIncomesuccess(true);
              setHometaxmessage(message.fail);
              setRefresh((refresh) => refresh + 1);
              clearInterval(countdown);
            } else {
              console.log("데이타수집성공");
              setVatdata3(vatdata.data.data);
              // setIncomesuccess(true);
              setHometaxmessage(message.success);
              setRefresh((refresh) => refresh + 1);
              clearInterval(countdown);
            }
          }
        }
        FetchData();
      }
    }, 15000);

    return () => clearInterval(countdown);
  }, []);

  // 부가세 4
  useEffect(() => {
    let ncount = 0;
    const countdown = setInterval(() => {
      if (ncount < 7) {
        async function FetchData() {
          if (user.biznum.length == 0) {
            console.log("1사업자 없어서 데이타수집안함");
            setVatdata({});
            // setIncomesuccess(true);
            setHometaxmessage(message.fail);
            setRefresh((refresh) => refresh + 1);
            clearInterval(countdown);
          }

          ncount++;

          const taxkey = user.hometax;
          const biznum = user.biznum[0];
          const vatdata = await getVatTax2({ user, taxkey, biznum });

          console.log("hometax get data vat data", vatdata);

          if (AuthErroMsgFind(vatdata.data.common.errMsg)) {
            setRefresh((refresh) => refresh + 1);
            console.log("인증실패");
          } else {
            if (vatdata.data.common.errMsg != "") {
              console.log("데이타수집실패");
              setVatdata4({});
              // setIncomesuccess(true);
              setHometaxmessage(message.fail);
              setRefresh((refresh) => refresh + 1);
              clearInterval(countdown);
            } else {
              console.log("데이타수집성공");
              setVatdata4(vatdata.data.data);
              // setIncomesuccess(true);
              setHometaxmessage(message.success);
              setRefresh((refresh) => refresh + 1);
              clearInterval(countdown);
            }
          }
        }
        FetchData();
      }
    }, 10000);

    return () => clearInterval(countdown);
  }, []);

  // 부가세 5
  useEffect(() => {
    let ncount = 0;
    const countdown = setInterval(() => {
      if (ncount < 7) {
        async function FetchData() {
          if (user.biznum.length <= 1) {
            console.log("2사업자 없어서 데이타수집안함");
            setVatdata2({});
            // setIncomesuccess(true);
            setHometaxmessage(message.fail);
            setRefresh((refresh) => refresh + 1);
            clearInterval(countdown);
          }

          ncount++;

          const taxkey = user.hometax;
          const biznum = user.biznum[1];
          const vatdata = await getVatTax2({ user, taxkey, biznum });

          console.log("hometax get data vat data", vatdata);

          if (AuthErroMsgFind(vatdata.data.common.errMsg)) {
            setRefresh((refresh) => refresh + 1);
            console.log("인증실패");
          } else {
            if (vatdata.data.common.errMsg != "") {
              console.log("데이타수집실패");
              setVatdata5({});
              // setIncomesuccess(true);
              setHometaxmessage(message.fail);
              setRefresh((refresh) => refresh + 1);
              clearInterval(countdown);
            } else {
              console.log("데이타수집성공");
              setVatdata5(vatdata.data.data);
              // setIncomesuccess(true);
              setHometaxmessage(message.success);
              setRefresh((refresh) => refresh + 1);
              clearInterval(countdown);
            }
          }
        }
        FetchData();
      }
    }, 10000);

    return () => clearInterval(countdown);
  }, []);

  // 부가세 6
  useEffect(() => {
    let ncount = 0;
    const countdown = setInterval(() => {
      if (ncount < 7) {
        async function FetchData() {
          if (user.biznum.length <= 2) {
            console.log("3사업자 없어서 데이타수집안함");
            setVatdata6({});
            // setIncomesuccess(true);
            setHometaxmessage(message.fail);
            setRefresh((refresh) => refresh + 1);
            clearInterval(countdown);
          }

          ncount++;

          const taxkey = user.hometax;
          const biznum = user.biznum[2];
          const vatdata = await getVatTax2({ user, taxkey, biznum });

          console.log("hometax get data vat data", vatdata);

          if (AuthErroMsgFind(vatdata.data.common.errMsg)) {
            setRefresh((refresh) => refresh + 1);
            console.log("인증실패");
          } else {
            if (vatdata.data.common.errMsg != "") {
              console.log("데이타수집실패");
              setVatdata6({});
              // setIncomesuccess(true);
              setHometaxmessage(message.fail);
              setRefresh((refresh) => refresh + 1);
              clearInterval(countdown);
            } else {
              console.log("데이타수집성공");
              setVatdata6(vatdata.data.data);
              // setIncomesuccess(true);
              setHometaxmessage(message.success);
              setRefresh((refresh) => refresh + 1);
              clearInterval(countdown);
            }
          }
        }
        FetchData();
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, []);

  // 원천세 1 신청
  useEffect(() => {
    let ncount = 0;
    const countdown = setInterval(() => {
      if (ncount < 7) {
        async function FetchData() {
          ncount++;

          if (user.biznum.length == 0) {
            console.log("1사업자 없어서 원천세 데이타수집안함");
            setWithholdingdata({});
            // setIncomesuccess(true);
            setHometaxmessage(message.fail);
            setRefresh((refresh) => refresh + 1);
            clearInterval(countdown);
          }

          const taxkey = user.hometax;
          const biznum = user.biznum[0];

          const withholdingdata = await getWithholdingTax({
            user,
            taxkey,
            biznum,
          });

          console.log("hometax get data withholding data", withholdingdata);

          if (AuthErroMsgFind(withholdingdata.data.common.errMsg)) {
            setRefresh((refresh) => refresh + 1);
            console.log("withholding 1 인증실패");
          } else {
            if (withholdingdata.data.common.errMsg != "") {
              console.log(" withholding 1  데이타수집실패");
              setWithholdingdata({});
              // setIncomesuccess(true);
              setHometaxmessage(message.fail);
              setRefresh((refresh) => refresh + 1);
              clearInterval(countdown);
            } else {
              console.log(" withholding 1  데이타수집성공");
              setWithholdingdata(withholdingdata.data.data);
              // setIncomesuccess(true);
              setHometaxmessage(message.success);
              setRefresh((refresh) => refresh + 1);
              clearInterval(countdown);
            }
          }
        }
        FetchData();
      }
    }, 5000);

    return () => clearInterval(countdown);
  }, []);

  // 원천세 2 신청
  useEffect(() => {
    let ncount = 0;
    const countdown = setInterval(() => {
      if (ncount < 7) {
        async function FetchData() {
          ncount++;

          if (user.biznum.length <= 1) {
            console.log("2사업자 없어서 원천세 데이타수집안함");
            setWithholdingdata2({});
            // setIncomesuccess(true);
            setHometaxmessage(message.fail);
            setRefresh((refresh) => refresh + 1);
            clearInterval(countdown);
          }

          const taxkey = user.hometax;
          const biznum = user.biznum[1];

          const withholdingdata = await getWithholdingTax({
            user,
            taxkey,
            biznum,
          });

          console.log("hometax get data withholding 2 data", withholdingdata);

          if (AuthErroMsgFind(withholdingdata.data.common.errMsg)) {
            setRefresh((refresh) => refresh + 1);
            console.log("withholding 2  인증실패");
          } else {
            if (withholdingdata.data.common.errMsg != "") {
              console.log(" withholding 2  데이타수집실패");
              setWithholdingdata2({});
              // setIncomesuccess(true);
              setHometaxmessage(message.fail);
              setRefresh((refresh) => refresh + 1);
              clearInterval(countdown);
            } else {
              console.log(" withholding 2  데이타수집성공");
              setWithholdingdata2(withholdingdata.data.data);
              // setIncomesuccess(true);
              setHometaxmessage(message.success);
              setRefresh((refresh) => refresh + 1);
              clearInterval(countdown);
            }
          }
        }
        FetchData();
      }
    }, 7000);

    return () => clearInterval(countdown);
  }, []);

  // 원천세 3 신청
  useEffect(() => {
    let ncount = 0;
    const countdown = setInterval(() => {
      if (ncount < 7) {
        async function FetchData() {
          ncount++;

          if (user.biznum.length <= 2) {
            console.log("3사업자 없어서 원천세 데이타수집안함");
            setWithholdingdata3({});
            // setIncomesuccess(true);
            setHometaxmessage(message.fail);
            setRefresh((refresh) => refresh + 1);
            clearInterval(countdown);
          }

          const taxkey = user.hometax;
          const biznum = user.biznum[2];

          const withholdingdata = await getWithholdingTax({
            user,
            taxkey,
            biznum,
          });

          console.log("hometax get data withholding 3 data", withholdingdata);

          if (AuthErroMsgFind(withholdingdata.data.common.errMsg)) {
            setRefresh((refresh) => refresh + 1);
            console.log("withholding 3  인증실패");
          } else {
            if (withholdingdata.data.common.errMsg != "") {
              console.log("withholding 3  데이타수집실패");
              setWithholdingdata3({});
              setHometaxmessage(message.fail);
              setRefresh((refresh) => refresh + 1);
              clearInterval(countdown);
            } else {
              console.log("withholding 3  데이타수집성공");
              setWithholdingdata3(withholdingdata.data.data);
              setHometaxmessage(message.success);
              setRefresh((refresh) => refresh + 1);
              clearInterval(countdown);
            }
          }
        }
        FetchData();
      }
    }, 6000);

    return () => clearInterval(countdown);
  }, []);

  // // 지방세 신청
  useEffect(() => {
    let ncount = 0;
    const countdown2 = setInterval(() => {
      if (ncount < 3) {
        async function FetchData() {
          ncount++;
          const wetaxkey = user.wetax;
          console.log("wetaxkey", wetaxkey);
          const localtaxdata = await getWeTax({ user, wetaxkey });
          console.log("localtaxdata", localtaxdata);

          if (AuthErroMsgFind(localtaxdata.data.common.errMsg)) {
            setRefresh((refresh) => refresh + 1);
            console.log("인증실패");
          } else {
            if (localtaxdata.data.common.errMsg != "") {
              console.log("데이타수집실패");
              setLocaltaxdata([]);
              setWetaxmessage(message.fail);
              setRefresh((refresh) => refresh + 1);
              clearInterval(countdown2);
            } else {
              console.log("데이타수집성공");
              setLocaltaxdata(localtaxdata.data.data);
              setWetaxmessage(message.success);
              setRefresh((refresh) => refresh + 1);
              clearInterval(countdown2);
            }
          }
        }
        FetchData();
      }
    }, 15000);

    return () => clearInterval(countdown2);
  }, []);

  return (
    <>
      <Mobile>
        <Container height={height}>
          <Guide
            Font={22}
            main1={"세금 신고에 필요한 개인정보"}
            main2={"를 가져오고 있습니다"}
          ></Guide>

          <img src={imageDB.loading} style={{ width: 100, marginTop: 30 }} />

          <Info>{msg}</Info>
          <Info>
            {seconds != 0 && <>{seconds}초 후에 완료 됩니다</>}
            {seconds == 0 && <>{"      "}</>}
          </Info>

          <Next
            callback={_handlenext}
            enable={true}
            containerStyle={{ position: "absolute", bottom: 0, width: "100%" }}
          />
        </Container>
      </Mobile>
      <PC>
        <PCContainer height={height}>
          <Guide
            Font={22}
            main1={"세금 신고에 필요한 개인정보"}
            main2={"를 가져오고 있습니다"}
          ></Guide>

          <img src={imageDB.loading} style={{ width: 100, marginTop: 30 }} />

          <Info>{msg}</Info>
          <Info>
            {seconds != 0 && <>{seconds}초 후에 완료 됩니다</>}
            {seconds == 0 && <>{"      "}</>}
          </Info>

          <Next
            callback={_handlenext}
            enable={true}
            containerStyle={{ position: "absolute", bottom: 0, width: "30%" }}
          />
        </PCContainer>
      </PC>
    </>
  );
};

export default Seekcontainer;
