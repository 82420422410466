import React,{useState, useEffect} from 'react';
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, Link, useNavigate} from "react-router-dom";
import styled from 'styled-components';

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';


// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import { imageDB } from '../utility/imageData';
import '../screen/css/common.css' 

const Container = styled.div`

`
const swiperStyle={
  position :"relative",
  width :'100%',

}

const Box = styled.div`
  width: 100%;
  height :200px;
  background: ${({ background }) => background};
  border-radius :15px;
`;

const BoxLayer = styled.div`
  font-size: ${({ Size }) => Size}px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  flex-direction: row;
  width: 70%;
  padding: 0px 10%;
  text-align:left;
`;
const ImageLayer = styled.div`
  display: flex;
  flex-direction:row;
  justify-content: flex-end;
  align-items: center;
`;

const DetailView = styled.div`
  width: 30%;
  padding: 10px 20px;
  margin: 0px 10px;
  border :1px solid #3d3939;
  border-radius: 10px;
`;

const Swipe2 = ({containerStyle, items, delaytime, height, Size}) => {

  SwiperCore.use([Autoplay]);

  const navigate = useNavigate();
  const [swiperIndex, setSwiperIndex] = useState(0);

  const _handelnewsdetail = () => {
    navigate("/newsdetail");
  }



  return (
    <Container style={containerStyle}>
      <Swiper
        spaceBetween={10}
        onActiveIndexChange={(swiperCore) => {
          setSwiperIndex(swiperCore.activeIndex);
        }}
        slidesPerView={1}
        loop={true}
        scrollbar={{ draggable: true }}
        style={swiperStyle}
        autoplay={{ delay: delaytime, disableOnInteraction: true }}
      >
        {items.map((data, index) => (
          <SwiperSlide key={index}>
            <Box background={data.background}>
              <BoxLayer Size={Size}>{data.label}</BoxLayer>

              <ImageLayer>
                {data.button == true && (
                  <DetailView onClick={_handelnewsdetail}>{"자세히 보기"}</DetailView>
                )}

                <div style={{ width: "50%" }}>
                  <img
                    src={data.img}
                    style={{ width: data.width, height: data.height }}
                  />
                </div>
              </ImageLayer>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  );

}

Swipe2.defaultProps = {
  Size: 23,
};

export default Swipe2;
