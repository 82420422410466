import React, {
  useState,
  useEffect,
  useContext,
  useLayoutEffect,
  useRef,
} from "react";
import {
  HashRouter,
  Route,
  Redirect,
  BrowserRouter,
  Routes,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Swipe from "../common/Swipe";

import styled from "styled-components";

import Guide from "../components/Guide";
import Swipe2 from "../common/Swipe2";
import Button from "../common/Button";
import { colors, theme } from "../theme/theme";
import InputItem from "../components/InputItem";
import InputReturn from "../components/InputReturn";
import Next from "../components/Next";
import { Mobile, PC } from "../components/Responsive";
import useDimension from "../common/UseDimension";
import SelectItem from "../components/SelectItem";
import { UserContext } from "../context/User";
import { get_userInfoForUID } from "../service/UserService";
import { BiCalendarEdit } from "react-icons/bi";
import { FcInfo } from "react-icons/fc";
import { imageDB } from "../utility/imageData";
import Image from "../common/Image";

import { FaCheckCircle } from "react-icons/fa";
import { setRef } from "@mui/material";
import Info from "../common/Info";
import { BiznoCheck } from "../utility/common";
import ErrorModalEx from "../components/ErrorModalEx";

const Container = styled.div`
  padding: 80px 20px;
  background: white;

`;
 const PCContainer = styled.div`
    width: 27%;
    margin: 0px 37%;
   background: white;
   padding-top: 80px;
   height: 100%;
   height: ${({ height }) => height - 10}px;

   border: 1px solid;
 `;
const LabelItem = styled.div`
  line-height: 1.5;
  font-size: 1.2rem;
  text-decoration-line: none;
  color: #6b6b6b;
  padding-left: 10px;
  font-family:Pretendard-Bold;
`;

const Box = styled.div`
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;

  margin: 10px;
  border: ${({ enablebiz }) =>
    enablebiz == true ? "2px solid #3268f3;" : "1px solid #ededed;"};
`;

const DisableBox = styled.div`
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;

  margin: 10px;
  border: ${({ disablebiz }) =>
    disablebiz == true ? "2px solid #3268f3;" : "1px solid #ededed;"};
`;



 

const phoneorg = ["SKT", "KT", "LGU+", "기타"];

const BizInfocontainer = () => {


  const navigate = useNavigate();
  
  const {user, dispatch2 } = useContext(UserContext);
  const [height, setHeight] = useState(window.innerHeight);

  const [bizenable, setBizenable] = useState(false);
  const [bizdisable, setBizdisable] = useState(false);
  
  const [enable, setEnable] = useState(false);
  const [refresh, setRefresh] = useState(1);
  const [enablerefresh, setEnablerefresh] = useState(1);


  const [biz1num, setBiz1num] = useState("");
  const [biz2num, setBiz2num] = useState("");
  const [biz3num, setBiz3num] = useState("");
  const [biz1focus, setBiz1focus] = useState(false);
  const [biz2focus, setBiz2focus] = useState(false);
  const [biz3focus, setBiz3focus] = useState(false);

  const [bizmorecount, setBizcmoreount] = useState(0);
  

  const [error, setError] = useState(false);
  const [errormsg, setErrormsg] = useState("");

  
  const _handlenext = async() => {

    if(!BiznoCheck(biz1num)){
      setError(true);
      setErrormsg(
        "잘못된  사업자 등록번호입니다. 올바른 사업자 등록번호를 입력해주세요"
      );
      setRefresh((refresh) => refresh + 1);

      return;
    }

    if(!BiznoCheck(biz2num)){
      setError(true);
      setErrormsg(
        "잘못된  사업자 등록번호입니다. 올바른 사업자 등록번호를 입력해주세요"
      );
      setRefresh((refresh) => refresh + 1);

      return;
    }


    if(!BiznoCheck(biz3num)){
      setError(true);
      setErrormsg(
        "잘못된  사업자 등록번호입니다. 올바른 사업자 등록번호를 입력해주세요"
      );
      setRefresh((refresh) => refresh + 1);

      return;
    }


    let biznum = [];
    biznum.push(biz1num);

    if (biz2num != "") {
      biznum.push(biz2num);
    }
    if (biz3num != "") {
      biznum.push(biz3num);
    }

    user["biznum"] = biznum;
    dispatch2(user);

    if (bizenable) {
      navigate("/biztypeinfo");
    } else {
      navigate("/authselect");
    }
    

  }

  const _handlebiz = (type) => {

    setBizenable(true);
    setBizdisable(false);
    setRefresh((refresh) => refresh + 1);
  }

  const _handledisablebiz = (type) => {

    setBiz1num("");
    setBiz2num("");
    setBiz3num("");

    setBizdisable(true);
    setBizenable(false);
    setRefresh((refresh) => refresh + 1);
  };

  

  useEffect(() => {
    setBizenable(bizenable);
    setBizdisable(bizdisable);
    setBizcmoreount(bizmorecount);

    if (bizdisable) {
      setEnable(true);
      setEnablerefresh((enablerefresh) => enablerefresh + 1);
    } else {
      if (biz1num != '') {


        setEnable(true);
        setEnablerefresh((enablerefresh) => enablerefresh + 1);
      } else {
        setEnable(false);
        setEnablerefresh((enablerefresh) => enablerefresh + 1);
      }
    }
  }, [refresh])
  
  useEffect(() => {
    setEnable(enable);
  }, [enablerefresh]);

 
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);


  const _handlebizadd = () => {
    let morecount = 0;
    morecount = bizmorecount;

    morecount += 1;

    setBizcmoreount(morecount);

    setRefresh((refresh) => refresh + 1);
  }

  const errormodalcallback = () => {
    setError(false);
    setRefresh((refresh) => refresh + 1);
  };

  
  return (
    <>
      {error == true && (
        <ErrorModalEx callback={errormodalcallback} msg={errormsg} />
      )}

      <Mobile>
        <Container height={height}>
          <Guide
            main1={"사업장 정보를  입력해주세요"}
            main2={""}
            sub={""}
          ></Guide>

          <Info
            content={"사업장을 보유하고 계시면 사업장 보유를 선택해주세여"}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              className="button"
              enablebiz={bizenable}
              onClick={() => {
                _handlebiz(true);
              }}
            >
              <Image
                source={imageDB.logo}
                containerStyle={{ width: 55, paddingLeft: 10, height: 45 }}
              />
              <div style={{ color: "#999999" }}>사업장 보유</div>

              {bizenable == true && (
                <div style={{ position: "absolute" }}>
                  <FaCheckCircle color={"#5974e3"} size={30} />
                </div>
              )}
            </Box>
            <DisableBox
              className="button"
              disablebiz={bizdisable}
              onClick={() => {
                _handledisablebiz(true);
              }}
            >
              <Image
                source={imageDB.logo}
                containerStyle={{ width: 55, paddingLeft: 10, height: 45 }}
              />
              <div style={{ color: "#999999" }}>사업장 보유 안함</div>

              {bizdisable == true && (
                <div style={{ position: "absolute" }}>
                  <FaCheckCircle color={"#5974e3"} size={30} />
                </div>
              )}
            </DisableBox>
          </div>
          {bizenable == true && (
            <>
              <Info
                content={
                  "사업자를 한개이상 보유하신 경우는 아래 추가 버튼을 눌러 사업자등록번호를 입력 해주세요 최대 3개까지 등록 가능하시며, 없으면입력하지 않으셔도 됩니다."
                }
              />

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  marginLeft: "5%",
                }}
              >
                <div
                  style={{
                    marginTop: 20,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <BiCalendarEdit size={20} />
                  <LabelItem>{"사업자 등록번호(숫자만 필수입력)"}</LabelItem>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 10,
                  }}
                >
                  <div>
                    <input
                      type="number"
                      value={biz1num}
                      style={{ width: "260px", marginLeft: 10 }}
                      onChange={(e) => {
                        setBiz1num(e.target.value);
                        setRefresh((refresh) => refresh + 1);
                      }}
                      onFocus={() => setBiz1focus(true)}
                      onBlur={() => setBiz1focus(false)}
                      className="inputcommon"
                      placeholder={"사업자 등록번호를 입력해주세요"}
                      returnKeyType={"다음"}
                      autoCapitalize="none"
                      autoCorrect={false}
                    ></input>

                    {biz1focus == true ? (
                      <div className="inputFocus"></div>
                    ) : (
                      <div className="inputNoFocus"></div>
                    )}
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <div
                  className="button"
                  style={{
                    padding: "5px 15px",
                    borderRadius: "5px",
                    marginTop: "10px",
                    marginRight:"10px",
                    color: "#5d5d5d",
                    borderRadius: "5px",
                    border: "1px solid",
                  }}
                  onClick={_handlebizadd}
                >
                  사업장 추가
                </div>
              </div>

              {bizmorecount >= 1 && (
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      marginLeft: "5%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: 10,
                      }}
                    >
                      <div>
                        <input
                          type="number"
                          value={biz2num}
                          style={{ width: "260px", marginLeft: 10 }}
                          onChange={(e) => {
                            setBiz2num(e.target.value);
                            setRefresh((refresh) => refresh + 1);
                          }}
                          onFocus={() => setBiz2focus(true)}
                          onBlur={() => setBiz2focus(false)}
                          className="inputcommon"
                          placeholder={"사업자 등록번호를 입력해주세요"}
                          returnKeyType={"다음"}
                          autoCapitalize="none"
                          autoCorrect={false}
                        ></input>

                        {biz2focus == true ? (
                          <div className="inputFocus"></div>
                        ) : (
                          <div className="inputNoFocus"></div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {bizmorecount >= 2 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    marginLeft: "5%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: 10,
                    }}
                  >
                    <div>
                      <input
                        type="number"
                        value={biz3num}
                        style={{ width: "260px", marginLeft: 10 }}
                        onChange={(e) => {
                          setBiz3num(e.target.value);
                          setRefresh((refresh) => refresh + 1);
                        }}
                        onFocus={() => setBiz3focus(true)}
                        onBlur={() => setBiz3focus(false)}
                        className="inputcommon"
                        placeholder={"사업자 등록번호를 입력해주세요"}
                        returnKeyType={"다음"}
                        autoCapitalize="none"
                        autoCorrect={false}
                      ></input>

                      {biz3focus == true ? (
                        <div className="inputFocus"></div>
                      ) : (
                        <div className="inputNoFocus"></div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          <Next
            callback={_handlenext}
            enable={enable}
            containerStyle={{ width: "100%", marginTop: 30 }}
          />
        </Container>
      </Mobile>
      <PC>
        <PCContainer height={height}>
          <Guide
            main1={"사업장 정보를  입력해주세요"}
            main2={""}
            sub={""}
          ></Guide>

          <Info
            content={"사업장을 보유하고 계시면 사업장 보유를 선택해주세여"}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              className="button"
              enablebiz={bizenable}
              onClick={() => {
                _handlebiz(true);
              }}
            >
              <Image
                source={imageDB.logo}
                containerStyle={{ width: 55, paddingLeft: 10, height: 45 }}
              />
              <div style={{ color: "#999999" }}>사업장 보유</div>

              {bizenable == true && (
                <div style={{ position: "absolute" }}>
                  <FaCheckCircle color={"#5974e3"} size={30} />
                </div>
              )}
            </Box>
            <DisableBox
              className="button"
              disablebiz={bizdisable}
              onClick={() => {
                _handledisablebiz(true);
              }}
            >
              <Image
                source={imageDB.logo}
                containerStyle={{ width: 55, paddingLeft: 10, height: 45 }}
              />
              <div style={{ color: "#999999" }}>사업장 보유 안함</div>

              {bizdisable == true && (
                <div style={{ position: "absolute" }}>
                  <FaCheckCircle color={"#5974e3"} size={30} />
                </div>
              )}
            </DisableBox>
          </div>
          {bizenable == true && (
            <>
              <Info
                content={
                  "사업자를 한개이상 보유하신 경우는 아래 추가 버튼을 눌러 사업자등록번호를 입력 해주세요 최대 3개까지 등록 가능하시며, 없으면입력하지 않으셔도 됩니다."
                }
              />

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  marginLeft: "5%",
                }}
              >
                <div
                  style={{
                    marginTop: 20,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <BiCalendarEdit size={20} />
                  <LabelItem>{"사업자 등록번호(숫자만 필수입력)"}</LabelItem>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 10,
                  }}
                >
                  <div>
                    <input
                      type="text"
                      value={biz1num}
                      style={{ width: "260px", marginLeft: 10 }}
                      onChange={(e) => {
                        setBiz1num(e.target.value);
                        setRefresh((refresh) => refresh + 1);
                      }}
                      onFocus={() => setBiz1focus(true)}
                      onBlur={() => setBiz1focus(false)}
                      className="inputcommon"
                      placeholder={"사업자 등록번호를 입력해주세요"}
                      returnKeyType={"다음"}
                      autoCapitalize="none"
                      autoCorrect={false}
                    ></input>

                    {biz1focus == true ? (
                      <div className="inputFocus"></div>
                    ) : (
                      <div className="inputNoFocus"></div>
                    )}
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <div
                  className="button"
                  style={{
                    padding: "5px 15px",
                    borderRadius: "5px",
                    marginTop: "10px",
                    marginRight:"10px",
                    color: "#5d5d5d",
                    borderRadius: "5px",
                    border: "1px solid",
                  }}
                  onClick={_handlebizadd}
                >
                  사업장 추가
                </div>
              </div>

              {bizmorecount >= 1 && (
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      marginLeft: "5%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: 10,
                      }}
                    >
                      <div>
                        <input
                          type="text"
                          value={biz2num}
                          style={{ width: "260px", marginLeft: 10 }}
                          onChange={(e) => {
                            setBiz2num(e.target.value);
                            setRefresh((refresh) => refresh + 1);
                          }}
                          onFocus={() => setBiz2focus(true)}
                          onBlur={() => setBiz2focus(false)}
                          className="inputcommon"
                          placeholder={"사업자 등록번호를 입력해주세요"}
                          returnKeyType={"다음"}
                          autoCapitalize="none"
                          autoCorrect={false}
                        ></input>

                        {biz2focus == true ? (
                          <div className="inputFocus"></div>
                        ) : (
                          <div className="inputNoFocus"></div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {bizmorecount >= 2 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    marginLeft: "5%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: 10,
                    }}
                  >
                    <div>
                      <input
                        type="text"
                        value={biz3num}
                        style={{ width: "260px", marginLeft: 10 }}
                        onChange={(e) => {
                          setBiz3num(e.target.value);
                          setRefresh((refresh) => refresh + 1);
                        }}
                        onFocus={() => setBiz3focus(true)}
                        onBlur={() => setBiz3focus(false)}
                        className="inputcommon"
                        placeholder={"사업자 등록번호를 입력해주세요"}
                        returnKeyType={"다음"}
                        autoCapitalize="none"
                        autoCorrect={false}
                      ></input>

                      {biz3focus == true ? (
                        <div className="inputFocus"></div>
                      ) : (
                        <div className="inputNoFocus"></div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          <Next
            callback={_handlenext}
            enable={enable}
            containerStyle={{ width: "100%", marginTop: 30 }}
          />
        </PCContainer>
      </PC>
    </>
  );
};

export default BizInfocontainer;
