import React, {
  useState,
  useEffect,
  useContext,
  useLayoutEffect,
  useRef,
} from "react";
import {
  HashRouter,
  Route,
  Redirect,
  BrowserRouter,
  Routes,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Swipe from "../common/Swipe";

import styled from "styled-components";

import Guide from "../components/Guide";
import Swipe2 from "../common/Swipe2";
import Button from "../common/Button";
import { colors, theme } from "../theme/theme";
import InputItem from "../components/InputItem";
import InputReturn from "../components/InputReturn";
import Next from "../components/Next";
import { Mobile, PC } from "../components/Responsive";
import { UserContext } from "../context/User";
import { CheckPhone, get_tax } from "../service/UserService";

import { BiCalendarEdit } from "react-icons/bi";
import { FcInfo } from "react-icons/fc";
import Info from "../common/Info";
import ErrorModalEx from "../components/ErrorModalEx";



const Container = styled.div`
  background: white;
  height: ${({ height }) => height}px;
  padding: 70px 5% 0px;

`;
const PCContainer = styled.div`
width: 27%;
margin: 0px 37%;
  background: white;
  padding-top: 80px;
  height: 100%;
  height: ${({ height }) => height - 80}px;
  border : 1px solid;
`;

const LabelItem = styled.div`
  line-height: 1.5;
  font-size: 1.2rem;
  text-decoration-line: none;
  color: #6b6b6b;
  padding-left: 10px;
  font-family:Pretendard-Bold;
`;



const Logincontainer = () => {
  const navigate = useNavigate();
  const [height, setHeight] = useState(window.innerHeight);
  
  const { user, dispatch2 } = useContext(UserContext);
  
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");


  const [enable, setEnable] = useState(false);
  const [refresh, setRefresh] = useState(1);
  const [enablerefresh, setEnablerefresh] = useState(1);
  
  const [namefocus, setNamefocus] = useState(true);
  const [numberfocus, setNumberfocus] = useState(false);

  const [error, setError] = useState(false);
  const [errormsg, setErrormsg] = useState('');
  
  const _handlenext = () => {
    navigate("/privacyinfo2");
  }

  // const namecallback = (callbackdata) => {
  //   setName(callbackdata);
  //   console.log("username", callbackdata);

  //   if (name != "" && number != "") {
  //     setEnable(true);
  //     setRefresh((refresh) => refresh + 1);
  //   }
  // } 
  // const numbercallback = (callbackdata) => {
  //   setNumber(callbackdata);
  //   console.log("phone", callbackdata);

  //   if (name != "" && number != "") {
  //     setEnable(true);
  //     setRefresh((refresh) => refresh + 1);
  //   }
  // }

  useEffect(() => {

    setError(error);
    setErrormsg(errormsg);

    if (name != "" && number != "" ) {
      setEnable(true);
      console.log("enable...... true");
      setEnablerefresh((enablerefresh) => enablerefresh + 1);
    } else {
      setEnable(false);
      setEnablerefresh((enablerefresh) => enablerefresh + 1);
    }
    
  }, [refresh])

    useEffect(() => {
      setEnable(enable);
    }, [enablerefresh]);
  

  const _handleautologin = () => {
    if (!enable) {
      return;
    }
    _handlelogin();
  }

  const _handlelogin = async () => {
    

    if (number.length != 10 && number.length != 11) {
      setError(true);
      setErrormsg("잘못된 핸드폰입니다. 올바른 핸드폰번호를 입력해주세요");
      setRefresh((refresh) => refresh + 1);
      return;
    }

    
    user["name"] = name;
    user["phone"] = number;




    const username = name;
    const phone = number.replaceAll("-","");
    const useritem = await CheckPhone({ username, phone });


    console.log("useritem", useritem);



    if (useritem == null) {

      setError(true);
      setErrormsg(
        "등록되지 않은 고객이십니다. 등록되신 고객이시라면 청람세무회계 사무소에 등록된 이름과 핸드폰를 다시 확인해주시기 바랍니다"
      );
      setRefresh((refresh) => refresh + 1);
      return;
    }

    user["jumin"] = useritem.jumin;

    dispatch2(user);
    
    const phonedata = number.replaceAll("-", "");
    const taxitem = await get_tax({ username, phonedata });

    if (taxitem.status == 'complete') {
         navigate("/result", { state: { username: name } });
    } else {
       // navigate("/home", { state: { username: name, phone: number } });  
        navigate("/newhome", { state: { username: name, phone: number } });  
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {};
  }, []);
  
  const errormodalcallback = () => {
    setError(false);
    setRefresh((refresh) => refresh + 1);
  }
 
  return (
    <>
      {
        error == true && <ErrorModalEx callback={errormodalcallback} msg={errormsg} />
      }
      <Mobile>
        <Container height={height}>
          <Guide main1={"이미 등록된 사용자 임을 확인합니다"} sub={""}></Guide>

          <Info
            content={
              "청람세무회계사무소에서는 모든 개인 정보를 암호화 처리 하고 있습니다"
            }
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              marginLeft: "5%",
            }}
          >
            <div
              style={{
                marginTop: 50,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BiCalendarEdit size={20} />
              <LabelItem>{"이름"}</LabelItem>
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 10 }}
            >
              <div>
                <input
                  type="text"
                  value={name}
                  style={{ width: "260px", marginLeft: 10 }}
                  onChange={(e) => {
                    setName(e.target.value);
                    setRefresh((refresh) => refresh + 1);
                  }}
                  onFocus={() => setNamefocus(true)}
                  onBlur={() => setNamefocus(false)}
                  className="inputcommon"
                  placeholder={"이름을 입력해주세요"}
                  returnKeyType={"다음"}
                  autoCapitalize="none"
                  autoCorrect={false}
                ></input>

                {namefocus == true ? (
                  <div className="inputFocus"></div>
                ) : (
                  <div className="inputNoFocus"></div>
                )}
              </div>
            </div>
            <div style={{ fontSize: 14, display: "flex", marginTop: 5 }}>
              <div style={{ color: "#ff0000" }}>TIP</div>
              <div style={{ paddingLeft: 5, color: "#959595" }}>
                실명이 입력되어 있는지 확인해주세요
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              marginLeft: "5%",
            }}
          >
            <div
              style={{
                marginTop: 50,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BiCalendarEdit size={20} />
              <LabelItem>{"휴대폰번호(숫자만)"}</LabelItem>
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 10 }}
            >
              <div>
                <input
                  type="number"
                  value={number}
                  style={{ width: "260px", marginLeft: 10 }}
                  onChange={(e) => {
                    setNumber(e.target.value);
                    setRefresh((refresh) => refresh + 1);
                  }}
                  onFocus={() => {
                    setNamefocus(false);
                    setNumberfocus(true);
                  }}
                  onBlur={() => setNumberfocus(false)}
                  className="inputcommon"
                  placeholder={"휴대폰번호을 입력해주세요"}
                  returnKeyType={"다음"}
                  autoCapitalize="none"
                  autoCorrect={false}
                ></input>

                {numberfocus == true ? (
                  <div className="inputFocus"></div>
                ) : (
                  <div className="inputNoFocus"></div>
                )}
              </div>
            </div>
          </div>
          {/* <InputItem
            type={"number"}
            containerStyle={{ marginTop: 50 }}
            label={"휴대폰번호"}
            placeholder={"휴대폰번호를 입력해 주세요"}
            callback={numbercallback}
            callback2={_handleautologin}
          /> */}

          <Next
            callback={_handlelogin}
            enable={enable}
            containerStyle={{
              paddingTop: 60,
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          />
        </Container>
      </Mobile>
      <PC>
        <PCContainer height={height}>
          <Guide main1={"이미 등록된 사용자 임을 확인합니다"} sub={""}></Guide>

          <Info
            content={
              "청람세무회계사무소에서는 모든 개인 정보를 암호화 처리 하고 있습니다"
            }
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              marginLeft: "5%",
            }}
          >
            <div
              style={{
                marginTop: 50,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BiCalendarEdit size={20} />
              <LabelItem>{"이름"}</LabelItem>
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 10 }}
            >
              <div>
                <input
                  type="text"
                  value={name}
                  style={{ width: "350px", marginLeft: 10 }}
                  onChange={(e) => {
                    setName(e.target.value);
                    setRefresh((refresh) => refresh + 1);
                  }}
                  onFocus={() => setNamefocus(true)}
                  onBlur={() => setNamefocus(false)}
                  className="inputcommon"
                  placeholder={"이름을 입력해주세요"}
                  returnKeyType={"다음"}
                  autoCapitalize="none"
                  autoCorrect={false}
                ></input>

                {namefocus == true ? (
                  <div className="inputFocus"></div>
                ) : (
                  <div className="inputNoFocus"></div>
                )}
              </div>
            </div>
            <div style={{ fontSize: 14, display: "flex", marginTop: 5 }}>
              <div style={{ color: "#ff0000" }}>TIP</div>
              <div style={{ paddingLeft: 5, color: "#959595" }}>
                실명이 입력되어 있는지 확인해주세요
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              marginLeft: "5%",
            }}
          >
            <div
              style={{
                marginTop: 50,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BiCalendarEdit size={20} />
              <LabelItem>{"휴대폰번호"}</LabelItem>
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 10 }}
            >
              <div>
                <input
                  type="number"
                  value={number}
                  style={{ width: "350px", marginLeft: 10 }}
                  onChange={(e) => {
                    setNumber(e.target.value);
                    setRefresh((refresh) => refresh + 1);
                  }}
                  onFocus={() => {
                    setNamefocus(false);
                    setNumberfocus(true);
                  }}
                  onBlur={() => setNumberfocus(false)}
                  className="inputcommon"
                  placeholder={"휴대폰 번호를 입력해주세요"}
                  returnKeyType={"다음"}
                  autoCapitalize="none"
                  autoCorrect={false}
                ></input>

                {numberfocus == true ? (
                  <div className="inputFocus"></div>
                ) : (
                  <div className="inputNoFocus"></div>
                )}
              </div>
            </div>
          </div>
          {/* <InputItem
            type={"number"}
            containerStyle={{ marginTop: 50 }}
            label={"휴대폰번호"}
            placeholder={"휴대폰번호를 입력해 주세요"}
            callback={numbercallback}
            callback2={_handleautologin}
          /> */}

          <Next
            callback={_handlelogin}
            enable={enable}
            containerStyle={{
              paddingTop: 150,
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          />
        </PCContainer>
      </PC>
    </>
  );
};

export default Logincontainer;
