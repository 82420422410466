import React,{useState, useEffect} from 'react';
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, Link, useNavigate} from "react-router-dom";
import styled from 'styled-components';
import { imageDB } from '../utility/imageData';

const Container = styled.div`
  padding: 0px 5%;
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  line-height: 2;
  width :100%;

`;

const Box = styled.div`
  box-shadow: 1px 1px 1px 2px #f5f5f5;
  border-radius: 5px;
  padding:5%;
  width:90%;
`;
const LabelItem = styled.div`
  font-weight: 500;
  line-height: 1.4;
  font-size: 1rem;
  text-decoration-line: none;
  color: rgb(58, 64, 71);
  display:flex;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
`;
const ItemLabel = styled.div`
  width: 100px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ItemData = styled.div`
  width:85%;
  padding-left: 10px;
  font-weight: 600;
  line-height: 1.5;
  font-size: 15px;
  text-decoration-line: none;
  color: ${({color}) => color};
  text-align:left;
`;

const Tag = styled.div`
  width: 40%;
  font-size: 14px;
  background-color: #f85b2d;
  color: #fff;
  border-radius: 5px;
`;

const ContentItem = ({containerStyle, label, content, color, type, enable ,data}) => {

  const navigate = useNavigate();
   useEffect(()=>{
    async function fetchData(){
		}
		fetchData();
  }, [])

  const [border, setBorder] = useState(false);
  const handleFocusBorder = () => {
    setBorder(true);
  }
  const handleNoFocusBorder = () => {
    setBorder(false);
  };

  const _handleDetail = () => {

    navigate("/tableinfo", {
      state: { type: type, data: data },
    });  
  }

  return (
    <Container style={containerStyle}>
      <Box>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems:"center",
            padding: "10px 20px",
          }}
        >
          <LabelItem>{label}</LabelItem>
          {enable == true && (
            <Tag className="button" onClick={_handleDetail}>
              자세히보기
            </Tag>
          )}
        </div>
        <Content>
          <ItemLabel>
            {type == "year" && (
              <img src={imageDB.iconsample} style={{ width: 40 }} />
            )}
            {type == "income" && (
              <img src={imageDB.income} style={{ width: 40 }} />
            )}
            {type == "vattax" && (
              <img src={imageDB.vattax} style={{ width: 40 }} />
            )}
            {type == "withholdingtax" && (
              <img src={imageDB.vattax} style={{ width: 40 }} />
            )}
            {type == "localtax" && (
              <img src={imageDB.vattax} style={{ width: 40 }} />
            )}
          </ItemLabel>
          <ItemData color={color}>{content}</ItemData>
        </Content>
      </Box>
    </Container>
  );
}

export default ContentItem;
