import React,{useState, useEffect, useContext} from 'react';
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, Link, useNavigate} from "react-router-dom";
import styled from 'styled-components';
import { imageDB } from '../utility/imageData';
import Relationbtn from './Relationbtn';
import Button from '../common/Button';
import { colors } from '../theme/theme';
import { GrAddCircle } from "react-icons/gr";
import { CgUserRemove } from 'react-icons/cg';
import { UserContext } from '../context/User';
import { get_tax, update_user2, update_userinfo, update_usersingle, update_userwoman } from '../service/UserService';
import { FcInfo } from "react-icons/fc";
import Info from '../common/Info';
import { BizConvert, JuminConvert, useSleep } from '../utility/common';
import { BiCalendarEdit } from "react-icons/bi";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 2;
  width: 90%;
  background: ${({ bgcolor }) => bgcolor};
  margin: 5px 0px;
  box-shadow: 1px 1px 1px 2px #e7e6e6;
  border-radius: 5px;
`;

const LabelItem = styled.div`
  font-weight: 700;
  line-height: 1.4;
  font-size: 1rem;
  text-decoration-line: none;
  color: rgb(58, 64, 71);
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  padding-left: 30px;
  align-items: center;
  flex-direction: row;
  width: 90%;
`;

const Content = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  margin-top:5px;
 
`
const ItemLabel = styled.div`
  width: 50px;
  height: 50px;
  background-color: #ededed;
  border-radius: 100%;
  position: absolute;
  left: 30px;
`;
const ItemData = styled.div`
  font-weight: 600;
  line-height: 1.5;
  font-size: 14px;
  text-decoration-line: none;
  color: #788391;;
  text-align:left;
  padding : 0px 10px;
`;

const LineControl = styled.div`
  margin-top:20px;
  width: 100%;
  height: 1px;
  background-color: #f0f1f2;
`;

const Note = styled.div`
  font-size: 12px;
  text-align: left;
  padding-left: 20px;
  margin-top:20px;
`;

const InfoData = styled.div`
  font-size: 14px;
  text-align: left;
  padding-left: 20px;
`;
const InfoData2 = styled.div`
  font-size: 14px;
  text-align: left;
  padding-left: 10px;
  padding-top: 10px;
`;

const ButtonLayer = styled.div`
  display : flex;
  flex-direction : row;
  justify-content: space-between;
  padding : 0x 10px;
`
const OPTIONS = [
  { value: "장애없음", name: "장애없음" },
  { value: "장애있음", name: "장애있음" },
];

const SelectBox = (props) => {
  return (
    <select style={{padding:3, outline:0, margin:5, width:"90%"}}>
      {props.options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.name}
        </option>
      ))}
    </select>
  );
};


const InfoItem = ({
  containerStyle,
  item,
  label,
  content,
  color,
  callback,
  bgcolor,
}) => {
  const navigate = useNavigate();

  const [woman, setWoman] = useState(false);
  const [single, setSingle] = useState(false);
  const [refresh, setRefresh] = useState(1);
  const { user, dispatch2 } = useContext(UserContext);
  const [etchumanitems, setEtchumanitems] = useState([]);
  const [tax, setTax] = useState(item);

  const [hometaxid, setHometaxid] = useState(item.hometaxid);
  const [hometaxpw, setHometaxpw] = useState(item.hometaxpw);
  const [wetaxid, setWetaxid] = useState(item.wetaxid);
  const [wetaxpw, setWetaxpw] = useState(item.wetaxpw);
  const [bank, setBank] = useState(item.bank);

  const [bizrealstate, setBizrealstate] = useState(item.bizrealstate);
  const [bizhumancost, setBizhumancost] = useState(item.bizhumancost);
  const [note, setNote] = useState(item.note);


  const _handlecallback = () => {
   // callback();
  };


  const handlerealstate = () =>{

    setBizrealstate(true);
    setRefresh((refresh) => refresh + 1);


  }
  const handlenorealstate = () =>{
    setBizrealstate(false);
    setRefresh((refresh) => refresh + 1);

  }
  const handlehumancost = () =>{
    setBizhumancost(true);
    setRefresh((refresh) => refresh + 1);

  }
  const handlehumannocost = () =>{
    setBizhumancost(false);
    setRefresh((refresh) => refresh + 1);
  }
  const onNoteChange = (e) => {
    setNote(e.target.value);
    setRefresh((refresh) => refresh + 1);
  };



  const _handleadd = async() =>{

    const tax_id = item.tax_id;
    
  
    const update2= await update_userinfo({
      tax_id,
      hometaxid,
      hometaxpw,
      wetaxid,
      wetaxpw,
      bizhumancost,
      bizrealstate,
      bank,
      note
    })

    const sleep2= await useSleep(1000);

    navigate("/newhome");



  }


  useEffect(() => {
   setTax(tax);
   setHometaxid(hometaxid);
  },[refresh])

  return (
    <>
      <Container
      style={containerStyle}
      onClick={_handlecallback}
      bgcolor={bgcolor}
      >



        <div style={{padding:"20px 0px 0px 10px", display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start"}}>
            <div>홈택스 아이디</div>
            <input
              type="text"
              value={hometaxid}
              onChange={(e) => {
                setHometaxid(e.target.value);
                setRefresh((refresh) => refresh +1);
              }}
              className="inputCommon"
              placeholder={"홈텍스아이디를 입력해주세요"}
            ></input>

        </div>
      


      </Container>
  

      <Button
          buttonText={"수정하기"}
          callback={_handleadd}
          containerStyle={{
            backgroundColor: colors.mainbuttoncolor,
            color: "#fff",
            margin: "10px 10%",
            width: "80%",
            height: 40,
            fontSize: 17,
            fontWeight: 700,
            borderRadius: "10px",
          }}
      />

      
    </>

  );
};

InfoItem.defaultProps = {
  bgcolor: "#FFF",
};


export default InfoItem;
