import React, { useContext, useEffect, useState } from "react";

import Layout from "../screen/Layout/HomeLayout";
import HomeLayout from "../screen/Layout/HomeLayout";
import { UserContext } from "../context/User";
import { TYPE } from "../utility/maroneDefine";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  get_userInfoForDevice,
  login,
  update_userdevice,
} from "../service/UserService";


import Resultcontainer from "../container/Resultcontainer";
import PrevLayout from "../screen/Layout/PrevLayout";
import Datacontainer from "../container/Datacontainer";

const Datapage = () => {
  const { user, dispatch2 } = useContext(UserContext);
  const location = useLocation();
  const queryString = location.search;
  const [searchParams, setSearchParams] = useSearchParams();

  let taxid_ = searchParams.get("id");
  const [taxid, setTaxid] = useState('');
  const [phone, setPhone] = useState('');
  const [refresh, setRefresh] = useState(1);

  
  useEffect(() => {

    console.log("searchparam", searchParams.get("id"));

  

  }, [])



  return (
    <PrevLayout menu={true} bottom={false} header={true} headerdetail={false}>
      <Datacontainer taxid={taxid_} />
    </PrevLayout>
  );
};

export default Datapage;
