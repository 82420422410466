import React,{useState, useEffect} from 'react';
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, Link, useNavigate} from "react-router-dom";
import styled from 'styled-components';

const Container = styled.div`
  padding: 0px 10%;
  display: flex;
  margin-top: 10px;
`;

const ReturnItem = styled.div`
  font-weight: 400;
  line-height: 1.5;
  font-size: 0.875rem;
  text-decoration-line: none;
  color: rgb(96, 106, 118);
  text-align:left;
`;

const InputReturn = ({containerStyle,point, value, error}) => {

  const navigate = useNavigate();
   useEffect(()=>{
    async function fetchData(){
		}
		fetchData();
  }, [])



  return (
    <Container style={containerStyle}>
      <ReturnItem>
        {point != '' &&
          <span style={{color:"#df4532", marginRight:10}}> { point }</span>}
        {value}
      </ReturnItem>
    </Container>
  );
}

export default InputReturn;
