

import React from "react";
import PrevHeader from "./Header/PrevHeader";



const PrevLayout = (props) => {


  return (
    <div>
      {props.header == true && <PrevHeader headername={props.headername} />}
      <main>{props.children}</main>
      {/* <Footer  menu ={props.menu} bottom ={props.bottom}  type={"home"}/> */}
    </div>
  );
};

export default PrevLayout;
