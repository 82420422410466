import React, { useState, createContext } from "react";

const UserContext = createContext({
  user: {
    name: "",
    phone: "",
    phoneorg: "",
    jumin: "",
    hometaxid: "",
    hometaxpw: "",
    wetaxid: "",
    wetaxpw: "",
    bank: "",
    authtype: "",
    wetax: "",
    hometax: "",
    biznum: [],
    bizrealstate: false,
    bizhumancost: false,
    yeardata : {},
    incomedata : {},
    vatdata : {},
    vatdata2 : {},
    vatdata3 : {},
    vatdata4 : {},
    vatdata5 : {},
    vatdata6 : {},
    withholdingdata : {},
    withholdingdata2 : {},
    withholdingdata3 : {},
    localtaxdata : {},
  },
  dispatch2: () => {},
});

const UserProvider = ({ children }) => {
  const [user, setUser] = useState({});

  const dispatch2 = ({
    name,
    phone,
    phoneorg,
    jumin,
    hometaxid,
    hometaxpw,
    wetaxid,
    wetaxpw,
    bank,
    authtype,
    wetax,
    hometax,
    biznum,
    bizrealstate,
    bizhumancost,
    yeardata,
    incomedata,
    vatdata,
    vatdata2,
    vatdata3,
    vatdata4,
    vatdata5,
    vatdata6,
    withholdingdata,
    withholdingdata2,
    withholdingdata3,
    localtaxdata,
  }) => {
    setUser({
      name,
      phone,
      phoneorg,
      jumin,
      hometaxid,
      hometaxpw,
      wetaxid,
      wetaxpw,
      bank,
      authtype,
      wetax,
      hometax,
      biznum,
      bizrealstate,
      bizhumancost,
      yeardata,
      incomedata,
      vatdata,
      vatdata2,
      vatdata3,
      vatdata4,
      vatdata5,
      vatdata6,
      withholdingdata,
      withholdingdata2,
      withholdingdata3,
      localtaxdata,
    });
  };

  const value = { user, dispatch2 };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
export { UserContext, UserProvider };
