import * as React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useSpring, animated } from '@react-spring/web';
import Text from '../common/Text';
import { imageDB } from '../utility/imageData';
import styled from 'styled-components';
import Label from '../common/Label';
import { colors, theme } from '../theme/theme';
import { Mobile, PC } from './Responsive';
import AcceptDocModalEx from './AcceptDocModalEx';

const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};

const style = {
  position: "absolute",
  top: "85%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderTopRightRadius: "10px",
  borderTopLeftRadius: "10px",
  padding: "22px",
};
const style2 = {
  position: "absolute",
  top: "85%",
  left: "50.5%",
  height: "250px",
  transform: "translate(-50%, -50%)",
  width: '320px',
  bgcolor: "background.paper",
  boxShadow: 24,
  borderTopRightRadius: "10px",
  borderTopLeftRadius: "10px",
  padding: "22px",
  overflow: "auto"
};
const IconCloseView = styled.div`
  position: relative;
  top: -8px;
  left: 35%;
`
const MainData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top:10px;
`

const MainDataItem = styled.div`
  padding: 10px 0px;
  justify-content: "space-between";

  border-radius: 5px;
  background-color: #fff;
  width:100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
 
`

const MainPCDataItem = styled.div`
  padding: 10px 25px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
  background-color: #fff;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
`;



const AgreeCheck = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    width: 100%;
    color: #606a76;
    font-size: 14px;
    padding-top:10px;
    padding-left:30px;
    margin-right:30px;
    text-decoration:underline;
`;

const MainDataItemText = styled.span`
  font-size :14px;
  font-family : ${({theme}) =>theme.REGULAR};
  color :  ${({check}) => check == 1 ? "#FF4E19" : "#000" };  

`
const ApplyItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${({ enable }) =>
    enable == true ? colors.mainbuttoncolor : "#ededed"};
  margin-bottom: 20px;
  margin: 10px 0px;
  height: 45px;
  border-radius:5px;
`;
const FilterApplyButton = styled.div`

  border-radius: 5px;
`;
const FilterApplyButtonText = styled.span`

  color: ${({ enable }) =>
  enable == true ? "#fff" : "#000"};
  font-size: 18px;
  font-family: Pretendard-Bold;
`;

const BackItem = styled.div`
  height: 18px;
  width: 18px;
  border-radius: 15px;
  border: 1px solid #606a76;
`;

const CheckItem = styled.div`
  background: ${({ check }) => (check == true ? "#3268f3" : "#FFF")};
  height: 15px;
  width: 15px;
  border-radius: 15px;
  position: relative;
  top: 1.5px;
  left: 1.5px;
`;

export default function AcceptModalEx({callback}) {
  const [open, setOpen] = React.useState(true);
  const [errorcontent, setErrorcontent] = React.useState('');
  const [refresh, setRefresh] = React.useState(1);

  const [acceptdocstatus, setAcceptdocstatus] = React.useState(false);
  
  const [allcheck, setAllcheck] = React.useState(false);

  const [errorcheckitems, setErrorcheckitems] = React.useState([0,0,0,0,0,0,0]);

  const handleClose = () =>{
    setOpen(false);
    callback(false);
  } 
  const _handleapply = () => {
    
    if (errorcheckitems[0] == 0) {
      return;
      }

    setOpen(false);
    callback(true);
  }
  const handleCheck = (index) =>{
    if(errorcheckitems[index] == 0){
      errorcheckitems[index] = 1;
    }else{
      errorcheckitems[index] = 0;
    }
    setErrorcheckitems(errorcheckitems);

    setRefresh(refresh => refresh + 1);

  }
  const _handleAllCheck = () => {

    if (allcheck == true) {
      errorcheckitems.map((data, index) => {
        errorcheckitems[index] = 2;
      });
      setAllcheck(false);
    } else {
      errorcheckitems.map((data, index) => {
        errorcheckitems[index] = 1;
      });
      setAllcheck(true);
    }
   
 
    setErrorcheckitems(errorcheckitems);
    

    setRefresh((refresh) => refresh + 1);
  }

  const acceptdoccallback = (type) => {
    setAcceptdocstatus(false);

    if (type == true) {
      let errorcheckitems = [];
      errorcheckitems[0] = 1;
      setErrorcheckitems(errorcheckitems);
      setRefresh((refresh) => refresh + 1);
    }
  };

  const _handleView = () => {
    setAcceptdocstatus(true);
  }

  React.useEffect(()=>{
    setErrorcheckitems(errorcheckitems);
    setAcceptdocstatus(acceptdoccallback);
    setAllcheck(allcheck);
  },[refresh])


  return (
    <>
      {acceptdocstatus == true && (
        <AcceptDocModalEx callback={acceptdoccallback} />
      )}

      <Mobile>
        <div>
          <Modal
            aria-labelledby="spring-modal-title"
            aria-describedby="spring-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                TransitionComponent: Fade,
              },
            }}
          >
            <Fade in={open}>
              <Box sx={style}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Label
                    content={
                      "개인(세무/회계)정보 수집이용을 위해 아래 항목에 동의해주세요"
                    }
                    containerStyle={{ color: "#141618", textAlign: "left" }}
                  />
                </div>
                <MainData>
                  <MainDataItem>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <BackItem>
                        <CheckItem
                          check={errorcheckitems[0]}
                          onClick={() => {
                            handleCheck(0);
                          }}
                        ></CheckItem>
                      </BackItem>

                      <Text
                        size={14}
                        value={"[필수]개인(세무/회계)정보 수집이용 제공 동의"}
                        containerStyle={{
                          fontWeight: 400,
                          lineHeight: 1.5,
                          color: "#606a76",
                          paddingLeft: 10,
                        }}
                      ></Text>
                    </div>

                    <AgreeCheck onClick={_handleView}>
                      동의서 자세히 보기
                    </AgreeCheck>
                  </MainDataItem>
                </MainData>

                <ApplyItem enable={errorcheckitems[0] == 1}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "center",
                    }}
                  >
                    <FilterApplyButton onClick={_handleapply}>
                      <FilterApplyButtonText enable={errorcheckitems[0] == 1}>
                        동의 후 계속 진행
                      </FilterApplyButtonText>
                    </FilterApplyButton>
                  </div>
                </ApplyItem>
              </Box>
            </Fade>
          </Modal>
        </div>
      </Mobile>
      <PC>
      <div>
          <Modal
            aria-labelledby="spring-modal-title"
            aria-describedby="spring-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                TransitionComponent: Fade,
              },
            }}
          >
            <Fade in={open}>
              <Box sx={style2}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Label
                    content={
                      "개인(세무/회계)정보 수집이용을 위해 아래 항목에 동의해주세요"
                    }
                    containerStyle={{ color: "#141618", textAlign: "left" }}
                  />
                </div>
                <MainData>
                  <MainDataItem>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <BackItem>
                        <CheckItem
                          check={errorcheckitems[0]}
                          onClick={() => {
                            handleCheck(0);
                          }}
                        ></CheckItem>
                      </BackItem>

                      <Text
                        size={14}
                        value={"[필수]개인(세무/회계)정보 수집이용 제공 동의"}
                        containerStyle={{
                          fontWeight: 400,
                          lineHeight: 1.5,
                          color: "#606a76",
                          paddingLeft: 10,
                        }}
                      ></Text>
                    </div>

                    <AgreeCheck onClick={_handleView}>
                      동의서 자세히 보기
                    </AgreeCheck>
                  </MainDataItem>
                </MainData>

                <ApplyItem enable={errorcheckitems[0] == 1}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "center",
                    }}
                  >
                    <FilterApplyButton onClick={_handleapply}>
                      <FilterApplyButtonText enable={errorcheckitems[0] == 1}>
                        동의 후 계속 진행
                      </FilterApplyButtonText>
                    </FilterApplyButton>
                  </div>
                </ApplyItem>
              </Box>
            </Fade>
          </Modal>
        </div>
      </PC>
    </>
  );
}