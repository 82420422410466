import React, {
  useState,
  useEffect,
  useContext,
  useLayoutEffect,
  useRef,
} from "react";
import {
  HashRouter,
  Route,
  Redirect,
  BrowserRouter,
  Routes,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Swipe from "../common/Swipe";

import styled from "styled-components";

import Guide from "../components/Guide";
import Swipe2 from "../common/Swipe2";
import Button from "../common/Button";
import { colors, theme } from "../theme/theme";
import InputItem from "../components/InputItem";
import InputReturn from "../components/InputReturn";
import Next from "../components/Next";
import { Mobile, PC } from "../components/Responsive";
import useDimension from "../common/UseDimension";
import SelectItem from "../components/SelectItem";
import { UserContext } from "../context/User";
import { get_userInfoForUID } from "../service/UserService";
import { BiCalendarEdit } from "react-icons/bi";
import { FcInfo } from "react-icons/fc";
import Info from "../common/Info";
import { JuminCheck } from "../utility/common";
import ErrorModalEx from "../components/ErrorModalEx";

const Container = styled.div`
  padding: 80px 10px;
  background: white;

`;
const PCContainer = styled.div`
width: 27%;
margin: 0px 37%;
  background: white;
  padding-top: 80px;
  height: 100%;
  height: ${({ height }) => height - 80}px;
  border: 1px solid;
`;

const LabelItem = styled.div`
  line-height: 1.5;
  font-size: 1.2rem;
  text-decoration-line: none;
  color: #6b6b6b;
  padding-left: 10px;
  font-family:Pretendard-Bold;
`;


 

const phoneorg = ["SKT", "KT", "LGU+", "기타"];

const JuminInfocontainer = () => {



  const navigate = useNavigate();
  
  const {user, dispatch2 } = useContext(UserContext);
  const [height, setHeight] = useState(window.innerHeight);



  const [jumin, setJumin] = useState("");
  
  const [juminfocus, setJuminfocus] = useState(true);
  
  const juminRef = useRef();


  
  
  const [enable, setEnable] = useState(false);
  const [refresh, setRefresh] = useState(1);
  const [enablerefresh, setEnablerefresh] = useState(1);
  
  const [error, setError] = useState(false);
  const [errormsg, setErrormsg] = useState("");
  
  const _handlenext = async () => {
    
    if (!JuminCheck(jumin)) {
      setError(true);
      setErrormsg("잘못된 주민등록번호입니다. 올바른 주민등록번호를 입력해주세요");
      setRefresh((refresh) => refresh + 1);
      return;
    }

    user["jumin"] = jumin;
    
    dispatch2(user);

    console.log("user", user);

    //navigate("/hometaxinfo");
    navigate("/bizinfo");

  }


  

  useEffect(() => {

    setJumin(jumin);


    if (jumin != '') {
      setEnable(true);
      console.log("enable...... true");
      setEnablerefresh(enablerefresh => enablerefresh + 1);
    } else {
      setEnable(false);
      setEnablerefresh((enablerefresh) => enablerefresh + 1);
    }

  }, [refresh])
  
  useEffect(() => {
    setEnable(enable);
  }, [enablerefresh]);


 

 
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  const errormodalcallback = () => {
    setError(false);
    setRefresh((refresh) => refresh + 1);
  };
  
  return (
    <>
      {error == true && (
        <ErrorModalEx callback={errormodalcallback} msg={errormsg} />
      )}
      <Mobile>
        <Container height={height}>
          <Guide
            main1={"세금 신고에 필요한 개인정보 입력 받습니다"}
            main2={""}
            sub={""}
          ></Guide>

          <Info
            content={
              "입력하신 주민 등록 번호 정보는 세금 신고시 필요한 자료 수집에만 사용되요"
            }
          />
   
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              marginLeft: "5%",
            }}
          >
            <div
              style={{
                marginTop: 50,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BiCalendarEdit size={20} />
              <LabelItem>{"주민등록번호(숫자만 필수입력)"}</LabelItem>
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 10 }}
            >
              <div>
                <input
                  ref={juminRef}
                  type="number"
                  value={jumin}
                  style={{ width: "260px", marginLeft: 10 }}
                  onChange={(e) => {
                    setJumin(e.target.value);
                    setRefresh((refresh) => refresh + 1);
                  }}
                  className="inputcommon"
                  placeholder={"주민등록번호를 입력해주세요"}
                  onFocus={() => {
            
                    setJuminfocus(true);
                  }}
                  onBlur={() => setJuminfocus(false)}
                ></input>
                {juminfocus == true ? (
                  <div className="inputFocus"></div>
                ) : (
                  <div className="inputNoFocus"></div>
                )}
              </div>
            </div>
          </div>
          <Next
            callback={_handlenext}
            enable={enable}
            containerStyle={{ width: "100%", marginTop: 30 }}
          />
        </Container>
      </Mobile>
      <PC>
        <PCContainer height={height}>
          <Guide
            main1={"세금 신고에 필요한 개인정보 입력 받습니다"}
            main2={""}
            sub={""}
          ></Guide>

          <Info
            content={
              "입력하신 주민등록 번호 정보는 세금 신고시 필요한 자료 수집에만 사용되요"
            }
          />
    
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              marginLeft: "5%",
            }}
          >
            <div
              style={{
                marginTop: 50,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BiCalendarEdit size={20} />
              <LabelItem>{"주민등록번호(필수입력)"}</LabelItem>
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 10 }}
            >
              <div>
                <input
                  ref={juminRef}
                  type="text"
                  value={jumin}
                  style={{ width: "350px", marginLeft: 10 }}
                  onChange={(e) => {
                    setJumin(e.target.value);
                    setRefresh((refresh) => refresh + 1);
                  }}
                  className="inputcommon"
                  placeholder={"주민등록번호를 입력해주세요"}
                  onFocus={() => {
                    setJuminfocus(true);
                  }}
                  onBlur={() => setJuminfocus(false)}
                ></input>
                {juminfocus == true ? (
                  <div className="inputFocus"></div>
                ) : (
                  <div className="inputNoFocus"></div>
                )}
              </div>
            </div>
          </div>
          <Next
            callback={_handlenext}
            enable={enable}
            containerStyle={{ width: "100%", marginTop: 30 }}
          />
        </PCContainer>
      </PC>
    </>
  );
};

export default JuminInfocontainer;
