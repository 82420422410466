import React,{useState, useEffect} from 'react';
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, Link, useNavigate} from "react-router-dom";
import styled from 'styled-components';
import { imageDB } from '../utility/imageData';
import { CgAdd } from 'react-icons/cg';
import Button from '../common/Button';
import { colors } from '../theme/theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 2;
  width: 90%;
  background: ${({bgcolor}) => bgcolor};
  margin: 5px 0px;
  box-shadow: 1px 1px 1px 2px #f5f5f5;
  border-radius: 5px;
`;

const LabelItem = styled.div`
  font-weight: 700;
  line-height: 1.4;
  font-size: 1rem;
  text-decoration-line: none;
  color: rgb(58, 64, 71);
  margin-top:20px;
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:row;
`;

const Content = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  margin-top:20px;
`
const ItemLabel = styled.div`
  width: 50px;
  height: 50px;
  background-color: #ededed;
  border-radius: 100%;
  position: absolute;
  left: 30px;
`;
const ItemData = styled.div`
  font-weight: 600;
  line-height: 1.5;
  font-size: 14px;
  text-decoration-line: none;
  color: #788391;;
  text-align:left;
  padding : 0px 10px;
`;

const LineControl = styled.div`
  margin-top:20px;
  width: 100%;
  height: 1px;
  background-color: #f0f1f2;
`;

const ServiceItem = ({
  containerStyle,
  label,
  content,
  color,
  callback,
  bgcolor,
}) => {
  const navigate = useNavigate();
  useEffect(() => {
    async function fetchData() {}
    fetchData();
  }, []);

  const _handlecallback = () => {
   // callback();
  };

  const handleAdd = () => {};
  return (
    <Container
      style={containerStyle}
      onClick={_handlecallback}
      bgcolor={bgcolor}
    >
      <LabelItem>
        {/* <div>
          <img src={imageDB.iconsample} style={{ width: 25 }} />
        </div> */}
        <div style={{ paddingLeft: 5 }}>{label}</div>
      </LabelItem>
      <Content>
        <ItemData color={color}>{content}</ItemData>

 
      </Content>
      <LineControl />
    </Container>
  );
};

ServiceItem.defaultProps = {
  bgcolor: "#FFF",

};


export default ServiceItem;
