import React, { useContext, useEffect, useState } from "react";

import Layout from "../screen/Layout/HomeLayout";
import HomeLayout from "../screen/Layout/HomeLayout";
import { UserContext } from "../context/User";
import { TYPE } from "../utility/maroneDefine";
import { useLocation } from "react-router-dom";
import {
  get_userInfoForDevice,
  login,
  update_userdevice,
} from "../service/UserService";

import PrevLayout from "../screen/Layout/PrevLayout";
import Noticecontainer from "../container/Noticecontainer";
import Infocontainer from "../container/Infocontainer";

const Infopage = () => {
  const { user, dispatch2 } = useContext(UserContext);

  const { state } = useLocation();



  return (
    <PrevLayout menu={true} bottom={false} header={true} headerdetail={false}>
      <Infocontainer INFOTYPE = {state.INFOTYPE} TAX={state.TAX}/>
    </PrevLayout>
  );
};

export default Infopage;
